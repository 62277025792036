import { createTheme } from "@mui/material";

export const theme = createTheme({
  //change styles to select
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          fontSize: "14px",
          height: "42px",

          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '7px',
            border: '1px solid #176318e6',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#176318e6',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#176318e6',
          },
        },


      }
    },
    TextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '7px',
            border: '1px solid #06c0b0',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#06c0b0',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#06c0b0',
          },
        },
      }
    }
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        fontFamily: "Inter",
        fontSize: "14px",

        '& $notchedOutline': {
          borderRadius: '7px',
          border: '1px solid #06c0b0',
        }
      },
    }
  }
});

export default theme;