import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Chip from '@mui/material/Chip'

import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  }
}

export default function StatusesMultiselect({ values, setValues, statuses, label }) {
  const theme = useTheme()

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setValues(value)
  }

  const handleDelete = (name) => {
    setValues(values.filter((value) => value !== name))
  }
  const handleClear = () => {
    setValues([])
  }

  useEffect(() => {
    console.log(values, 'values')
    console.log(statuses, 'statuses')
  }, [values, statuses])

  return (
    <div>
      <FormControl sx={{ width: 300 }}>
        <InputLabel id='demo-multiple-chip-label'>{label}</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={values}
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  sx={{
                    backgroundColor: value.color,
                  }}
                  key={value.id} // Assuming id is the unique identifier
                  label={value.name ? value.name : 'Without status'} // Assuming name is the property to display
                  onDelete={() => handleDelete(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation()
                  }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          <MenuItem
            key={'none'}
            value={{ _id: 'none', name: 'Without status', color: 'transparent' }}
            style={getStyles('Without status', values, theme)}
          >
            Without status
          </MenuItem>
          {statuses.map((name) => (
            <MenuItem
              sx={{
                backgroundColor: name.color,
              }}
              key={name._id}
              value={name}
              style={getStyles(
                name.name,
                values.map((value) => value.name),
                theme,
              )}
            >
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
