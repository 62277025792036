import React, { useState, useEffect, useMemo } from 'react'
import { mainApi } from '../../../store/services/mainApi'
import { Button, Modal, Box, CircularProgress, Typography, Checkbox } from '@mui/material'
import { useDispatch } from 'react-redux'

export default function PayFactura({ orders, id }) {
  const dispatch = useDispatch()

  const { data: ordersData, isLoading: isLoadingOrders } = mainApi.useGetOrdersByIdsQuery(orders, {
    skip: !orders,
  })

  const [payOrders, { isLoading: isLoadingPayOrders, isSuccess: isSuccessPayOrders }] = mainApi.usePayOrdersInFacturaMutation()
  const [upPayment, { isLoading: isLoadingUpPayment, isSuccess: isSuccessUpPayment }] = mainApi.useUndoPaymentInFacturaMutation()
  const [updateStatus, { isLoading, isSuccess: isSuccessUpdate }] = mainApi.useUpdateStatusHistoryMutation()
  const [updatePayValue] = mainApi.useUpdatePayValueInFacturaHistoryAdminMutation()
  const { data: statusesData, isLoading: isLoadingStatus } = mainApi.useGetAllStatusesQuery()

  const [statuses, setStatuses] = useState([])
  const [ordersObj, setOrdersObj] = useState([])

  useEffect(() => {
    if (statusesData) {
      setStatuses(statusesData.filter((status) => status.type === 'history'))
    }
  }, [statusesData])

  useEffect(() => {
    if (ordersData) {
      setOrdersObj(ordersData)
    }
  }, [ordersData])

  const [openPayModal, setOpenPayModal] = useState(false)

  const handleClosePayModal = (e) => {
    // e.preventDefault()
    e.stopPropagation()
    setOpenPayModal(false)
  }

  const handleOpenPayModal = (e) => {
    setOpenPayModal(true)
  }

  const [changebleOrders, setChangebleOrders] = useState(orders)

  const payValueTotalToUnpay = useMemo(() => {
    let total = 0
    ordersObj.forEach((order) => {
      if (changebleOrders.includes(order._id)) {
        total += 0
      } else {
        total += order.paidValue
      }
    })
    return total
  })

  //ordersObj ! include changebleOrders

  const handlePayCheckboxChange = (event, order) => {
    const isChecked = event.target.checked
    setChangebleOrders((prevOrders) => {
      if (isChecked) {
        return [...prevOrders, order]
      } else {
        return prevOrders.filter((item) => item !== order)
      }
    })
  }

  const handlePay = async () => {
    await payOrders({
      data: [...changebleOrders],
    })
      .unwrap()
      .then(() => {
        updateStatus({
          data: {
            _id: id,
            status: {
              ...statuses.find((status) => status._id === '65c81a6176b7b8a91821445a'),
            },
          },
        })
          .unwrap()
          .then(() => {
            updatePayValue({
              data: {
                _id: id,
                payValue: findAllOrdersTotalPrice,
              },
            })
              .unwrap()
              .then(() => {
                dispatch(mainApi.util.invalidateTags(['History']))
                setOpenPayModal(false)
                dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleUpPayment = async () => {
    await upPayment({
      data: [...changebleOrders],
    })
      .unwrap()
      .then(() => {
        //65dac76a41c2558bcd1e8add
        updateStatus({
          data: {
            _id: id,
            status: {
              ...statuses.find((status) => status._id === '65dac76a41c2558bcd1e8add'),
            },
          },
        })
          .then(() => {
            updatePayValue({
              data: {
                _id: id,
                payValue: payValueTotalToUnpay,
              },
            })
              .then(() => {
                dispatch(mainApi.util.invalidateTags(['History']))
                setOpenPayModal(false)
                dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
                alert('Payment successfully canceled', `${changebleOrders.length} orders canceled`)
              })
              .catch((err) => {
                console.log(err)
              })
          })
          .catch((err) => {
            console.log(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const findAllOrdersTotalPrice = useMemo(() => {
    let total = 0
    ordersObj.forEach((order) => {
      if (changebleOrders.includes(order._id)) {
        order.items.forEach((item) => {
          const { marketPriceCP, quantity } = item
          total += marketPriceCP ? parseFloat(marketPriceCP * (quantity || 0)) : 0
        })
      } else {
        if (order.paidValue) {
          total += order.paidValue
        }
      }
    })
    return total
  }, [ordersObj, changebleOrders])

  const calculateOrderTotalPrice = (order) => {
    return order?.items.reduce((acc, curr) => {
      const { marketPriceCP, quantity } = curr
      const itemTotalPrice = marketPriceCP ? parseFloat(marketPriceCP * (quantity || 0)) : 0
      return acc + itemTotalPrice
    }, 0)
  }

  return (
    <Box sx={{ display: 'flex', gap: '10px' }}>
      <Button variant='outlined' color='success' onClick={handleOpenPayModal}>
        Pay
      </Button>
      <Modal open={openPayModal} onClose={handleClosePayModal}>
        {isLoadingPayOrders ? (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ flex: 2, textAlign: 'center' }}>Total</p>
              <p style={{ flex: 1 }}>Pay</p>
              <p style={{ flex: 1 }}>Order</p>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', maxHeight: '300px', overflow: 'auto', maxWidth: '400px' }}>
              {isLoadingOrders ? (
                <CircularProgress />
              ) : ordersObj.length > 0 ? (
                ordersObj.map((order) => {
                  return (
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }} key={order._id}>
                      <Checkbox
                        checked={changebleOrders.includes(order._id)}
                        key={order._id}
                        onChange={(e) => handlePayCheckboxChange(e, order._id)}
                      />
                      <p>{calculateOrderTotalPrice(order).toFixed(2) + ' €'}</p>
                      <p>{order?.paidValue ? order.paidValue.toFixed(2) + ' €' : '0 €'}</p>
                      <a
                        href={`orders/${order._id}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          marginRight: '10px',
                          color: 'black',
                          textDecoration: 'none',
                          borderBottom: '1px solid blue',
                          cursor: 'pointer',
                          fontFamily: 'Inter',
                        }}
                      >
                        {order?.idTucomida ? order.idTucomida.slice(0, 8) : order._id.slice(0, 8)}
                      </a>
                    </Box>
                  )
                })
              ) : (
                orders.map((order) => {
                  return (
                    <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }} key={order}>
                      <Checkbox checked={changebleOrders.includes(order)} key={order} onChange={(e) => handlePayCheckboxChange(e, order)} />
                      <a
                        href={`orders/${order}`}
                        target='_blank'
                        rel='noreferrer'
                        style={{
                          marginRight: '10px',
                          color: 'black',
                          textDecoration: 'none',
                          borderBottom: '1px solid blue',
                          cursor: 'pointer',
                          fontFamily: 'Inter',
                        }}
                      >
                        {order.slice(0, 8)}
                      </a>
                    </Box>
                  )
                })
              )}
            </Box>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Are u sure? Pay {orders.length} orders? {findAllOrdersTotalPrice ? findAllOrdersTotalPrice.toFixed(2) + ' €' : ''}
            </Typography>
            <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='success' onClick={handlePay}>
              Pay
            </Button>
            <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='error' onClick={handleClosePayModal}>
              Cancel
            </Button>
            <Button sx={{ width: '100%', marginTop: '10px' }} variant='contained' color='warning' onClick={handleUpPayment}>
              Canceled orders payment
            </Button>
          </Box>
        )}
      </Modal>
    </Box>
  )
}
