import { Box, Typography, useMediaQuery } from '@mui/material'
import './OrdersInfo.scss'
import React, { useMemo } from 'react'

import { ReactComponent as Turnover } from '../../../../assets/svg/Turnover.svg'
import { ReactComponent as Debt } from '../../../../assets/svg/DebtIcon.svg'
import { useTranslation } from 'react-i18next'

export const OrdersInfo = ({ orders }) => {
  const mobileView = useMediaQuery('(max-width: 798px)')
  const { t } = useTranslation()

  const total = useMemo(() => {
    let total = 0
    if (orders) {
      orders.forEach((order) => {
        order.items.forEach((item) => {
          total += item.marketPriceCP * (item.quantity || 0)
        })
      })
    }
    return total
  }, [orders])

  const paid = useMemo(() => {
    let total = 0
    if (orders) {
      orders.forEach((order) => {
        total += order?.paidValue || 0
      })
    }
    return total
  }, [orders])

  return (
    <Box className='ordersInfo'>
      <Box className='ordersInfo__item' sx={{ marginBottom: '20px' }}>
        {mobileView ? null : <Turnover width={80} height={80} fill='#176318' />}
        <Box className='ordersInfo__item__content'>
          <Typography sx={{ color: '#575757' }} className='ordersInfo__item__content__title'>
            {total.toFixed(2)}€
          </Typography>
          <Typography sx={{ color: '#176318' }} className='ordersInfo__item__content__text'>
            {t('StaticPage.statistics.turnover')}
          </Typography>
        </Box>
      </Box>
      <Box className='ordersInfo__item' sx={{ marginBottom: '20px' }}>
        {mobileView ? null : (
          // <svg width='50' height='50' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
          //   <circle cx='20' cy='20' r='20' fill='#FF725E' />
          //   <path
          //     d='M26 11H14C13.3434 11 12.6932 11.1293 12.0866 11.3806C11.48 11.6319 10.9288 12.0002 10.4645 12.4645C9.52678 13.4021 9 14.6739 9 16V24C9 25.3261 9.52678 26.5979 10.4645 27.5355C10.9288 27.9998 11.48 28.3681 12.0866 28.6194C12.6932 28.8707 13.3434 29 14 29H26C26.6566 29 27.3068 28.8707 27.9134 28.6194C28.52 28.3681 29.0712 27.9998 29.5355 27.5355C29.9998 27.0712 30.3681 26.52 30.6194 25.9134C30.8707 25.3068 31 24.6566 31 24V16C31 15.3434 30.8707 14.6932 30.6194 14.0866C30.3681 13.48 29.9998 12.9288 29.5355 12.4645C29.0712 12.0002 28.52 11.6319 27.9134 11.3806C27.3068 11.1293 26.6566 11 26 11ZM14 13H26C26.7956 13 27.5587 13.3161 28.1213 13.8787C28.6839 14.4413 29 15.2044 29 16H11C11 15.2044 11.3161 14.4413 11.8787 13.8787C12.4413 13.3161 13.2044 13 14 13ZM26 27H14C13.2044 27 12.4413 26.6839 11.8787 26.1213C11.3161 25.5587 11 24.7956 11 24V18H29V24C29 24.7956 28.6839 25.5587 28.1213 26.1213C27.5587 26.6839 26.7956 27 26 27Z'
          //     fill='white'
          //   />
          //   <path
          //     d='M26 23H22C21.7348 23 21.4804 23.1054 21.2929 23.2929C21.1054 23.4804 21 23.7348 21 24C21 24.2652 21.1054 24.5196 21.2929 24.7071C21.4804 24.8946 21.7348 25 22 25H26C26.2652 25 26.5196 24.8946 26.7071 24.7071C26.8946 24.5196 27 24.2652 27 24C27 23.7348 26.8946 23.4804 26.7071 23.2929C26.5196 23.1054 26.2652 23 26 23Z'
          //     fill='white'
          //   />
          // </svg>
          <svg width='80' height='80' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='20' cy='20' r='20' fill='#FF725E' />
            <path
              d='M26 11H14C13.3434 11 12.6932 11.1293 12.0866 11.3806C11.48 11.6319 10.9288 12.0002 10.4645 12.4645C9.52678 13.4021 9 14.6739 9 16V24C9 25.3261 9.52678 26.5979 10.4645 27.5355C10.9288 27.9998 11.48 28.3681 12.0866 28.6194C12.6932 28.8707 13.3434 29 14 29H26C26.6566 29 27.3068 28.8707 27.9134 28.6194C28.52 28.3681 29.0712 27.9998 29.5355 27.5355C29.9998 27.0712 30.3681 26.52 30.6194 25.9134C30.8707 25.3068 31 24.6566 31 24V16C31 15.3434 30.8707 14.6932 30.6194 14.0866C30.3681 13.48 29.9998 12.9288 29.5355 12.4645C29.0712 12.0002 28.52 11.6319 27.9134 11.3806C27.3068 11.1293 26.6566 11 26 11ZM14 13H26C26.7956 13 27.5587 13.3161 28.1213 13.8787C28.6839 14.4413 29 15.2044 29 16H11C11 15.2044 11.3161 14.4413 11.8787 13.8787C12.4413 13.3161 13.2044 13 14 13ZM26 27H14C13.2044 27 12.4413 26.6839 11.8787 26.1213C11.3161 25.5587 11 24.7956 11 24V18H29V24C29 24.7956 28.6839 25.5587 28.1213 26.1213C27.5587 26.6839 26.7956 27 26 27Z'
              fill='white'
            />
            <path
              d='M26 23H22C21.7348 23 21.4804 23.1054 21.2929 23.2929C21.1054 23.4804 21 23.7348 21 24C21 24.2652 21.1054 24.5196 21.2929 24.7071C21.4804 24.8946 21.7348 25 22 25H26C26.2652 25 26.5196 24.8946 26.7071 24.7071C26.8946 24.5196 27 24.2652 27 24C27 23.7348 26.8946 23.4804 26.7071 23.2929C26.5196 23.1054 26.2652 23 26 23Z'
              fill='white'
            />
          </svg>
        )}
        <Box className='ordersInfo__item__content'>
          <Typography sx={{ color: '#FF725E' }} className='ordersInfo__item__content__title'>
            {Math.max(total - paid, 0).toFixed(2)}€
          </Typography>
          <Typography sx={{ color: '#FF725E' }} className='ordersInfo__item__content__text'>
            {t('StaticPage.statistics.debt')}
          </Typography>
        </Box>
      </Box>
      <Box className='ordersInfo__item' sx={{ marginBottom: '20px' }}>
        {mobileView ? null : (
          // <svg width='50' height='50' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
          //   <circle cx='20' cy='20' r='20' fill='#5FC56D' />
          //   <path
          //     d='M26 11H14C13.3434 11 12.6932 11.1293 12.0866 11.3806C11.48 11.6319 10.9288 12.0002 10.4645 12.4645C9.52678 13.4021 9 14.6739 9 16V24C9 25.3261 9.52678 26.5979 10.4645 27.5355C10.9288 27.9998 11.48 28.3681 12.0866 28.6194C12.6932 28.8707 13.3434 29 14 29H26C26.6566 29 27.3068 28.8707 27.9134 28.6194C28.52 28.3681 29.0712 27.9998 29.5355 27.5355C29.9998 27.0712 30.3681 26.52 30.6194 25.9134C30.8707 25.3068 31 24.6566 31 24V16C31 15.3434 30.8707 14.6932 30.6194 14.0866C30.3681 13.48 29.9998 12.9288 29.5355 12.4645C29.0712 12.0002 28.52 11.6319 27.9134 11.3806C27.3068 11.1293 26.6566 11 26 11ZM14 13H26C26.7956 13 27.5587 13.3161 28.1213 13.8787C28.6839 14.4413 29 15.2044 29 16H11C11 15.2044 11.3161 14.4413 11.8787 13.8787C12.4413 13.3161 13.2044 13 14 13ZM26 27H14C13.2044 27 12.4413 26.6839 11.8787 26.1213C11.3161 25.5587 11 24.7956 11 24V18H29V24C29 24.7956 28.6839 25.5587 28.1213 26.1213C27.5587 26.6839 26.7956 27 26 27Z'
          //     fill='white'
          //   />
          //   <path
          //     d='M26 23H22C21.7348 23 21.4804 23.1054 21.2929 23.2929C21.1054 23.4804 21 23.7348 21 24C21 24.2652 21.1054 24.5196 21.2929 24.7071C21.4804 24.8946 21.7348 25 22 25H26C26.2652 25 26.5196 24.8946 26.7071 24.7071C26.8946 24.5196 27 24.2652 27 24C27 23.7348 26.8946 23.4804 26.7071 23.2929C26.5196 23.1054 26.2652 23 26 23Z'
          //     fill='white'
          //   />
          // </svg>
          <svg width='80' height='80' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='20' cy='20' r='20' fill='#5FC56D' />
            <path
              d='M26 11H14C13.3434 11 12.6932 11.1293 12.0866 11.3806C11.48 11.6319 10.9288 12.0002 10.4645 12.4645C9.52678 13.4021 9 14.6739 9 16V24C9 25.3261 9.52678 26.5979 10.4645 27.5355C10.9288 27.9998 11.48 28.3681 12.0866 28.6194C12.6932 28.8707 13.3434 29 14 29H26C26.6566 29 27.3068 28.8707 27.9134 28.6194C28.52 28.3681 29.0712 27.9998 29.5355 27.5355C29.9998 27.0712 30.3681 26.52 30.6194 25.9134C30.8707 25.3068 31 24.6566 31 24V16C31 15.3434 30.8707 14.6932 30.6194 14.0866C30.3681 13.48 29.9998 12.9288 29.5355 12.4645C29.0712 12.0002 28.52 11.6319 27.9134 11.3806C27.3068 11.1293 26.6566 11 26 11ZM14 13H26C26.7956 13 27.5587 13.3161 28.1213 13.8787C28.6839 14.4413 29 15.2044 29 16H11C11 15.2044 11.3161 14.4413 11.8787 13.8787C12.4413 13.3161 13.2044 13 14 13ZM26 27H14C13.2044 27 12.4413 26.6839 11.8787 26.1213C11.3161 25.5587 11 24.7956 11 24V18H29V24C29 24.7956 28.6839 25.5587 28.1213 26.1213C27.5587 26.6839 26.7956 27 26 27Z'
              fill='white'
            />
            <path
              d='M26 23H22C21.7348 23 21.4804 23.1054 21.2929 23.2929C21.1054 23.4804 21 23.7348 21 24C21 24.2652 21.1054 24.5196 21.2929 24.7071C21.4804 24.8946 21.7348 25 22 25H26C26.2652 25 26.5196 24.8946 26.7071 24.7071C26.8946 24.5196 27 24.2652 27 24C27 23.7348 26.8946 23.4804 26.7071 23.2929C26.5196 23.1054 26.2652 23 26 23Z'
              fill='white'
            />
          </svg>
        )}
        <Box className='ordersInfo__item__content'>
          <Typography sx={{ color: '#5FC56E' }} className='ordersInfo__item__content__title'>
            {paid.toFixed(2)}€
          </Typography>
          <Typography sx={{ color: '#5FC56E' }} className='ordersInfo__item__content__text'>
            {t('StaticPage.statistics.paid')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
