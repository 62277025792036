import { configureStore } from '@reduxjs/toolkit'
import { mainApi } from './services/mainApi'
import allContacts from './slices/allContacts'
import currentOrderSlice from './slices/changeOrder'
import changeItemsSlice from './slices/changeItems'
import currentProductSlice from './slices/currentProduct'
import changeContactSlice from './slices/changeContact'
import userSettingsSlice from './slices/userSettings'

const combineReducers = {
  allContacts: allContacts,
  currentOrder: currentOrderSlice,
  changeItems: changeItemsSlice,
  currentProduct: currentProductSlice,
  changeContact: changeContactSlice,
  userSettings: userSettingsSlice,
  [mainApi.reducerPath]: mainApi.reducer,
}

export const store = configureStore({
  reducer: combineReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(mainApi.middleware),
})
