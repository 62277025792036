import { Button, Container, Modal, Box, TextField, Checkbox, Select, MenuItem, CircularProgress, Switch } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import InputAdornment from '@mui/material/InputAdornment'
import { replaceAccent } from '../../../../utils/accents'

export default function Sample({ deliveryTime, order, contact, type }) {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const dispatch = useDispatch()

  const [selectedItems, setSelectedItems] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [time, setTime] = useState(false)

  const [updateSample] = mainApi.useUpdateSampleMutation()
  const [createSample] = mainApi.useCreateSampleFileMutation()
  const [createOrder] = mainApi.useCreateNewOrderMutation()

  const { data, isLoading } = mainApi.useGetOrdersByTradeNameQuery(
    open ? (order?.tradeName ? order?.tradeName : contact?.tradeName ? contact?.tradeName : 'Not Set') : '',
    {
      skip: !open,
      refetchOnMountOrArgChange: true,
    },
  )
  const { data: currentUnits, isLoading: isLoadingCurrent } = mainApi.useGetUniqueCurrentUnitsQuery('', {
    skip: !open,
    refetchOnMountOrArgChange: true,
  })

  const {
    data: sampleData,
    isLoading: isLoadingSample,
    isError,
    isFetching: isFetchingSample,
  } = mainApi.useGetSampleByNameQuery(order?.tradeName ? order?.tradeName : contact?.tradeName ? contact?.tradeName : 'Not Set', {
    skip: name === 'Not Set',
  })

  const handleClearSearch = () => {
    setSearchQuery('')
  }

  useEffect(() => {
    if (sampleData) {
      console.log(sampleData, 'sampleData')
    }
  }, [sampleData])

  const handleUpdateSample = async () => {
    const body = {
      data: {
        ...sampleData,
        items: selectedItems.map((item) => ({
          ...item,
          status: {},
        })),
      },
    }
    await updateSample(body)
      .unwrap()
      .then(() => {
        setOpen(false)
        alert('Sample updated')
      })
      .catch((err) => alert('Something went wrong'))
  }

  const handleCreateSample = async () => {
    const body = {
      data: {
        name: order?.tradeName ? order?.tradeName : contact?.tradeName ? contact?.tradeName : 'Not Set',
        contact: contact,
        items: selectedItems.map((item) => ({
          ...item,
          status: {},
        })),
      },
    }
    await createSample(body)
      .unwrap()
      .then(() => {
        setOpen(false)
        alert('Sample created' + name)
      })
      .catch((err) => alert('Something went wrong'))
  }

  const createOrUpdateSample = () => {
    if (sampleData) {
      handleUpdateSample()
    } else {
      handleCreateSample()
    }
  }

  useEffect(() => {
    if (sampleData) {
      setSelectedItems(sampleData.items)
    }
  }, [sampleData])

  const uniqueItemsInOrders = useMemo(() => {
    if (data) {
      const result = []
      data.forEach((order) => {
        order.items?.forEach((item) => {
          if (!result.find((i) => i.sku === item.sku)) {
            result.push(item)
          }
        })
      })
      return result
    }
  }, [data])

  const handleSelect = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i.sku !== item.sku))
    } else {
      setSelectedItems([...selectedItems, item])
    }
  }

  const changeUnidad = (und, item) => {
    if (und === null || und === undefined) {
      console.error('changeUnidad got a null or undefined unidad', und, item)
      return
    }
    if (item === null || item === undefined) {
      console.error('changeUnidad got a null or undefined item', und, item)
      return
    }
    const currentItem = selectedItems.find((selectedItem) => selectedItem !== null && selectedItem.sku === item.sku)
    if (currentItem === null || currentItem === undefined) {
      console.error('Could not find item to change unidad', item)
      return
    }
    setSelectedItems(
      selectedItems.map((selectedItem) => {
        if (selectedItem === null || selectedItem === undefined) {
          console.error('Found a null or undefined item in the selectedItems list', selectedItem)
          return selectedItem
        }
        if (selectedItem.sku === item.sku) {
          return { ...selectedItem, currentUnit: und }
        }
        return selectedItem
      }),
    )
  }

  const changeQuantity = (qty, item) => {
    if (qty === null || qty === undefined) {
      console.error('changeQuantity got a null or undefined quantity', qty, item)
      return
    }
    if (item === null || item === undefined) {
      console.error('changeQuantity got a null or undefined item', qty, item)
      return
    }
    const currentItem = selectedItems.find((selectedItem) => selectedItem !== null && selectedItem.sku === item.sku)
    if (currentItem === null || currentItem === undefined) {
      console.error('Could not find item to change quantity', item)
      return
    }
    setSelectedItems(
      selectedItems.map((selectedItem) => {
        if (selectedItem === null || selectedItem === undefined) {
          console.error('Found a null or undefined item in the selectedItems list', selectedItem)
          return selectedItem
        }
        if (selectedItem.sku === item.sku) {
          return { ...selectedItem, quantity: qty, quantityOrder: qty }
        }
        return selectedItem
      }),
    )
  }

  const formatDate = (date) => {
    const d = new Date(date)
    const day = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0') // Месяцы начинаются с 0
    const year = d.getFullYear()
    return `${day}.${month}.${year}, ${deliveryTime}`
  }

  const handleCreateOrder = async () => {
    const body = {
      data: {
        items: selectedItems.map((item) => ({
          ...item,
          status: {},
          quantity: parseFloat(item?.quantity || 1),
          quantityOrder: parseFloat(item?.quantity || 1),
        })),
        deliveryTime: deliveryTime,
        deliveryDate: formatDate(Date.now()),
        date: Date.now(),
        type: 'browser',
        status: {},
        order: 'CP',
        paidValue: 0,
        userAcc: contact,
        tradeName: contact?.tradeName ? contact?.tradeName : '',
      },
    }
    await createOrder(body)
      .unwrap()
      .then(() => {
        setOpen(false)
        dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {
    console.log(selectedItems)
  }, [selectedItems])

  const sortedItems = useMemo(() => {
    const searchQueryLower = searchQuery.toLowerCase()

    // Фильтруем элементы, которые соответствуют поисковому запросу
    const filterByName = (item) => replaceAccent(item.name).toLowerCase().includes(searchQueryLower)

    // Сначала отображаем выбранные элементы, которые соответствуют поисковому запросу
    const filteredSelectedItems = (selectedItems || []).filter(filterByName)

    // Затем добавляем элементы из заказов, которые не были выбраны, но соответствуют поисковому запросу
    const filteredUniqueItemsInOrders = (uniqueItemsInOrders || [])
      .filter((item) => !(selectedItems || []).some((selectedItem) => selectedItem.sku === item.sku))
      .filter(filterByName)

    // Конкатенируем отфильтрованные массивы
    return filteredSelectedItems.concat(filteredUniqueItemsInOrders)
  }, [selectedItems, uniqueItemsInOrders, searchQuery])

  return (
    <Container>
      {type === 'update' && (
        <div style={{ cursor: 'pointer' }} className='totalInfo__status' onClick={handleOpen}>
          Sample
        </div>
      )}
      {type === 'create' && (
        <Button onClick={handleOpen} disabled={isFetchingSample || !sampleData} variant='contained'>
          Use Sample
        </Button>
      )}
      <Modal sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} open={open} onClose={handleClose}>
        {isLoading || isLoadingSample || isLoadingCurrent ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              position: 'relative',

              width: '80%',
              bgcolor: 'background.paper',
              border: '1px solid #000',
              boxShadow: 24,
              borderRadius: 2,
              maxHeight: '80vh',
              p: 4,
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: 'white',
              }}
            >
              <TextField
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                sx={{ mb: 2 }}
                id='outlined-basic'
                label='Search'
                variant='outlined'
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      {searchQuery && (
                        <IconButton onClick={handleClearSearch} edge='end'>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              {type === 'update' && (
                <Button onClick={() => createOrUpdateSample()} sx={{ m: 2, mt: 0 }} variant='contained'>
                  Save Sample
                </Button>
              )}
              {type === 'create' && (
                <Button onClick={handleCreateOrder} variant='contained'>
                  Create Order
                </Button>
              )}
            </Box>

            <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
              {sortedItems &&
                sortedItems.map((item) => {
                  return (
                    <Box key={item.sku} sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2, width: '300px', flexWrap: 'wrap' }}>
                      <Checkbox checked={selectedItems.some((selectedItem) => selectedItem.sku === item.sku)} onChange={() => handleSelect(item)} />
                      <img src={item.img} alt={item.name} width={100} height={100} />
                      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ marginBottom: 5 }}>{item.name}</div>
                        {selectedItems.some((selectedItem) => selectedItem.sku === item.sku) ? (
                          <Select
                            sx={{ width: 100 }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: '200px', // Set the desired height for the dropdown menu
                                },
                              },
                            }}
                            value={item.currentUnit ? item.currentUnit : ''}
                            onChange={(e) => changeUnidad(e.target.value, item)}
                          >
                            {currentUnits &&
                              currentUnits.map(
                                (unidad) =>
                                  unidad !== null && (
                                    <MenuItem key={unidad} value={unidad}>
                                      {unidad}
                                    </MenuItem>
                                  ),
                              )}
                          </Select>
                        ) : null}
                        {selectedItems.some((selectedItem) => selectedItem.sku === item.sku) ? (
                          <TextField sx={{ width: 80 }} value={item.quantity} onChange={(e) => changeQuantity(e.target.value, item)} type='number' />
                        ) : null}
                      </Box>
                    </Box>
                  )
                })}
            </div>
          </Box>
        )}
      </Modal>
    </Container>
  )
}
