import React from 'react'
import './FiltersInfo.scss'
import { ReactComponent as RatingStar } from '../../../../assets/svg/RatingStar.svg'
import { ReactComponent as Like } from '../../../../assets/svg/Like.svg'
import { ReactComponent as Dislike } from '../../../../assets/svg/Dislike.svg'
import { Rating } from '@mui/material'

export default function FiltersInfo({ contact }) {
  const { dificilidad, slovenica } = contact
  return (
    <div className='filtersInfo'>
      <div className='filtersInfo__rating'>
        <h3>Rating:</h3>
        <div className='filtersInfo__rating__box'>
          <Rating className='filtersInfo__rating__box__star' name='half-rating-read' defaultValue={2.5} precision={0.5} />
        </div>
      </div>
      <div className='filtersInfo__cats'>
        <div className='filtersInfo__cats__item'>
          <p>Slovenica</p>
          <Like className={`filtersInfo__cats__item__like ${slovenica ? 'active' : ''}`} fill='white' />
          <Dislike className={`filtersInfo__cats__item__dislike ${slovenica ? '' : 'active'}`} />
          <div className='line'></div>
        </div>
        <div className='filtersInfo__cats__item'>
          <p>Dificilidad</p>
          <Like className={`filtersInfo__cats__item__like ${dificilidad ? 'active' : ''}`} fill='white' />
          <Dislike className={`filtersInfo__cats__item__dislike ${dificilidad ? '' : 'active'}`} />
          <div className='line'></div>
        </div>
      </div>
      <div className='filtersInfo__extra'>
        <p>Extra pedidos:</p>
        <div>Yes</div>
      </div>
    </div>
  )
}
