import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
  save: false
}

export const changeItemsSlice = createSlice({
  name: 'changeItems',
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload
    },
    changeSave: (state, action) => {
      state.save = action.payload
    },
    changeItem: (state, action) => {
      const item = action.payload
      const index = state.items.findIndex((elem) => elem._id === item._id)
      if (index !== -1) {
        state.items[index] = item
      }
    },
    changeItems: (state, action) => {
      const selectedItems = action.payload
      selectedItems.forEach((item) => {
        const index = state.items.findIndex((elem) => elem._id === item._id)
        if (index !== -1) {
          state.items[index] = item
        }
      })
    }
  }
})

export const { changeSave, changeItem, setItems, changeItems } = changeItemsSlice.actions
export default changeItemsSlice.reducer