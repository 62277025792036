import React, { useState } from 'react'
import './Autorization.scss'
import { Box, Container, FormControl, TextField, Button, Select, MenuItem, Modal, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router'
import { updateNameAndImage } from '../../store/slices/userSettings'
import { useDispatch } from 'react-redux'
import { MuiTelInput } from 'mui-tel-input'

import macBook from '../../assets/png/StartPage/MacBook.png'
import Registration from './Registration/Registration'
import Login from './Login/Login'

export default function Autorization() {
  //new version

  const [pass, setPass] = useState('')

  const [phone, setPhone] = useState('')

  const [regModal, setRegModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)

  const [loginModal, setLoginModal] = useState(false)

  const mobileView = useMediaQuery('(max-width: 798px)')

  return (
    <div className='autorization'>
      <Box
        className='autorization__content'
        sx={{
          backgroundImage: `url(${macBook})`,
          backgroundPositionX: 'calc(70% + 300px)',
          backgroundPositionY: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',

          height: '95vh',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
        <h2 className='autorization__content__title'>A LITTLE INFORMATION ABOUT US</h2>
        <Box
          className='autorization__content__btns'
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Button onClick={() => setLoginModal(true)} className='autorization__content__btns__btn' variant='contained'>
            Sign In
          </Button>
          <Button onClick={() => setRegModal(true)} className='autorization__content__btns__btn' variant='contained'>
            Register
          </Button>
        </Box>
        <Modal open={regModal} onClose={() => setRegModal(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box className='autorization__content__modal'>
            <Box className='autorization__content__modal__content'>
              <h2>
                <span
                  style={{
                    fontSize: 40,
                  }}
                >
                  WELCOME
                </span>{' '}
                to TPO<span style={{ color: '#186419' }}>Admin</span>
              </h2>
            </Box>
            <h5>Let’s start with your phone number</h5>
            <MuiTelInput
              MenuProps={{ PaperProps: { style: { maxHeight: '200px', width: '300px' } } }}
              InputProps={{
                disableUnderline: true,
                sx: {
                  border: 'none',
                },
              }}
              variant='standard'
              sx={{
                '& .MuiTelInput-TextField': {
                  border: 'none',
                },
                '& .MuiTelInput-IconButton': {
                  border: '1px solid #186419',
                  width: 50,
                  height: 40,
                  borderRadius: '20px 0px 0px 20px',

                  '&:hover': {
                    backgroundColor: '#186419',
                    color: 'white',
                  },
                },
                '& .MuiInputBase-input': {
                  paddingTop: 1,
                  paddingBottom: 1,
                  paddingLeft: 1,
                  // paddingRight: 10,
                  borderTop: '1px solid #186419',
                  borderBottom: '1px solid #186419',
                  borderRight: '1px solid #186419',
                  borderTopRightRadius: '20px',
                  borderBottomRightRadius: '20px',
                  // borderLeft: '1px solid #186419',
                },
                '& .MuiInputBase-root': {
                  border: 'none',
                },
              }}
              value={phone}
              onChange={(e) => setPhone(e)}
            />
            <Button
              onClick={() => {
                setRegModal(false)
                setRegisterModal(true)
              }}
              sx={{ width: '100% !important', marginTop: 2 }}
              className='autorization__content__btns__btn'
              variant='contained'
            >
              Continue
            </Button>

            <p>By creating an account, you automatically accept our Terms of service, Privacy Policy and Cookies Policy</p>
          </Box>
        </Modal>
        <Modal open={registerModal} onClose={() => setRegisterModal(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Registration phone={phone} />
        </Modal>
        <Modal open={loginModal} onClose={() => setLoginModal(false)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Login />
        </Modal>
      </Box>
    </div>
  )
}
