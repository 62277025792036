import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
export const OrdersCounter = ({ orders }) => {
  // const [orders, setOrders] = useState([])
  // const { data: ordersData, isLoading: isLoadingOrders, error } = mainApi.useGetOrdersByTradeNameQuery(tradeName, { skip: !tradeName })

  // useEffect(() => {
  //   if (ordersData) {
  //     console.log('Orders data from API:', ordersData) // Логирование данных из API
  //     try {
  //       // Пытаемся распарсить данные, если это не строка
  //       const parsedData = typeof ordersData === 'string' ? JSON.parse(ordersData) : ordersData
  //       setOrders(parsedData)
  //     } catch (e) {
  //       console.error('Error parsing orders data:', e)
  //     }
  //   } else {
  //     console.log('No orders data')
  //   }
  //   if (error) {
  //     console.error('Error fetching orders:', error)
  //     if (error.status === 'PARSING_ERROR') {
  //       console.error('Parsing error: the server returned invalid JSON')
  //     }
  //   }
  // }, [ordersData, error])

  useEffect(() => {
    console.log('Component mounted or updated')
  }, [])

  useEffect(() => {
    console.log('Orders state updated:', orders)
  }, [orders])

  return <div>{orders.length}</div>
}
