import React from 'react'
import './Rentabilidad.scss'
import { Select, FormControl, InputLabel, MenuItem } from '@mui/material'
import { ResponsiveContainer, RadialBarChart, RadialBar, PolarAngleAxis } from 'recharts'
export default function Rentabilidad() {
  const [timeskip, setTimeskip] = React.useState('month')

  const data = [
    {
      name: 'Real',
      uv: 35,
      fill: '#6f6bf4',
    },
    {
      name: 'Potencial',
      uv: 75,
      fill: '#ff9f00',
    },
  ]

  return (
    <div className='rentabilidad'>
      <h2>Rentabilidad potencial/real</h2>
      <div className='rentabilidad__container'>
        <div className='rentabilidad__container__left'>
          <div className='rentabilidad__container__left__info'>
            <p>Potencial</p>
            <p className='rentabilidad__container__left__info__value orange'>75 %</p>
          </div>
          <div className='rentabilidad__container__left__info'>
            <p>Real</p>
            <p className='rentabilidad__container__left__info__value purple'>35 %</p>
          </div>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>Time</InputLabel>
            <Select
              labelId='simple-select-label'
              id='simple-select'
              defaultValue={timeskip}
              value={timeskip}
              label='Time'
              onChange={(e) => setTimeskip(e.target.value)}
            >
              <MenuItem value={'day'}>Day</MenuItem>
              <MenuItem value={'month'}>Month</MenuItem>
              <MenuItem value={'year'}>Year</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='rentabilidad__container__right'>
          <ResponsiveContainer width='100%' height='100%'>
            <RadialBarChart cx='60%' cy='50%' innerRadius='60%' outerRadius='100%' barSize={20} data={data}>
              <PolarAngleAxis type='number' tick={false} angleAxisId={0} domain={[0, 100]} />
              <RadialBar minAngle={15} background clockWise dataKey='uv' angleAxisId={0} />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  )
}
