// i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(HttpApi) // загрузка переводов с сервера
  .use(LanguageDetector) // определение языка пользователя
  .use(initReactI18next) // проход через react-i18next
  .init({
    supportedLngs: ['en', 'es'], // поддерживаемые языки
    fallbackLng: 'es', // язык по умолчанию
    detection: {
      order: ['path', 'cookie', 'htmlTag'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
    },
  })

export default i18n
