import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font, Svg, Path } from '@react-pdf/renderer'
import logo from '../../../assets/png/Factura/logo.jpg'
import check from '../../../assets/svg/Check.svg'

const styles = StyleSheet.create({
  document: {
    display: 'block',
  },
  page: {
    flexDirection: 'column',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  view: {
    border: '1 solid grey',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    border: '1 solid grey',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: '1 solid grey',
  },
  tableHeaderСell: {
    flex: 1,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  tableHeaderСellLong: {
    flex: 3,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    borderBottom: '1 solid grey',
  },
  cell: {
    flex: 1,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
  },
  cellLong: {
    flex: 3,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
    // borderLeft: '1 solid grey',
    display: 'flex',
    flexDirection: 'column',
  },
  cellLeft: {
    flex: 1,
    padding: 5,
    fontSize: 20,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
    borderLeft: '1 solid grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    flexDirection: 'column',
  },
  textTitle: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
  },
  flexView: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    margin: 20,
    justifyContent: 'flex-end',
  },
  textTitleTotal: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'right',
  },
  textTitleBold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
})

// Create Document Component
const Albaran = ({ items, order, contact }) => {
  const getTotalbyIva = (items, iva) => {
    return items.filter((item) => item.IVA === iva).reduce((acc, curr) => acc + curr.price * (curr.quantity || 1) * (iva / 100), 0)
  }

  const getTotalbyIvaAll = (items) => {
    return items.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1), 0)
  }

  const getTotalbyIvaTotal = (items, iva) => {
    const newItems = items.filter((item) => item.IVA === iva)
    if (newItems.length === 0) return 0
    return newItems.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1), 0)
  }

  const total = (items) => {
    const ivaValue = items.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1) * (curr.IVA / 100), 0)
    return items.reduce((acc, curr) => acc + curr.price * (curr.quantity || 1), 0) - ivaValue
  }

  const counters = useMemo(() => {
    const counters = {
      0: 0,
      5: 0,
      10: 0,
      21: 0,
    }

    for (const item of items) {
      counters[item.IVA] += 1
    }

    return counters
  }, [items])

  const IVA0Counter = counters[0]
  const IVA5Counter = counters[5]
  const IVA10Counter = counters[10]
  const IVA21Counter = counters[21]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            gap: 10,
            width: '50%',
            // alighnItems: 'center',
            alignItems: 'center',

            height: '31px',
          }}
        >
          <Image
            source={logo}
            style={{
              width: 60,
              height: 31,
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              color: '#176318',
              fontFamily: 'Helvetica',
              fontSize: 14,
              fontWeight: '800',
              letterSpacing: 1.4,
              width: '163px',
            }}
          >
            Tu Producto Online
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          }}
        >
          <View>
            <Text style={styles.textTitle}>Check List: {order?.idTucomida ? order?.idTucomida : order._id}</Text>
            <Text style={styles.textTitle}>Fecha: {new Date().toISOString().slice(0, 10).split('-').reverse().join('.')}</Text>
          </View>
          <View>
            <Text style={styles.textTitleBold}>
              {contact?.name || order?.userAcc?.name || order?.name || 'N/A'}({order?.tradeName || contact?.tradeName || ''})
            </Text>
            <Text style={styles.textTitle}>{contact?.nif ? contact?.nif[0] : ''}</Text>
            <Text style={styles.textTitle}>{contact?.billAddress?.address || order?.address || ''}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderСell}>STATUS</Text>
            <Text style={styles.tableHeaderСellLong}>CONCEPTO</Text>
            <Text style={styles.tableHeaderСell}>Cantidad</Text>
            <Text style={styles.tableHeaderСell}>UND</Text>
            <Text style={styles.tableHeaderСell}>PRECIO</Text>
            <Text style={styles.tableHeaderСell}>IVA</Text>
            {/* <Text style={styles.tableHeaderСell}>TOTAL</Text> */}
          </View>
          {!items ? (
            <Text>some</Text>
          ) : (
            items.map((item) => (
              <View wrap key={item.conceptio} style={styles.row}>
                <View style={styles.cellLeft}>
                  {item?.status ? (
                    item?.status?.name == 'Ready' ? (
                      <Svg width={24} height={24}>
                        <Path strokeWidth={2} stroke='rgb(23, 99, 24)' d={'M5 12l5 5l10 -10'} />
                      </Svg>
                    ) : item?.status?.name == 'NOT AVAILABLE' ? (
                      <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>&mdash;</Text>
                    ) : (
                      <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>?</Text>
                    )
                  ) : (
                    <Text style={{ fontSize: 20, fontFamily: 'Helvetica-Bold' }}>?</Text>
                  )}
                </View>
                <View style={styles.cellLong}>
                  <Text style={{ fontSize: 11, fontFamily: 'Helvetica' }}>{item?.conceptio + '\n'}</Text>
                  <Text style={{ color: '#9D9D9D', fontSize: 12, fontFamily: 'Helvetica', fontWeight: 'bold' }}>{item?.sku}</Text>
                </View>
                <Text style={styles.cell}>{item?.quantity ? item?.quantity : 'N/A'}</Text>
                <Text style={styles.cell}>{item?.tipo ? item?.tipo : 'N/A'}</Text>
                <Text style={styles.cell}>{item?.price ? item?.price : 'N/A'}€</Text>
                <Text style={styles.cell}>{item?.IVA ? item?.IVA : 0}%</Text>
                {/* <Text style={styles.cell}>{item?.total ? +item?.total : 'N/A'}€</Text> */}
              </View>
            ))
          )}
        </View>
        {/* <View style={styles.flexView}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>BASE IMPONIBLE:</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{(getTotalbyIvaTotal(items, 0) - getTotalbyIva(items, 0)).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{(getTotalbyIvaTotal(items, 5) - getTotalbyIva(items, 5)).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{(getTotalbyIvaTotal(items, 10) - getTotalbyIva(items, 10)).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{(getTotalbyIvaTotal(items, 21) - getTotalbyIva(items, 21)).toFixed(2)}€</Text>}
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>{total(items).toFixed(2)}€</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>IMPUESTO</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>IVA 0%</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>IVA 5%</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>IVA 10%</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>IVA 21%</Text>}
            
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL IMPUESTO</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIva(items, 0).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIva(items, 5).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIva(items, 10).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIva(items, 21).toFixed(2)}€</Text>}
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 0).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 5).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 10).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 21).toFixed(2)}€</Text>}
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>{getTotalbyIvaAll(items).toFixed(2)}€</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  )
}

export default Albaran
