import React, { useEffect, useState, useMemo } from 'react'
import { useMediaQuery } from '@mui/material'
import { mainApi } from '../../../store/services/mainApi'
import { CircularProgress } from '@mui/material'
import Stats from '../../Stats/Stats'
import OrdersTable from '../OrderTable/OrderTable'
export default function OrdersHistory() {
  useEffect(() => {
    document.title = 'Orders History'
  }, [])

  const mobileView = useMediaQuery('(max-width: 768px)')

  //filters
  const [perPage, setPerPage] = useState(50)
  const [searchValue, setSearchValue] = useState('')
  const [status, setStatus] = useState([])

  const [dateRange, setDateRange] = useState('')

  const [tradeNames, setTradeNames] = useState([])

  const [productsStatus, setProductsStatus] = useState('')

  const [searchPlatform, setSearchPlatform] = useState('')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const {
    data: ordersData,
    isFetching: isFetchingOrders,
    isLoading: isLoadingOrders,
  } = mainApi.useGetAllOrdersEmitidoQuery({
    limit: perPage ? perPage : 50,
    tradeName: localStorage.getItem('name'),
    search: searchValue ? searchValue : '',
    status: status ? status : '',
    platform: searchPlatform ? searchPlatform : '',
    tradeNames: tradeNames ? tradeNames : '',
    deliveryDate:
      startDate || endDate
        ? (startDate ? new Date(startDate.toISOString()).getTime() : '') + '-' + (endDate ? new Date(endDate.toISOString()).getTime() : '')
        : '',
    month: dateRange ? dateRange : '',
  })

  const {
    data: ordersEmetido,
    isLoading: isLoadingOrdersEmetido,
    isFetching: isFetchingOrdersEmetido,
  } = mainApi.useGetOrdersEmetidoDashboardQuery({ tradeName: localStorage.getItem('name') })

  const { data: contactsData, isLoading: isLoadingContacts } = mainApi.useGetAllContactsQuery('', {
    skip: !ordersData,
  })
  const [orders, setOrders] = useState([])
  const [contacts, setContacts] = useState([])

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
      console.log(contactsData)
    }
  }, [contactsData])

  useEffect(() => {
    if (ordersData) {
      setOrders(ordersData)
      console.log(ordersData)
    }
  }, [ordersData])

  // const modalKeys = ['Copy', 'Export', 'Status', 'New order', 'Payment', 'Add Route', 'Delete', 'Total Sold', 'COGS', 'Estimated Profit', 'Paid']

  const allOrdersTotal = useMemo(() => {
    let total = 0
    if (ordersEmetido) {
      ordersEmetido.forEach((order) => {
        order.items.length > 0 ? order.items.forEach((item) => (total += item.marketPriceCP * (item.quantity || 1))) : (total += 0)
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [ordersEmetido])

  const cogs = useMemo(() => {
    let total = 0
    if (ordersEmetido) {
      ordersEmetido.forEach((order) => {
        order.items.length > 0 ? order.items.forEach((item) => (total += item.ourPrice * (item.quantity || 1))) : (total += 0)
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [ordersEmetido])

  const profit = useMemo(() => {
    let total = 0
    if (ordersEmetido) {
      ordersEmetido.forEach((order) => {
        order.items.length > 0
          ? order.items.forEach(
              (item) => (total += (item.marketPriceCP * (item.quantity || 1) - item.ourPrice * (item.quantity || 1)) * (1 - item.IVA / 100)),
            )
          : (total += 0)
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [ordersEmetido])

  const paid = useMemo(() => {
    let total = 0
    if (ordersEmetido) {
      ordersEmetido.forEach((order) => {
        order.paidValue ? (total += +order.paidValue) : (total += 0)
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [ordersEmetido])

  const balance = useMemo(() => {
    let total = 0
    if (ordersEmetido) {
      ordersEmetido.forEach((order) => {
        return parseFloat(order.paidValue)
          ? (total +=
              order.items
                .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
                .reduce((acc, curr) => acc + curr, 0) - (parseFloat(order.paidValue) || 0))
          : (total += order.items
              .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0))
      })
      return total.toFixed(2) + '€'
    }
  }, [ordersEmetido])

  const mock = [
    {
      image: 'Cart',
      title: 'Total orders',
      value: ordersEmetido ? ordersEmetido.length : 0,
      color: '#5cc3fd ',
    },
    {
      image: 'Cogs',
      title: 'COGS',
      value: cogs,
      color: '#0E8C15 ',
    },
    {
      image: 'HandWithMoney',
      title: 'Paid',
      value: paid,
      color: '#13CF1E ',
    },
    {
      image: 'Sold',
      title: 'Total Sold',
      value: allOrdersTotal,
      color: '#0E8C15 ',
    },

    {
      image: 'Money',
      title: 'Estimated Profit',
      value: profit,
      color: '#13CF1E ',
    },

    {
      image: 'Debts',
      title: 'Balance (Debts)',
      value: balance,
      color: '#FF0000 ',
    },
  ]

  return isLoadingOrders || isLoadingContacts ? (
    <CircularProgress
      size={200}
      color='success'
      sx={{
        position: 'absolute',
        top: mobileView ? '50%' : '45%',
        left: mobileView ? '30%' : '45%',
        transform: 'translate(-50%, -50%)',
      }}
    />
  ) : (
    <div className='orders'>
      {/* <h2 className='orders__title'>All orders</h2> */}
      <Stats mock={mock} isLoading={isLoadingOrdersEmetido} isFetching={isFetchingOrdersEmetido} />
      <div className='orders__body'>
        <OrdersTable
          data={orders}
          setData={setOrders}
          contacts={contacts}
          perPage={perPage}
          setPerPage={setPerPage}
          isFetchingOrders={isFetchingOrders}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          status={status}
          setStatus={setStatus}
          dateRange={dateRange}
          setDateRange={setDateRange}
          tradeNames={tradeNames}
          setTradeNames={setTradeNames}
          setProductsStatus={setProductsStatus}
          productsStatus={productsStatus}
          searchPlatform={searchPlatform}
          setSearchPlatform={setSearchPlatform}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
        />
      </div>
    </div>
  )
}
