import React, { Component } from 'react'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error(error, errorInfo)
  }

  handleClick = () => {
    // You can define what happens when the user clicks on the error message
    // For example, you can reload the page or redirect them to another page
    window.location.reload()
  }

  render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <h1>Something went wrong!</h1>
          <p>Click on Snoop Dogg to reload the page</p>
          <img
            src='https://media.giphy.com/media/XgXZ4LiyVKoM7Ikk1B/giphy.gif'
            alt='Snoop Dogg gif'
            onClick={this.handleClick}
            style={{ cursor: 'pointer' }}
          />
          <p>
            Also u can write me{' '}
            <a rel='noreferrer noopener' href='https://t.me/diffick'>
              LINK{' '}
            </a>
            and I will help to fix it
          </p>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
