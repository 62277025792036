import React from 'react'
import './TableModal.scss'

export default function TableModal({ keys, selectedItems, renderModal, setSelectedItems }) {
  return (
    <div style={{ display: selectedItems.length > 0 ? 'flex' : 'none' }} className='tableModal'>
      <div style={{ display: 'flex', justifyContent: 'space-around' }} className='tableModal__title'>
        <span>Selected Rows</span>
        <span style={{ fontSize: '40px' }}>{selectedItems.length}</span>
      </div>
      <div className='vertical__line'></div>
      <div className='tableModal__body'>
        {keys.map((elem) => {
          return <React.Fragment key={elem}>{renderModal(elem, selectedItems, setSelectedItems)}</React.Fragment>
        })}
        <div onClick={() => setSelectedItems([])} className='tableModal__body__btn'>
          x
        </div>
      </div>
    </div>
  )
}
