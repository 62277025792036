import React from 'react'
import TextField from '@mui/material/TextField'
import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green', // Цвет label при фокусе
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green', // Цвет подчеркивания при фокусе
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '25px',
      //   borderColor: 'red', // Цвет границы в нормальном состоянии
    },
    '&:hover fieldset': {
      opacity: 0.5, // Цвет границы при наведении
    },
    '&.Mui-focused fieldset': {
      borderColor: 'green', // Цвет границы при фокусе
    },
  },
})

function AuthTextField({ name, value, onChange, label }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '15px' }}>
      <Typography
        sx={{
          fontFamily: 'Montserrat',
          fontStyle: 'bold',
          fontSize: '12px',
          fontWeight: '600',
          lineHeight: '17px',
          marginBottom: '15px',
        }}
      >
        {label}
      </Typography>
      <StyledTextField
        size='small'
        required
        id={`custom-css-outlined-input-${name}`}
        name={name} // Добавь это
        value={value}
        onChange={onChange}
        variant='outlined'
      />
    </Box>
  )
}

export default AuthTextField
