import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as Whatsapp } from '../assets/svg/Whatsapp.svg'
import { ReactComponent as Telegram } from '../assets/svg/Telegram.svg'
import { ReactComponent as Browser } from '../assets/svg/Browser.svg'
import { useNavigate } from 'react-router'
import { CircularProgress } from '@mui/material'
import ChangeStatus from '../components/ChangeStatus/ChangeStatus'
import { mainApi } from '../store/services/mainApi'
import { OrdersCounter } from '../components/Contacts/ContactStats/OrdersCounter/OrdersCounter'
import BusinessType from '../components/Contacts/ContactStats/BusnessType/BusinessType'

export const renderComponentContacts = (key, options, data, visible) => {
  const { _id, phone, name, type, billAddress, email, tags, nif, iban, platform, tradeName, lastVisit } = data
  const navigate = useNavigate()

  const { name: statusName, color: statusColor } = status

  const { data: statuses, isLoading } = mainApi.useGetAllStatusesQuery()

  const currientStatuses = useMemo(() => {
    if (statuses) {
      return statuses.filter((status) => status.type === 'contacts')
    }
  }, [statuses])

  //modal

  const [statusModal, setStatusModal] = useState(false)

  const handleOpenModal = () => {
    setStatusModal(true)
  }

  switch (key) {
    case 'number':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item' data-tooltip={_id}>
          <span>
            <a href={`contacts/${data._id}`} target='_blank' rel='noreferrer' style={{ color: 'black' }}>
              {_id.slice(-6)}
            </a>
          </span>
        </div>
      )
    case 'phone':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={(typeof phone === 'object' ? phone[0].replace(/\s/g, '') : '') + (typeof phone === 'string' ? phone.replace(/\s/g, '') : '')}
        >
          <span style={{ textAlign: 'left' }}>
            {(typeof phone === 'object' ? phone[0].replace(/\s/g, '') : '') + (typeof phone === 'string' ? phone.replace(/\s/g, '') : '')}
          </span>
        </div>
      )
    case 'platform':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item platform'>
          <span>{platform == 'whatsapp' ? <Whatsapp /> : platform == 'telegram' ? <Telegram /> : platform == 'browser' ? <Browser /> : ''}</span>
        </div>
      )
    case 'codigo':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={tradeName ? tradeName : ''}
        >
          <span style={{ textAlign: 'left' }}>{tradeName ? tradeName : ''}</span>
        </div>
      )
    case 'name':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={name ? name : ''}
        >
          <span style={{ textAlign: 'left' }}>{name ? name : ''}</span>
        </div>
      )
    case 'type':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={
            (type == 'userUnion' && '🧑‍User') ||
            (type == 'user' && '📲Account') ||
            (type == 'supplier' && '🚚Supplier') ||
            (type == 'client' && '💰Business') ||
            (type == 'ambassador' && '👑Ambassador')
          }
        >
          <span style={{ textAlign: 'left' }}>
            {(type == 'userUnion' && '🧑‍User') ||
              (type == 'user' && '📲Account') ||
              (type == 'supplier' && '🚚Supplier') ||
              (type == 'client' && '💰Business') ||
              (type == 'ambassador' && '👑Ambassador')}
          </span>
        </div>
      )
    case 'status':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item status'
          onClick={handleOpenModal}
        >
          {isLoading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            <ChangeStatus
              type='contacts'
              data={data}
              statusModal={statusModal}
              setStatusModal={setStatusModal}
              statuses={currientStatuses}
              statusName1={statusName}
            />
          )}
        </div>
      )
    case 'address':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={billAddress?.address ? billAddress?.address : ''}
        >
          <span>{billAddress?.address ? billAddress?.address : ''}</span>
        </div>
      )
    case 'email':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={email ? email : ''}
        >
          <span>{email ? email : ''}</span>
        </div>
      )
    case 'tags':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={tags ? tags.join(', ') : ''}
        >
          <span>{tags ? tags.join(', ') : ''}</span>
        </div>
      )
    case 'nif':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={nif ? nif : ''}
        >
          <span>{nif ? nif : ''}</span>
        </div>
      )
    case 'iban':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={iban ? (Array.isArray(iban) ? iban.join('<br>') : iban) : ''}
        >
          <span>{iban ? (Array.isArray(iban) ? iban.join('<br>') : iban) : ''}</span>
        </div>
      )
    case 'lastvisit':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={lastVisit ? new Date(lastVisit).toLocaleDateString() : ''}
        >
          <span>{lastVisit ? new Date(lastVisit).toLocaleDateString() : ''}</span>
        </div>
      )
    case 'lastupdate':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
          <span>lastupdate</span>
        </div>
      )
    case 'orders':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
          <span>
            <OrdersCounter orders={data.orders} />
          </span>
        </div>
      )
    case 'btype':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
          <span style={{ textAlign: 'left' }}>
            <BusinessType orders={data.orders} />
          </span>
        </div>
      )
    case 'total':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
          <span style={{ textAlign: 'right' }}>{findOrdersTotal(data.orders)}</span>
        </div>
      )
  }
}

export function findOrdersTotal(orders) {
  let total = 0
  orders.forEach((order) => {
    total += order.items
      .map((item) => (item.marketPriceCP != '' ? parseFloat(item.marketPriceCP * (item.quantity || 0)) : 0))
      .reduce((acc, curr) => acc + curr, 0)
  })

  return total.toFixed(2) + '€'
}
