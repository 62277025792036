import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentOrder: {}
}

export const currentOrderSlice = createSlice({
  name: 'CurrentOrder',
  initialState,
  reducers: {
    setCurrentOrder: (state, action) => {
      state.currentUser = action.payload
    },
    changeStatus: (state, action) => {
      state.currentOrder.status = action.payload
    },
    changeClient: (state, action) => {

    }
  },
})

export const { setCurrentOrder } = currentOrderSlice.actions
export default currentOrderSlice.reducer
