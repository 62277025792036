import React, { useEffect } from 'react'
import './User.scss'
import { ReactComponent as Whatsapp } from '../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Telegram } from '../../../../assets/svg/Telegram.svg'
import { ReactComponent as Browser } from '../../../../assets/svg/Browser.svg'
import avatar from '../../../../assets/svg/Avatar.svg'
export default function User({ contact }) {
  const { img, type, platform } = contact
  return (
    <div className='user'>
      <div className='user__left'>
        <div
          className='user__left__img'
          style={{
            backgroundImage: `url(${img ? img : avatar})`,
            borderRadius: '5px',
          }}
        ></div>
        <div className='user__left__info'>
          <p>ID:</p>
          <div>
            <p className={type === 'user' ? 'active' : ''}>User</p>
            <div className={`checkbox ${type === 'user' || type === 'userUnion' ? 'active' : ''}`}></div>
          </div>
          <div>
            <p className={type === 'client' ? 'active' : ''}>Client</p>
            <div className={`checkbox ${type === 'client' || type === 'supplier' ? 'active' : ''}`}></div>
          </div>
          <div>
            <p className={type === 'ambassador' ? 'active' : ''}>Ambassador</p>
            <div className={`checkbox ${type === 'ambassador' ? 'active' : ''}`}></div>
          </div>
        </div>
      </div>
      <div className='user__right'>
        <Telegram height={30} width={30} className={platform === 'telegram' ? 'active' : ''} />
        <Whatsapp height={30} width={30} className={platform === 'whatsapp' ? 'active' : ''} />
        <Browser height={30} width={30} className={platform === 'browser' ? 'active' : ''} />
        {/* <Instagram height={30} width={30} /> */}
      </div>
    </div>
  )
}
