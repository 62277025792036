import React from 'react'
import '../../Table/Table.scss'
import TableBody from '../../Table/TableBody/TableBody'
import CatalogTableHeader from './CatalogTableHeader/CatalogTableHeader'
import { replaceAccent } from '../../../utils/accents'

export default function CatalogTable({ renderModal, modalKeys, data, renderComponent, keys, options, handleChoose }) {
  const [perPage, setPerPage] = React.useState(20)

  const [searchValue, setSearchValue] = React.useState('')
  const [searchType, setSearchType] = React.useState('')
  const [searchPlatform, setSearchPlatform] = React.useState('')
  const [spo, setSpo] = React.useState(false)
  const filtredData = React.useMemo(() => {
    console.log(searchValue, searchType, searchPlatform, 'filtredData')

    const normalizedSearchValue = replaceAccent(searchValue).toLowerCase()

    return data
      .filter((elem) => {
        if (spo) {
          return elem.SPOPrice !== null
        } else {
          return elem
        }
      })
      .filter((elem) => {
        if (searchPlatform === '') {
          return true
        } else {
          return elem.platform === searchPlatform
        }
      })
      .filter((elem) => {
        if (searchType === '') {
          return true // Include all elements when searchType is empty
        } else {
          return elem.type === searchType // Filter by type when searchType is not empty
        }
      })
      .filter((elem) => {
        return Object.keys(elem).some((key) => {
          const normalizedValue = replaceAccent(String(elem[key])).toLowerCase()
          return normalizedValue.includes(normalizedSearchValue)
        })
      })
  }, [searchValue, searchType, searchPlatform, spo, data])

  return (
    <div className='table'>
      <CatalogTableHeader
        data={data}
        setPerPage={setPerPage}
        perPage={perPage}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setSpo={setSpo}
        spo={spo}
      />
      <TableBody
        renderModal={renderModal}
        modalKeys={modalKeys}
        data={filtredData}
        perPage={perPage}
        renderComponent={renderComponent}
        keys={keys}
        handleChoose={handleChoose}
        options={options}
      />
    </div>
  )
}
