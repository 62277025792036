import React, { useState, useMemo } from 'react'
import './PeopleInfo.scss'

import MockImage from '../../../../../assets/png/Dashboard/Mock.png'
import Driver from '../../../../../assets/png/Dashboard/Driver.png'
import Buyer from '../../../../../assets/png/Dashboard/Buyer.jpg'

import { ReactComponent as Telegram } from '../../../../../assets/svg/Telegram.svg'
import { ReactComponent as Whatsapp } from '../../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Browser } from '../../../../../assets/svg/Browser.svg'
import { ReactComponent as Instagram } from '../../../../../assets/svg/Instagram.svg'
import { ReactComponent as BotoBot } from '../../../../../assets/svg/BotoBot.svg'

import { ReactComponent as ArrowDown } from '../../../../../assets/svg/ArrowDown.svg'

import botton from '../../../../../assets/png/boton.jpg'
import chocko from '../../../../../assets/png/choco.png'
import prezo from '../../../../../assets/png/prezo.png'
import { mainApi } from '../../../../../store/services/mainApi'

import { useDispatch } from 'react-redux'
import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { logAction } from '../../../../../utils/logsAction/logService'

const socials = (platform, innerWidth, innerHeight) => {
  switch (platform) {
    case 'telegram':
      return <Telegram width={innerWidth} height={innerHeight} />
    case 'whatsapp':
      return <Whatsapp width={innerWidth} height={innerHeight} />
    case 'browser':
      return <Browser width={innerWidth} height={innerHeight} />
    case 'instagram':
      return <Instagram width={innerWidth} height={innerHeight} />
    case 'botobot':
      return <BotoBot width={innerWidth} height={innerHeight} />
    case 'boton':
      return <img src={botton} alt='botton' width={innerWidth} height={innerHeight} />
    case 'choco':
      return <img src={chocko} alt='choco' width={innerWidth} height={innerHeight} />
    case 'prezo':
      return <img src={prezo} alt='prezo' width={innerWidth} height={innerHeight} />
    default:
      return null
  }
}

export default function PeopleInfo({ order, contact, contacts }) {
  const [changeClientToggle, setChangeClientToggle] = useState(false)
  const dispatch = useDispatch()
  // const [search, setSearch] = useState('')
  const [changeClient] = mainApi.useChangeClientMutation()
  // const { data: ordersData, lazyLoading } = mainApi.useGetAllOrdersQuery()
  const { data: contactsData, isLoading: isLoadingContacts } = mainApi.useGetAllContactsQuery()
  const [user, setUser] = useState({ label: contact?.name, value: contact })
  const usr = JSON.parse(localStorage.getItem('acc'))

  const changeClientHandle = async (selectedContact) => {
    if (selectedContact) {
      const body = {
        data: {
          ...order,
          userAcc: selectedContact,
          tradeName: selectedContact.tradeName ? selectedContact.tradeName : '',
        },
      }
      await changeClient(body)
        .unwrap()
        .then(() => {
          dispatch(mainApi.util.invalidateTags(['Order']))
          setChangeClientToggle(false)
        })

      logAction('Changed client', {
        orderId: order._id,
        client: selectedContact,
        oldClient: order?.userAcc,
        user: {
          ...usr,
          actions: {},
        },
      })
    } else {
      alert('Please select a contact')
    }
  }

  // const uniqueOrders = useMemo(() => {
  //   if (ordersData) {
  //     let ord = Array.from(
  //       new Set(ordersData.map((elem) => (elem?.userAcc?.name || elem?.tradeName || elem?.userAcc?.tradeName) && elem?.userAcc && elem?.userAcc)),
  //     )
  //     return Array.from(
  //       new Set(
  //         ord?.filter(
  //           (c) =>
  //             (c?.name.toLowerCase().includes(search.toLowerCase()) || (c?.tradeName && c?.tradeName.toLowerCase().includes(search.toLowerCase()))) &&
  //             c.phone !== contact?.phone &&
  //             c.name !== null &&
  //             c.name !== '' &&
  //             c.type !== 'supplier' &&
  //             c.type !== '',
  //         ),
  //       ),
  //     )
  //   } else {
  //     return []
  //   }
  // }, [search, ordersData])

  // let uniqueContacts = Array.from(
  //   new Set(
  //     contacts?.filter(
  //       (c) =>
  //         (c?.name.toLowerCase().includes(search.toLowerCase()) || (c?.tradeName && c?.tradeName.toLowerCase().includes(search.toLowerCase()))) &&
  //         c.phone !== contact?.phone &&
  //         c.name !== null &&
  //         c.name !== '' &&
  //         c.type !== 'userUnion' &&
  //         c.type !== 'supplier' &&
  //         c.type !== '',
  //     ),
  //   ),
  // )

  // const unique = Array.from(new Set([...uniqueContacts, ...uniqueOrders]))

  // const uniqueOrders = orders.map((contactData) => {
  //     if(contactData){
  //
  //         return {
  //             billAddress: {address: '', city: '', postalCode: ''},
  //             cif: [],
  //             code: "",
  //             dificilidad: false,
  //             email: "",
  //             enabled: true,
  //             extraPedidos: false,
  //             iban: [],
  //             img: "",
  //             lastVisit: 1705736442617,
  //             name: contactData?.userAcc?.name,
  //             nif: [],
  //             password: "",
  //             phone: "34000000000",
  //             platform: "browser",
  //             rate: 0,
  //             solvencia: false,
  //             swift: [],
  //             tags: [],
  //             tradeName: contactData?.tradeName,
  //             type: "Business",
  //         }
  //     }
  // })

  // const uniqueContactsNames = Array.from(new Set([...uniqueContacts, ...uniqueOrders]))
  return (
    <div className='peopleInfo'>
      <div
        className='peopleInfo__item'
        style={{marginRight: '50px' }}
      >
        <h4 onClick={() => window.open(`/admin/contacts/${contact?._id}`, '_blank')} style={{ cursor: 'pointer'}}>
          {contact?.name || 'No name'} ({order?.tradeName || contact?.tradeName || 'No trade name'})
        </h4>
        {socials(contact?.platform, 30, 30)}
        <div
          onClick={() => setChangeClientToggle(!changeClientToggle)}
          className={` peopleInfo__item peopleInfo__item__down ${changeClientToggle ? 'active' : ''} `}
          style={{
            transform: changeClientToggle ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        >
          <ArrowDown width={30} height={30} fill='black' />
        </div>

        {isLoadingContacts ? (
          <CircularProgress />
        ) : (
          <Autocomplete
            sx={{
              width: '100%',
              display: changeClientToggle ? 'block' : 'none',
              maxHeight: '300px',
              overflow: 'auto',
              position: 'absolute',
              zIndex: '1',
              left: '0',
              top: '100%',
              backgroundColor: 'white',
              marginTop: '10px',
            }}
            options={contactsData.map((c) => {
              return { label: c.name + '-' + c.tradeName + '-(' + c.type + ')', value: c }
            })}
            renderInput={(params) => <TextField {...params} />}
            onChange={(_, value) => {
              if (value) {
                changeClientHandle(value.value)
              }
            }}
            disableCloseOnSelect
            clearOnBlur
          />
        )}
        {/* <Select options={unique}>
            {changeClientToggle &&
              unique.map((c) => (
                <MenuItem value={c} key={c._id}>
                  {c.name} {c.tradeName ? `(${c.tradeName})` : ''}
                  {socials(c.platform, 15, 15)}
                </MenuItem>
              ))}
          </Select> */}

        {/* {changeClientToggle && <input onChange={(e) => setSearch(e.target.value)} value={search} type='text' />}
          {changeClientToggle &&
            unique.map((c) => (
              <div
                className='peopleInfo__item__change'
                style={{
                  cursor: 'pointer',
                  paddingTop: '10px',
                  ':hover': {
                    backgroundColor: 'red',
                  },
                }}
                onClick={() => changeClientHandle(c)}
                key={c._id}
              >
                {c.name} {c.tradeName ? `(${c.tradeName})` : ''}
                {socials(c.platform, 15, 15)}
              </div>
            ))} */}
      </div>

      <div className='peopleInfo__item'>
        <h4>PICKING</h4>
        <img src={MockImage} alt='MockImage' width={30} height={30} />
      </div>
      <div className='peopleInfo__item'>
        <h4>DRIVER</h4>
        <img src={Driver} alt='MockImage' width={30} height={30} />
      </div>
      <div className='peopleInfo__item'>
        <h4>BUYER</h4>
        <img src={Buyer} alt='MockImage' width={30} height={30} />
      </div>
    </div>
  )
}
