import React, { useState, useEffect } from 'react'
import './CatalogInfo.scss'
import { FormControl, TextField, ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changeCurrentProduct } from '../../../../store/slices/currentProduct'

const theme = createTheme({
  palette: {
    primary: {
      main: '#176318',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#176318',
          boxShadow: '0px 4px 4px 0px rgba(23, 99, 24, 0.25)',
        },
        root: {
          ':hover': {
            borderColor: 'red',
          },

          // backgroundColor: '#176318',
          // boxShadow: '0px 4px 4px 0px rgba(23, 99, 24, 0.25)',
        },
        input: {
          fontFamily: 'Inter',
          fieldset: {
            borderColor: '#176318',
            // backgroundColor: '#fff',
          },
        },
        inputSizeSmall: {
          // borderColor: '#176318',
        },
      },
    },
  },
})

export default function CatalogInfo({ product, reset }) {
  const save = useSelector((state) => state.currentProduct.save)
  const dispatch = useDispatch()

  const [img, setImg] = useState('')
  const [name, setName] = useState('')
  const [proveedor, setProveedor] = useState('')
  const [id, setId] = useState('')
  const [sku, setSku] = useState('')
  const [proveedorSku, setProveedorSku] = useState('')

  const catalogInfo = {
    img,
    name,
    nameProveedor: typeof proveedor === 'object' ? proveedor.join(',') : proveedor,
    sku,
    skuProveedor: proveedorSku,
    barcode: null,
  }

  useEffect(() => {
    setImg(product?.img || '')
    setName(product?.name || '')
    setProveedor(product?.proveedor || '')
    setId(product?._id || '')
    setSku(product?.sku || '')
    setProveedorSku(product?.skuProveedor || '')
  }, [product])
  //http://localhost:3000/admin/catalog/admin/catalog/659744e5b6e959e1e35f660d
  useEffect(() => {
    if (reset) {
      setImg('')
      setName('')
      setProveedor('')
      setId('')
      setSku('')
      setProveedorSku('')
    }
  }, [reset])

  useEffect(() => {
    if (save) {
      dispatch(changeCurrentProduct(catalogInfo))
    }
  }, [save])

  return (
    <div className='catalogInfo'>
      <div className='catalogInfo__left' style={{ backgroundImage: `url(${product?.img})`, backgroundSize: 'contain' }}></div>

      <ThemeProvider theme={theme}>
        <div className='catalogInfo__right'>
          <div className='catalogInfo__right__item'>
            <p className='gray'>NAME:</p>
            <FormControl sx={{ minWidth: 120 }}>
              <TextField label='Name' size='small' variant='outlined' value={name} onChange={(e) => setName(e.target.value)} />
            </FormControl>
          </div>
          <div className='catalogInfo__right__item'>
            <p className='gray'>Proveedor name:</p>
            <FormControl sx={{ minWidth: 120 }}>
              <TextField label='Proveedor name' size='small' variant='outlined' value={proveedor} onChange={(e) => setProveedor(e.target.value)} />
            </FormControl>
          </div>

          <div className='catalogInfo__right__item'>
            <p className='gray'>ID:</p>
            {/* <p>{product?._id}</p> */}
            <FormControl sx={{ minWidth: 120 }}>
              <TextField disabled label='Id' size='small' variant='outlined' value={id} onChange={(e) => setId(e.target.value)} />
            </FormControl>
          </div>
          <div className='catalogInfo__right__item'>
            <p className='gray'>SKU:</p>
            {/* <p>{product?.sku}</p> */}
            <FormControl sx={{ minWidth: 120 }}>
              <TextField label='SKU' size='small' variant='outlined' value={sku} onChange={(e) => setSku(e.target.value)} />
            </FormControl>
          </div>
          <div className='catalogInfo__right__item'>
            <p className='gray'>Proveedor SKU:</p>
            {/* <p>{product?.proveedorSKU}</p> */}
            <FormControl sx={{ minWidth: 120 }}>
              <TextField
                label='Proveedor Sku'
                size='small'
                variant='outlined'
                value={proveedorSku}
                onChange={(e) => setProveedorSku(e.target.value)}
              />
            </FormControl>
          </div>
        </div>
      </ThemeProvider>
    </div>
  )
}
