import React, { useEffect, useState } from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, useMediaQuery } from '@mui/material'
import { MuiTelInput } from 'mui-tel-input'
import { mainApi } from '../../../store/services/mainApi'
import AuthTextField from '../../Rcomp/AuthTextField/AuthTextField'
import CustomSelect from '../../Rcomp/AuthSelect/AuthSelect'
const Registration = ({ phone }) => {
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [register] = mainApi.useRegisterAdminMutation()

  const [formData, setFormData] = useState({
    name: '',
    username: '',
    userType: '',
    phoneNumber: phone || '',
    // email: '',
    password: '',
    repeatPassword: '',
    code: '',
  })

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    await register({
      data: formData,
    })
      .unwrap()
      .then((res) => {
        console.log(res, 'res')
        alert('Registration success. Please login')
        window.location.reload()
      })
      .catch((e) => {
        alert(e.data)
      })
  }

  useEffect(() => {
    setFormData({ ...formData, phoneNumber: phone })
  }, [phone])

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: '90vh',
        backgroundColor: '#f5f5f5',
        padding: mobileView ? '20px' : '20px 50px',
        overflow: 'auto',

        borderRadius: '20px',
      }}
    >
      <h2 style={{ textAlign: 'center' }}>
        WELCOME to TPO<span>Admin</span>
      </h2>
      <h3>Sign Up</h3>
      <AuthTextField label='Name' name='name' value={formData.name} onChange={handleChange} />
      {/* <TextField label='Name' variant='outlined' name='name' value={formData.name} onChange={handleChange} fullWidth margin='normal' /> */}
      {/* <TextField label='User Name' variant='outlined' name='username' value={formData.username} onChange={handleChange} fullWidth margin='normal' /> */}
      <CustomSelect name='userType' value={formData.userType} label='User Type' onChange={handleChange} />
      <AuthTextField label='Code' name='code' value={formData.code} onChange={handleChange} />
      {/* <TextField required label='Code' variant='outlined' name='code' value={formData.code} onChange={handleChange} fullWidth margin='normal' /> */}

      {formData.userType === 'admin' && (
        <AuthTextField label='Admin Code' name='adminPass' value={formData.adminPass} onChange={handleChange} />
        // <TextField
        //   required
        //   label='Admin Code'
        //   variant='outlined'
        //   name='adminPass'
        //   value={formData.adminPass}
        //   onChange={handleChange}
        //   fullWidth
        //   margin='normal'
        // />
      )}

      <MuiTelInput
        required
        MenuProps={{ PaperProps: { style: { maxHeight: '200px', width: '300px' } } }}
        InputProps={{
          disableUnderline: true,
          sx: {
            border: 'none',
          },
        }}
        variant='standard'
        sx={{
          width: mobileView ? '90%' : '80%',
          margin: '15px',

          '& .MuiTelInput-TextField': {
            border: 'none',
          },
          '& .MuiTelInput-IconButton': {
            border: '1px solid #186419',
            width: 50,
            height: 40,
            borderRadius: '20px 0px 0px 20px',

            '&:hover': {
              backgroundColor: '#186419',
              color: 'white',
            },
          },
          '& .MuiInputBase-input': {
            paddingTop: 1,
            paddingBottom: 1,
            paddingLeft: 1,
            // paddingRight: 10,
            borderTop: '1px solid #186419',
            borderBottom: '1px solid #186419',
            borderRight: '1px solid #186419',
            borderLeft: '1px solid #186419',

            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            // borderLeft: '1px solid #186419',
          },
          '& .MuiInputBase-root': {
            border: 'none',
          },
        }}
        value={formData.phoneNumber}
        onChange={(value) => setFormData({ ...formData, phoneNumber: value })}
      />
      {/* <TextField
        label='Email'
        type='email'
        variant='outlined'
        name='email'
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
      /> */}
      {/* <AuthTextField label='Email' name='email' value={formData.email} onChange={handleChange} /> */}
      {/* <TextField
        label='Password'
        type='password'
        variant='outlined'
        name='password'
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
      /> */}
      <AuthTextField label='Password' name='password' value={formData.password} onChange={handleChange} />
      {/* <TextField
        label='Repeat Password'
        type='password'
        variant='outlined'
        name='repeatPassword'
        value={formData.repeatPassword}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
      /> */}
      <AuthTextField label='Repeat Password' name='repeatPassword' value={formData.repeatPassword} onChange={handleChange} />
      <Button
        sx={{
          width: 'max-content',
          borderRadius: '20px',
          backgroundColor: '#5FC56E',

          '&:hover': {
            opacity: 0.9,
          },
        }}
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
      >
        Register
      </Button>
    </form>
  )
}

export default Registration
