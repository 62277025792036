import { Box, Button, Modal, TextField, useMediaQuery } from '@mui/material'
import React, { useState, useEffect } from 'react'

export default function Carts({ contact }) {
  const [openCart, setOpenCart] = useState(false)
  const mobileView = useMediaQuery('(max-width: 798px)')

  return (
    <div
      style={{
        // display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        display: contact.currentCart ? 'flex' : 'none',
        flexDirection: 'column',
        width: '100px',
        height: '100px',
        backgroundColor: 'red',
        margin: '10px auto',
      }}
    >
      <Button onClick={() => setOpenCart(true)} variant='contained'>
        Cart
      </Button>

      <Modal open={openCart} onClose={() => setOpenCart(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: mobileView ? '300px' : '750px',
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            backgroundColor: 'white',
          }}
        >
          <Box sx={{ display: 'flex', borderBottom: '1px solid black', width: '100%', gap: '10px' }}>
            <p>Name: {contact.name}</p>
            <p>Phone: {contact.phone}</p>
            <p>Tradename: {contact.tradeName}</p>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%' }}>
            {contact.currentCart &&
              contact.currentCart.map((item) => (
                <Box sx={{ display: 'flex', gap: '10px', width: '100%', alignItems: 'center', textAlign: 'center' }} key={item._id}>
                  <img src={item.img} alt={item.name} width={50} height={50} />
                  <p>{item.name}</p>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Quantity:</p>
                    <TextField sx={{ width: '50px' }} size='small' value={item.quantity} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Price:</p>

                    <TextField sx={{ width: '50px' }} size='small' value={item.marketPriceCP} />
                  </div>{' '}
                  {/* <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                  }}
                >
                  <p style={{ fontSize: '16px', fontWeight: 'bold' }}>Quantity:</p>

                  <TextField sx={{ width: '50px' }} size='small' value={item.quantity} />
                </div> */}
                </Box>
              ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button variant='contained' color='error' sx={{ width: '50%' }} onClick={() => setOpenCart(false)}>
              Close
            </Button>
            <Button variant='contained' color='success' sx={{ width: '50%' }}>
              Create Order
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
