import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { CircularProgress, useMediaQuery } from '@mui/material'

import { mainApi } from '../../../store/services/mainApi'
import StyledBox from '../../Rcomp/StyledBox/StyledBox'
// import OrdersChangeTable from '../Orders/OrdersChange/OrdersChangeTable/OrdersChangeTable'
import { DataGrid, GridPagination, GridToolbar, GridToolbarContainer, GridToolbarExport, useGridApiContext } from '@mui/x-data-grid'
import { ReactComponent as Like } from '../../../assets/svg/Like.svg'
import { ReactComponent as InProgress } from '../../../assets/svg/inProgress.svg'
import { ReactComponent as Plus } from '../../../assets/svg/Plus.svg'
import { ReactComponent as Production } from '../../../assets/svg/cap.svg'
import { ReactComponent as Delivery } from '../../../assets/svg/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../assets/svg/Congelador.svg'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { Box } from '@mui/material'
import swipeLeft from '../../../assets/svg/swipe-left-icon.svg'

import { usePDF } from '@react-pdf/renderer'
import Albaran from '../../PrintDocuments/Albaran/Albaran'
export const StaticOrderFP = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [orderId, setOrderId] = useState(location.pathname.split('/').pop())

  const mobile = useMediaQuery('(max-width: 768px)')
  const {
    data: order,
    isLoading,
    isError,
  } = mainApi.useGetOrdersByIdQuery(orderId, {
    pollingInterval: 50000,
  })

  const [deliveryDate, setDeliveryDate] = useState('')
  const [creationDate, setCreationDate] = useState('')
  const [creationTime, setCreationTime] = useState('')
  const [items, setItems] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (order) {
      document.title = `Order ${order._id.slice(-6)} ${order.tradeName || order.userAcc?.tradeName || 'No TradeName'}`
      // Устанавливаем deliveryDate после получения order
      setDeliveryDate(order.deliveryDate ? order.deliveryDate : '')

      // Устанавливаем items после получения order
      setItems(order.items)

      // Устанавливаем дату и время создания после получения order
      const date = new Date(order.date)
      const localeDate = date.toLocaleDateString() // Получаем локализованную дату
      const localeTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) // Получаем локализованное время

      setCreationDate(localeDate)
      setCreationTime(localeTime)

      // Устанавливаем rows после получения order
      setRows(order.items)
    }
  }, [order])

  const statusBackground = (status) => {
    switch (status) {
      case 'Ready':
        return <Like width={20} height={20} />
      case 'PROCESSING':
        return <InProgress width={20} height={20} />
      case 'NOT AVAILABLE':
        return '⛔'
      case 'CHECKING':
        return '👁️'
      case 'PRODUCTION':
        return <Production width={20} height={20} /> //Production
      case 'SHOPPING':
        return <Delivery fill='#6D26E1' width={20} height={20} />
      case 'CONGEDOR':
        return <CONGEDOR width={20} height={20} />
      case 'DEVOLUCION':
        return '🔙'
      case 'CONFIRMAR':
        return '👍'
      default:
        return null
    }
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [statusName, setStatusName] = useState('')

  const handleCellClick = (params, event) => {
    if (params.field === 'status') {
      setStatusName(params.row.status.name) // предполагается, что статус находится в params.row.status
      setOpenDialog(true)
    }
  }

  const CustomFooter = () => {
    const apiRef = useGridApiContext()

    return (
      <GridToolbarContainer sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {/* <GridToolbarExport /> */}
        {/* Здесь можно добавить любой кастомный контент */}
        <Box>
          <img src={swipeLeft} alt='swipe' width={30} height={30} />
        </Box>
        <Box>
          <GridPagination />
        </Box>
        {/* Кнопки переключения страниц будут добавлены автоматически */}
      </GridToolbarContainer>
    )
  }

  const columns = [
    {
      field: 'img',
      headerName: 'Image',
      width: 70,
      sortable: false, // Отключает сортировку для колонки
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      renderHeader: () => {
        return <p style={{ margin: '0 auto' }}>Image</p>
      },
      renderCell: (params) => {
        return <img src={params.value} alt='' style={{ width: '50px', height: '50px' }} />
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 70,
      sortable: false,
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      renderCell: (params) => (
        <p title={params.value.name} style={{ margin: '0 auto' }}>
          {statusBackground(params.value.name)}
        </p>
      ),
    },

    {
      field: 'currentUnit',
      headerName: 'Unit',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 70,
      renderCell: (params) => <p>{params.value}</p>,
    },
    {
      field: 'quantity',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      headerName: 'Quantity',
      width: 80,
      renderCell: (params) => <p>{params.value}</p>,
    },

    {
      field: 'marketPriceCP',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      headerName: 'Price',
      width: 70,
      renderCell: (params) => <p>{params.value}€</p>,
    },
    {
      field: 'IVA',
      headerName: 'IVA',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 50,
      renderCell: (params) => <p>{params.value ? params.value : 0}%</p>,
    },

    {
      field: 'total',
      headerName: 'Total',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 80,
      renderCell: (params) => <p>{params.row ? (params.row.quantity * params.row.marketPriceCP).toFixed(2) : ''}€</p>,
    },
    {
      field: 'name',
      headerName: 'Name',
      filterable: false, // Отключает фильтрацию для колонки
      disableColumnMenu: true, // Отключает меню столбцов
      width: 300,
      renderCell: (params) => (
        <p
          style={{
            maxWidth: '300px',
            overflow: 'hidden',
            whiteSpace: 'normal',
            wordWrap: 'break-word',
            // marginBottom: 0, // Убираем отступ снизу, если он есть
          }}
        >
          {params.value}
        </p>
      ),
    },
  ]

  const total = useMemo(() => {
    if (order && order.items) {
      if (order?.deliveryPrice) {
        return (
          order.deliveryPrice +
          order.items
            .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0)
        )
      } else {
        return order.items
          .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
          .reduce((acc, curr) => acc + curr, 0)
      }
    }
    return 0 // return a default value if items is falsy
  }, [order])

  const IVA = useMemo(() => {
    let totalIva = 0
    if (order && order.items) {
      totalIva = order.items
        .map((item) => (item.IVA ? (item.IVA / 100) * (item.marketPriceCP * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0) // Provide 0 as the initial value
    }
    console.log(totalIva, 'IVA')
    // if (order.items && order.items.length > 0) {
    //   totalIva = totalIva / order.items.length
    // }
    return totalIva ? totalIva : 0 // return a default value if items is falsy
  }, [order])

  //albaran
  const [instancePDf, updatePDF] = usePDF({
    document: <Albaran items={[]} order={{}} contact={{}} />,
  })

  const [printModal, setPrintModal] = useState(false)

  const itemsForAlbaran = useMemo(() => {
    if (order?.items) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      order.items.forEach((item) => {
        const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

        if (!uniqueItemsMap[key]) {
          // Если элемент с таким ключом еще не существует, добавляем его в объект
          uniqueItemsMap[key] = {
            conceptio: item.name,
            sku: item.sku,
            quantity: item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total: (item.marketPriceCP * item.quantity).toFixed(2),
          }
        } else {
          // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
          uniqueItemsMap[key].quantity += item.quantity
          uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
        }
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      return uniqueItems
    } else {
      return []
    }
  }, [order])

  useEffect(() => {
    if (order) {
      updatePDF(<Albaran items={itemsForAlbaran} order={order} contact={order?.userAcc} />)
    }
  }, [order, itemsForAlbaran])

  const balance = useMemo(() => {
    if (order) {
      return parseFloat(order?.paidValue)
        ? (
            (parseFloat(order?.deliveryPrice) ? parseFloat(order.deliveryPrice) : 0) +
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0)
              .toFixed(2) -
            (parseFloat(order?.paidValue).toFixed(2) || 0)
          ).toFixed(2)
        : (
            (parseFloat(order?.deliveryPrice) ? parseFloat(order.deliveryPrice) : 0) +
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0)
          ).toFixed(2)
    }
  }, [order])

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div style={{ width: '95%', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: mobile ? 'column' : 'row',
          gap: '20px',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <StyledBox headerText={'Order details'} defaultVisible={false} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
          <div className='orderInfo__box__item'>
            <p>
              ORDER: <span>{order.idTucomida ? order.idTucomida : order?._id ? order?._id.slice(-6) : ''}</span>
            </p>
          </div>

          <div className='orderInfo__box__item'>
            <p>
              PAYMENT TYPE:{' '}
              <span>
                {order?.paymentType !== '-'
                  ? parseFloat(order?.paymentType)
                    ? 'Сдача с ' + order?.paymentType + '€'
                    : order?.paymentType
                  : 'Без сдачи'}
              </span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              CREATION:{' '}
              <span>
                {creationDate} {creationTime}
              </span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              DELIVERY: <span>{deliveryDate ? deliveryDate : ''}</span>
              <span>{deliveryDate && deliveryDate.includes(',') ? (deliveryDate.split(',')[1].slice(0, -6) > 12 ? '🌛' : '🌞') : ''}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              TOTAL ITEMS: <span>{items ? items.length : 0}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              COMMENT:<span>{order?.description ? order?.description : 'No comment'}</span>
            </p>
          </div>
        </StyledBox>
        <StyledBox headerText={'Payment and history'} defaultVisible={true} sx={{ width: 'max-content', minWidth: '250px', maxWidth: '100%' }}>
          <div className='orderInfo__box__item'>
            <p>
              Paid: <span>{(parseFloat(order?.paidValue) ? parseFloat(order?.paidValue).toFixed(2) : '0.00') + '€'}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Debt: <span style={{ color: balance <= 0 ? 'green' : 'red', textAlign: 'right' }}>{balance}€</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Creator: <span>{order?.userAcc?.name}</span>
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '10px 0',
              border: '1px solid #2a2d2f',
              borderRadius: '10px',
              padding: '10px',
              cursor: 'pointer',
              color: '#000',
              fontWeight: 'bold',

              '&:hover': {
                backgroundColor: '#2a2d2f',
                color: '#fff',
              },
            }}
            onClick={() => navigate(`/client/static/${order?.userAcc?._id}`)}
          >
            Mis pedidos
          </div>
        </StyledBox>
        <StyledBox headerText={'Totals'} defaultVisible={false} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
          <div className='orderInfo__box__item'>
            <p>
              GOODS W/O TVA: <span>{(total - IVA - (order.deliveryPrice ? +order.deliveryPrice : 0)).toFixed(2)}€</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              TVA: <span>{IVA.toFixed(2)}&euro;</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Delivery: <span>{order?.deliveryPrice ? order?.deliveryPrice : '0.00'}&euro;</span>
            </p>
          </div>

          <div className='orderInfo__box__item'>
            <p>
              Total: <span>{total.toFixed(2)}€</span>
            </p>
          </div>
        </StyledBox>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px 0',
        }}
      >
        <p>STATUS: </p>
        <p
          style={{
            textTransform: 'capitalize',
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: order?.status?.color ? order?.status?.color : '#fff',
            border: '1px solid ' + (order?.status?.color ? order?.status?.color : '#fff'),
            margin: '0 5px',
          }}
        >
          {order?.status.name ? order?.status.name : 'In progress'}
        </p>
        <a
          href={instancePDf.url}
          download={`Albaran ${order?.idTucomida ? order?.idTucomida : order?._id ? order?._id.slice(-6) : 'N/A'}.pdf`}
          target='_blank'
          rel='noreferrer'
        >
          Albarán 🖨️
        </a>
      </div>
      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight={true}
          sx={{
            marginTop: '10px',
            backgroundColor: '#fff',
          }}
          onCellClick={handleCellClick}
          // slots={{ toolbar: GridToolbar }}
          // disableColumnFilter
          // slotProps={{
          //   toolbar: {
          //     showQuickFilter: true,
          //   },
          // }}
          rows={rows}
          columns={columns}
          components={{
            Footer: CustomFooter,
          }}
        />
      </div>
    </div>
  )
}
