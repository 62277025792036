import { Box, CircularProgress, Container, TextField } from '@mui/material'
import React, { useState, useEffect, useCallback } from 'react'
import { mainApi } from '../../store/services/mainApi'
import debounce from 'lodash.debounce'

export default function LogsPage() {
  useEffect(() => {
    document.title = 'LogsPage'
  }, [])

  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [limitPerPage, setLimitPerPage] = useState(20)

  // Debounce the search input
  const debounceSearch = useCallback(
    debounce((value) => {
      setDebouncedSearch(value)
    }, 500), // Adjust the delay as needed
    [],
  )

  const handleSearchChange = (e) => {
    const value = e.target.value
    setSearch(value)
    debounceSearch(value)
  }

  const { data: logsData, isLoading } = mainApi.useGetLogsQuery(
    {
      limit: limitPerPage,
      search: debouncedSearch,
    },
    // { pollingInterval: 5000 },
  )

  return (
    <Container>
      <Box>
        <h1>LogsPage</h1>

        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <TextField fullWidth label='Search' value={search} onChange={handleSearchChange} />
            {logsData &&
              logsData.map((log) => (
                <Box
                  sx={{
                    marginBottom: '10px',
                    padding: '10px',
                    backgroundColor: '#fff',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  }}
                  key={log.id}
                >
                  <p>{`${log.action} || ${log?.details?.user?.name} || ${new Date(log.timestamp)} || ${log?.details?.user?.phoneNumber}`}</p>
                  <p>{JSON.stringify(log.details)}</p>
                </Box>
              ))}
          </Box>
        )}
      </Box>
    </Container>
  )
}
