// import { ReactComponent as Whatsapp } from '../assets/svg/Whatsapp.svg'
// import { ReactComponent as Telegram } from '../assets/svg/Telegram.svg'
// import { ReactComponent as Browser } from '../assets/svg/Browser.svg'
import React, { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mainApi } from '../store/services/mainApi'
import ChangeStatus from '../components/ChangeStatus/ChangeStatus'
import { useDispatch } from 'react-redux'
import { CircularProgress, Link } from '@mui/material'
export const renderComponent = (key, options, data, visible) => {
  const navigate = useNavigate()
  const {
    sku,
    enabled,
    _id: id,
    name,
    category,
    proveedor,
    skuProveedor,
    unit,
    barcode,
    ourPrice,
    marketPrice,
    marketPriceDP,
    marketPriceSDP,
    marketPriceCP,
    IVA,
    status,
  } = data

  const { name: statusName, color: statusColor } = status

  const { data: statuses, isLoading } = mainApi.useGetAllStatusesQuery()

  const currientStatuses = useMemo(() => {
    if (statuses) {
      return statuses.filter((status) => status.type === 'products')
    }
  }, [statuses])

  //modal

  const [statusModal, setStatusModal] = useState(false)

  const handleOpenModal = () => {
    setStatusModal(true)
  }

  switch (key) {
    case 'id':
      return (
        <div
          onClick={() => navigate(`admin/catalog/${data._id}`)}
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={id ? id : ''}
        >
          <span>{id ? id : ''}</span>
        </div>
      )
    case 'sku':
      return (
        <div
          // onClick={() => navigate(`admin/catalog/${data._id}`)}
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={sku ? sku : ''}
        >
          <span style={{ textAlign: 'left' }}>
            <a href={`/admin/catalog/${data._id}`} style={{ color: 'black' }} target='_blank' rel='noreferrer'>
              {sku ? sku : ''}
            </a>
          </span>
        </div>
      )
    case 'barcode':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={barcode ? barcode : ''}
        >
          <span style={{ textAlign: 'left' }}>{barcode ? barcode : ''}</span>
        </div>
      )
    case 'image':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item platform'>
          <span>
            {data?.img ? (
              <img style={{ width: '50px', height: '50px' }} src={data.img} alt={data.name} />
            ) : (
              <img style={{ width: '50px', height: '50px' }} src={'https://imgur.com/b6ByMHk.jpg'} alt={data.name} />
            )}
          </span>
        </div>
      )
    case 'status':
      return (
        <div
          onClick={handleOpenModal}
          style={{ backgroundColor: statusColor ? statusColor : '', display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item status'
        >
          {isLoading ? (
            <CircularProgress size={20} color='inherit' />
          ) : (
            <ChangeStatus
              type='products'
              data={data}
              statusModal={statusModal}
              setStatusModal={setStatusModal}
              statuses={currientStatuses}
              statusName1={statusName}
            />
          )}
        </div>
      )
    case 'name':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={name ? name : ''}
        >
          <span style={{ textAlign: 'left' }}>{name ? name : ''}</span>
        </div>
      )
    case 'category':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={category ? category : ''}
        >
          <span style={{ textAlign: 'left' }}> {category ? category : ''}</span>
        </div>
      )
    case 'proveedor':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={proveedor ? proveedor : ''}
        >
          <span style={{ textAlign: 'right' }}>{proveedor ? proveedor : ''}</span>
        </div>
      )
    case 'unit':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={unit ? unit : ''}
        >
          <span style={{ textAlign: 'left' }}>{unit ? unit : ''}</span>
        </div>
      )
    case 'mbp':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={marketPrice ? marketPrice : ''}
        >
          <span>{marketPrice ? marketPrice.toFixed(2) : ''} &euro;</span>
        </div>
      )
    case 'dp':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={marketPriceDP ? marketPriceDP : ''}
        >
          <span>{marketPriceDP ? marketPriceDP.toFixed(2) : ''}&euro;</span>
        </div>
      )
    case 'sdp':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={marketPriceSDP ? marketPriceSDP : ''}
        >
          <span>{marketPriceSDP ? marketPriceSDP.toFixed(2) : ''}&euro;</span>
        </div>
      )
    case 'iva':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={IVA ? IVA : ''}
        >
          <span>{IVA ? IVA : ''}</span>
        </div>
      )
    case 'entryprice':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={ourPrice ? ourPrice : ''}
        >
          <span>{ourPrice ? ourPrice.toFixed(2) : ''}&euro;</span>
        </div>
      )
    case 'cp':
      return (
        <div
          style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }}
          className='tableBodyItem__item'
          data-tooltip={marketPriceCP ? marketPriceCP : ''}
        >
          <span>{marketPriceCP ? marketPriceCP.toFixed(2) : ''}&euro;</span>
        </div>
      )
    case 'profit':
      return (
        <div style={{ display: options[key] ? 'block' : 'none', width: `${80 / visible}%` }} className='tableBodyItem__item'>
          <span>{findProfit(data).toFixed(2)} &euro;</span>
        </div>
      )
    default:
      return null
  }
}

export const findProfit = (data) => {
  return (data.marketPriceCP - data.ourPrice) * (1 - data.IVA / 100)
}
