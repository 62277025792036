import React, { useEffect, useMemo, useState } from 'react'
import '../../../Table/Table.scss'
import OrdersChangeTableHeader from './OrdersChangeTableHeader/OrdersChangeTableHeader'
import OrdersChangeTableBody from './OrdersChangeTableBody/OrderChangeTableBody'

export default function OrdersChangeTable({
  editOrder,
  setEditOrder,
  order,
  favoriteItems,
  setFavoriteItems,
  data,
  setData,
  progressItems,
  setProgressItems,
  productionItems,
  setProductionItems,
  shoppingItems,
  setShoppingItems,
}) {
  const [perPage, setPerPage] = useState(20)

  const [searchValue, setSearchValue] = useState('')

  const [categorySortDerection, setCategorySortDerection] = useState({
    name: 0,
    value: 0,
  })

  useEffect(() => {
    console.log(categorySortDerection, 'categorySortDerection')
  }, [categorySortDerection])

  const filtredData = useMemo(() => {
    return data
      .filter((elem) => {
        return Object.keys(elem).some((key) => {
          return String(elem[key]).toLowerCase().includes(searchValue.toLowerCase())
        })
      })
      .sort((a, b) => {
        if (categorySortDerection.name) {
          if (categorySortDerection.name === 'IVA') {
            if (a[categorySortDerection.name] < b[categorySortDerection.name]) {
              return categorySortDerection.value
            }
            if (a[categorySortDerection.name] > b[categorySortDerection.name]) {
              return -categorySortDerection.value
            }
            return 0
          } else if (
            categorySortDerection.name === 'ID' ||
            categorySortDerection.name === 'SKU' ||
            categorySortDerection.name === 'Name' ||
            categorySortDerection.name === 'Category' ||
            categorySortDerection.name === 'Proveedor'
          ) {
            const valueB = String(a[categorySortDerection.name.toLowerCase()]).toLowerCase()
            const valueA = String(b[categorySortDerection.name.toLowerCase()]).toLowerCase()
            if (valueA < valueB) {
              return categorySortDerection.value
            }
            if (valueA > valueB) {
              return -categorySortDerection.value
            }
            return 0
          } else if (categorySortDerection.name === 'Unit') {
            if (a.currentUnit < b.currentUnit) {
              return categorySortDerection.value
            }
            if (a.currentUnit > b.currentUnit) {
              return -categorySortDerection.value
            }
            return 0
          } else if (categorySortDerection.name === 'Sold') {
            if (a.quantity < b.quantity) {
              return categorySortDerection.value
            }
            if (a.quantity > b.quantity) {
              return -categorySortDerection.value
            }
            return 0
          } else if (categorySortDerection.name === 'Requested') {
            if (a.quantityOrder < b.quantityOrder) {
              return categorySortDerection.value
            }
            if (a.quantityOrder > b.quantityOrder) {
              return -categorySortDerection.value
            }
            return 0
          } else if (categorySortDerection.name === 'Price') {
            if (a.marketPrice < b.marketPrice) {
              return categorySortDerection.value
            }
            if (a.marketPrice > b.marketPrice) {
              return -categorySortDerection.value
            }
            return 0
          } else {
            if (a[categorySortDerection.name.toLowerCase()] < b[categorySortDerection.name.toLowerCase()]) {
              return categorySortDerection.value
            }
            if (a[categorySortDerection.name.toLowerCase()] > b[categorySortDerection.name.toLowerCase()]) {
              return -categorySortDerection.value
            }
            return 0
          }
        } else {
          return 0
        }
      })
  }, [searchValue, data, categorySortDerection])

  useEffect(() => {
    console.log(filtredData, 'filtredData')
  }, [filtredData])

  return (
    <div className='table'>
      <OrdersChangeTableHeader
        order={order}
        setData={setData}
        data={data}
        setPerPage={setPerPage}
        perPage={perPage}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        setEditOrder={setEditOrder}
        editOrder={editOrder}
      />
      <OrdersChangeTableBody
        editOrder={editOrder}
        setEditOrder={setEditOrder}
        favoriteItems={favoriteItems}
        setFavoriteItems={setFavoriteItems}
        data={filtredData}
        perPage={perPage}
        order={order}
        progressItems={progressItems}
        setProgressItems={setProgressItems}
        productionItems={productionItems}
        setProductionItems={setProductionItems}
        shoppingItems={shoppingItems}
        setShoppingItems={setShoppingItems}
        setCategorySortDerection={setCategorySortDerection}
        categorySortDerection={categorySortDerection}
      />
    </div>
  )
}
