function classifyPoSForClient(clientOrders) {
  const posTypes = []

  if (isPoSClients(clientOrders)) {
    posTypes.push('PoS Clients')
  }
  if (isCorePoS(clientOrders)) {
    posTypes.push('Core PoS')
  }
  if (isActivePoS(clientOrders)) {
    posTypes.push('Active PoS')
  }
  if (isSmallPoS(clientOrders)) {
    posTypes.push('Small PoS')
  }
  if (isOcasionalPoS(clientOrders)) {
    posTypes.push('Ocasional PoS')
  }
  if (isNewCorePoS(clientOrders)) {
    posTypes.push('New Core PoS')
  }
  if (isNewPoS(clientOrders)) {
    posTypes.push('New PoS')
  }
  if (isDecreasedPoS(clientOrders)) {
    posTypes.push('Decreased PoS')
  }
  if (isAlertPoS(clientOrders)) {
    posTypes.push('Alert PoS')
  }
  if (isLostPoS(clientOrders)) {
    posTypes.push('Lost PoS')
  }
  if (posTypes.length === 0) {
    posTypes.push('Unknown PoS Category')
  }

  return posTypes
}

// Check for PoS Clients category
function isPoSClients(clientOrders) {
  return clientOrders.length >= 1 && isWithinDateRange(clientOrders[0].date, '01-01-2024', '01-01-2024')
}

// Check for Core PoS category
function isCorePoS(clientOrders) {
  const totalAmount = calculateTotalAmount(clientOrders)
  const monthsWithOrders = findMonthsWithOrders(clientOrders)
  return monthsWithOrders >= 9 && totalAmount >= 1000
}

// Check for Active PoS category
function isActivePoS(clientOrders) {
  const monthsWithOrders = findMonthsWithOrders(clientOrders)
  return monthsWithOrders >= 8
}

// Check for Small PoS category
function isSmallPoS(clientOrders) {
  const recentMonthsOrders = getRecentMonthsOrders(clientOrders, 3)
  const totalOrders = recentMonthsOrders.reduce((sum, monthOrders) => sum + monthOrders.length, 0)
  return totalOrders >= 4 && totalOrders <= 8
}

// Check for Ocasional PoS category
function isOcasionalPoS(clientOrders) {
  const recentMonthsOrders = getRecentMonthsOrders(clientOrders, 3)
  const totalOrders = recentMonthsOrders.reduce((sum, monthOrders) => sum + monthOrders.length, 0)
  return totalOrders >= 1 && totalOrders <= 3
}

// Check for New Core PoS category
function isNewCorePoS(clientOrders) {
  const recentMonthsOrders = getRecentMonthsOrders(clientOrders, 3)
  const numOrdersLast3Months = recentMonthsOrders.reduce((sum, monthOrders) => sum + monthOrders.length, 0)
  return numOrdersLast3Months >= 8 && recentMonthsOrders.length >= 2
}

// Check for Decreased PoS category
function isDecreasedPoS(clientOrders) {
  const recentMonthsOrders = getRecentMonthsOrders(clientOrders, 2)
  if (recentMonthsOrders.length < 2) {
    return false
  }
  const numOrdersMonth1 = recentMonthsOrders[0].length
  const numOrdersMonth2 = recentMonthsOrders[1].length
  return numOrdersMonth2 < numOrdersMonth1 * 0.7
}

// Check for New PoS category
function isNewPoS(clientOrders) {
  if (clientOrders.length === 0) return false
  const totalOrders = clientOrders.length
  return totalOrders < 8 || calculateTotalAmount(clientOrders) < 1000
}

// Check for Alert PoS category
function isAlertPoS(clientOrders) {
  if (clientOrders.length === 0) return false
  const daysSinceLastOrder = calculateDaysSinceLastOrder(clientOrders)
  return daysSinceLastOrder >= 10
}

// Check for Lost PoS category
function isLostPoS(clientOrders) {
  if (clientOrders.length === 0) return false
  const daysSinceLastOrder = calculateDaysSinceLastOrder(clientOrders)
  return daysSinceLastOrder >= 30
}

// Helper functions

// Check if a date is within a specified range
function isWithinDateRange(dateString, startDateString, endDateString) {
  const startDate = new Date(startDateString)
  const endDate = new Date(endDateString)
  const date = new Date(dateString)
  return date >= startDate && date <= endDate
}

// Calculate total amount of orders
function calculateTotalAmount(orders) {
  return orders.reduce((total, order) => {
    return total + order.items.reduce((sum, item) => sum + item.marketPriceCP * item.quantity, 0)
  }, 0)
}

// Find months with orders
function findMonthsWithOrders(orders) {
  const months = new Set()
  orders.forEach((order) => {
    const orderDate = new Date(order.date)
    const monthYear = orderDate.getMonth() + '-' + orderDate.getFullYear()
    months.add(monthYear)
  })
  return months.size
}

// Get orders for the recent N months
function getRecentMonthsOrders(orders, numMonths) {
  const currentDate = new Date()
  const recentMonthsOrders = []

  for (let i = 0; i < numMonths; i++) {
    const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
    const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() - i + 1, 0)
    const ordersInMonth = orders.filter((order) => {
      return isWithinDateRange(order.date, monthStart, monthEnd)
    })
    recentMonthsOrders.push(ordersInMonth)
  }

  return recentMonthsOrders
}

// Calculate days since the last order
function calculateDaysSinceLastOrder(orders) {
  const currentDate = new Date()
  const lastOrderDate = orders.reduce((lastDate, order) => {
    const orderDate = new Date(order.date)
    return orderDate > lastDate ? orderDate : lastDate
  }, new Date(0))

  const diffTime = Math.abs(currentDate - lastOrderDate)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

// Function to collect statistics from all clients
export default function collectStatistics(clients) {
  const statistics = {
    'PoS Clients': 0,
    'Core PoS': 0,
    'Active PoS': 0,
    'Small PoS': 0,
    'Ocasional PoS': 0,
    'New Core PoS': 0,
    'New PoS': 0,
    'Decreased PoS': 0,
    'Alert PoS': 0,
    'Lost PoS': 0,
    'Unknown PoS Category': 0,
  }

  clients.forEach((client) => {
    console.log(client)
    const posTypes = classifyPoSForClient(client.orders)
    // console.log('client', client?.name + ' passed ' + posTypes)
    posTypes.forEach((type) => {
      if (statistics[type] !== undefined) {
        statistics[type]++
      }
    })
  })

  console.log(statistics, 'here')

  return [
    {
      image: 'Buisness',
      title: 'PoS Clients',
      value: statistics['PoS Clients'],
      color: 'green',
    },
    {
      image: 'Buisness',
      title: 'Core PoS',
      value: statistics['Core PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Active PoS',
      value: statistics['Active PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Small PoS',
      value: statistics['Small PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Ocasional PoS',
      value: statistics['Ocasional PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'New Core PoS',
      value: statistics['New Core PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Alert PoS',
      value: statistics['Alert PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Decreased PoS',
      value: statistics['Decreased PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Lost PoS',
      value: statistics['Lost PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'New PoS',
      value: statistics['New PoS'],
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Unknown PoS Category',
      color: 'green',
      value: statistics['Unknown PoS Category'],
    },
    {
      image: 'ThreeMan',
      title: 'Total Users',
      value: clients.length,
      color: 'green',
    },
  ]
}
