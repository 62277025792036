import React, { useState, useEffect } from 'react'
import { Box, Button } from '@mui/material'
import { ReactComponent as Logo } from '../../assets/svg/NewLogo.svg'
import './WelcomePage.scss'

export default function WelcomePage() {
  return (
    <div className='welcome-page'>
      <Box className='welcome-page__header'>
        <Box className='welcome-page__header__logo'>
          <Logo width={70} height={70} />
          <div className='welcome-page__header__logo__text'>
            <p>
              Tu Producto Online <span>Admin</span>
            </p>
          </div>
        </Box>
        <Box className='welcome-page__header__plan'>
          <p>Choose a plan</p>
          <Button>Get PRO</Button>
          <Button>ENG</Button>
        </Box>
      </Box>
    </div>
  )
}
