import React, { useEffect, useMemo, useState } from 'react'
import './CatalogChangeBox.scss'
import { FormControl, Select, TextField, MenuItem, CircularProgress } from '@mui/material'
import Selection from '../../../MultiSelectItem/SelectionComponent'
import { useSelector, useDispatch } from 'react-redux'
import { changeCurrentProduct } from '../../../../store/slices/currentProduct'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function CatalogChangeBox({ products, product, setStatus, status, reset }) {
  const [loading, setLoading] = useState(true)
  const save = useSelector((state) => state.currentProduct.save)
  const dispatch = useDispatch()

  const initialState = {
    category: '',
    brand: '',
    businessType: '',
    provider: '',
    description: '',
    unidad: '',
    weight: '',
    weightUnits: '',
    length: '',
    width: '',
    height: '',
    minQuantity: '',
    place: '',
    shelf: '',
    stand: '',
    quantityStep: '',
    decimal: false,
    active: false,
    stock: '',
    days: '',
    dias: '',
    prId: '',
    exDate: null,
  }

  const [state, setState] = useState(initialState)

  const uniqueCategories = useMemo(() => getUniqueValues(products, 'category'), [products])
  const uniqueBrands = useMemo(() => getUniqueValues(products, 'brand'), [products])
  const uniqueBusinessTypes = useMemo(() => getUniqueValues(products, 'businessType'), [products])
  const uniqueProviders = useMemo(() => getUniqueValues(products, 'proveedor'), [products])
  const uniqueUnidades = useMemo(() => getUniqueValues(products, 'unit'), [products])
  const uniqueWeightUnits = useMemo(() => getUniqueValues(products, 'weightUnits'), [products])

  useEffect(() => {
    if (product) {
      setState({
        category: splitAndMapValues(product.category),
        brand: splitAndMapValues(product.brand),
        businessType: splitAndMapValues(product.businessType),
        provider: splitAndMapValues(product.proveedor),
        unidad: splitAndMapValues(product.unit),
        weightUnits: splitAndMapValues(product.weightUnits),
        description: product.desc || '',
        weight: product.weightValue || '',
        length: product.length || '',
        width: product.width || '',
        height: product.height || '',
        minQuantity: product.minQt || '',
        place: product.place || '',
        shelf: product.shelf || '',
        stand: product.stand || '',
        active: product.enabled || false,
        decimal: product.decimal || false,
        days: product.days || '',
        dias: product.dias || '',
        stock: product.stock || '',
        prId: product.id || '',
        quantityStep: product.quantityStep || 0,
        exDate: dayjs(product.exDate) || null,
      })
      setStatus(product.status || {})
    }
  }, [product])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    if (reset) {
      setState(initialState)
    }
  }, [reset])

  useEffect(() => {
    if (save) {
      dispatch(changeCurrentProduct(formatCatalogChangeBox(state)))
    }
  }, [save])

  const handleChange = (field) => (event) => {
    const value = event.target.value
    setState((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleChangeExDate = (date) => {
    setState((prevState) => ({
      ...prevState,
      exDate: date,
    }))
  }

  const styles = { maxWidth: 200, minWidth: 200, width: '100%', backgroundColor: 'white', borderRadius: '5px' }

  return loading ? (
    <CircularProgress size={200} color='success' sx={{ position: 'absolute', top: '70%', left: '10%', transform: 'translate(-50%, -50%)' }} />
  ) : (
    <div className='catalogChangeBox'>
      <CatalogChangeBoxItem label='Category'>
        <Selection
          departmentNames={uniqueCategories}
          initialValue={state.category}
          onchange={(value) => setState((prevState) => ({ ...prevState, category: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Brands'>
        <Selection
          departmentNames={uniqueBrands}
          initialValue={state.brand}
          onchange={(value) => setState((prevState) => ({ ...prevState, brand: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Business Type'>
        <Selection
          departmentNames={uniqueBusinessTypes}
          initialValue={state.businessType}
          onchange={(value) => setState((prevState) => ({ ...prevState, businessType: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Proveedors'>
        <Selection
          departmentNames={uniqueProviders}
          initialValue={state.provider}
          onchange={(value) => setState((prevState) => ({ ...prevState, provider: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Description'>
        <TextField
          id='standard-basic'
          size='small'
          label='Description'
          variant='outlined'
          value={state.description}
          multiline
          onChange={handleChange('description')}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Unidad'>
        <Selection
          departmentNames={uniqueUnidades}
          initialValue={state.unidad}
          onchange={(value) => setState((prevState) => ({ ...prevState, unidad: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Weight'>
        <TextField
          id='standard-basic'
          size='small'
          label='Weight'
          variant='outlined'
          value={state.weight}
          onChange={handleChange('weight')}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Weight Units'>
        <Selection
          departmentNames={uniqueWeightUnits}
          initialValue={state.weightUnits}
          onchange={(value) => setState((prevState) => ({ ...prevState, weightUnits: value }))}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Dimensions'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {' '}
          <TextField
            id='standard-basic'
            size='small'
            label='Length (cm)'
            type='number'
            variant='outlined'
            value={state.length}
            onChange={handleChange('length')}
            //   sx={{ maxWidth: '25%', backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Width (cm)'
            type='number'
            variant='outlined'
            value={state.width}
            onChange={handleChange('width')}
            //   sx={{ maxWidth: '25%', backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Height (cm)'
            type='number'
            variant='outlined'
            value={state.height}
            onChange={handleChange('height')}
            //   sx={{ maxWidth: '25%', backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
        </div>
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Min. Quantity'>
        <TextField
          id='standard-basic'
          size='small'
          label='Min. Quantity'
          variant='outlined'
          value={state.minQuantity}
          onChange={handleChange('minQuantity')}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Stock Location'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            id='standard-basic'
            size='small'
            label='Place'
            variant='outlined'
            value={state.place}
            onChange={handleChange('place')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Shelf'
            variant='outlined'
            value={state.shelf}
            onChange={handleChange('shelf')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Stand'
            variant='outlined'
            value={state.stand}
            onChange={handleChange('stand')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
        </div>
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Quantity step'>
        <TextField
          id='standard-basic'
          size='small'
          label='Quantity step'
          variant='outlined'
          value={state.quantityStep}
          onChange={handleChange('quantityStep')}
          sx={styles}
        />
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Decimal'>
        <FormControl sx={{ m: 1, minWidth: 100, backgroundColor: 'white', borderRadius: '5px' }}>
          <Select
            style={{
              backgroundColor: 'white',
              borderRadius: '5px',
            }}
            value={state.decimal}
            onChange={handleChange('decimal')}
          >
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Active'>
        <FormControl sx={{ m: 1, minWidth: 100, backgroundColor: 'white', borderRadius: '5px' }}>
          <Select value={state.active} onChange={handleChange('active')}>
            <MenuItem value={false}>No</MenuItem>
            <MenuItem value={true}>Yes</MenuItem>
          </Select>
        </FormControl>
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Stock'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            id='standard-basic'
            size='small'
            label='Stock'
            variant='outlined'
            value={state.stock}
            onChange={handleChange('stock')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Days'
            variant='outlined'
            value={state.days}
            onChange={handleChange('days')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
          <TextField
            id='standard-basic'
            size='small'
            label='Dias'
            variant='outlined'
            value={state.dias}
            onChange={handleChange('dias')}
            // sx={{ maxWidth: 115, backgroundColor: 'white', borderRadius: '5px' }}
            sx={styles}
          />
        </div>
      </CatalogChangeBoxItem>
      <CatalogChangeBoxItem label='Expired Date'>
        <FormControl sx={{ m: 1, minWidth: 100, backgroundColor: 'white', borderRadius: '5px' }}>
          <DatePicker
            value={state.exDate}
            onChange={(e) => handleChangeExDate(e.$d)}
            componentsProps={{ actionBar: { actions: ['clear'] } }}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
      </CatalogChangeBoxItem>
    </div>
  )
}

const CatalogChangeBoxItem = ({ label, children }) => (
  <div className='catalogChangeBox__item'>
    <p>{label}</p>
    {children}
  </div>
)

const getUniqueValues = (products, key) => {
  let valuesArray = products.map((product) => product[key])
  valuesArray = valuesArray.flatMap((value) => {
    if (value === null || value === undefined || value === '') {
      return []
    }
    if (typeof value === 'string' && value.includes(',')) {
      return value.split(',').map((c) => c.trim())
    }
    return value
  })

  const uniqueSet = new Set(valuesArray)
  const uniqueValuesArray = Array.from(uniqueSet)
  uniqueValuesArray.sort()
  return uniqueValuesArray.map((value) => ({
    label: value,
    value: value,
  }))
}

const splitAndMapValues = (value) => {
  if (typeof value === 'string' && value.includes(',')) {
    return value.split(',').map((c) => ({ label: c.trim(), value: c.trim() }))
  }
  return value ? [{ label: value, value: value }] : []
}

const formatCatalogChangeBox = (state) => ({
  category: formatValue(state.category),
  brand: formatValue(state.brand),
  businessType: formatValue(state.businessType),
  proveedor: formatValue(state.provider),
  desc: state.description,
  unit: formatValue(state.unidad),
  weightValue: state.weight,
  weightUnits: formatValue(state.weightUnits),
  length: state.length,
  width: state.width,
  height: state.height,
  minQt: state.minQuantity,
  place: state.place,
  shelf: state.shelf,
  stand: state.stand,
  quantityStep: state.quantityStep,
  quantity: 0,
  status: state.status,
  decimal: state.decimal,
  enabled: state.active,
  stock: state.stock,
  days: state.days,
  dias: state.dias,
  id: state.prId,
  exDate: state.exDate,
})

const formatValue = (value) => (typeof value === 'object' ? value.map((c) => c.value).join(',') : value)
