import React, { useEffect, useMemo, useState } from 'react'
import './Progress.scss'
import { LinearProgress } from '@mui/material'

export default function Progress({ setFilterItemsValue, order, favoriteItems, progressItems, productionItems, shoppingItems }) {
  const { items } = order

  const [buffer, setBuffer] = useState()

  useEffect(() => {
    if (items) {
      setBuffer(items.length)
    } else {
      setBuffer(0)
    }
  }, [items])

  //favoriteItems == listo

  const listo = useMemo(() => {
    if (favoriteItems) {
      return favoriteItems.length
    } else {
      return items ? items.filter((item) => item.status === 'Listo').length : 0
    }
  }, [items, favoriteItems])

  const progress = useMemo(() => {
    if (progressItems) {
      return progressItems.length
    } else {
      items
        ? items.filter(
            (item) =>
              item.status._id === '658efe96c582a56609d72de8' ||
              item.status._id === '6584aad40ca272b8a1c94df7' ||
              item.status._id === '6584aa210ca272b8a1c94df5',
          ).length
        : 0
    }
  }, [items, progressItems])
  const production = useMemo(() => {
    if (productionItems) {
      return productionItems.length
    } else {
      return items ? items.filter((item) => item.status._id === '6584ab060ca272b8a1c94df8').length : 0
    }
  }, [items, productionItems])
  const wait = useMemo(() => {
    if (shoppingItems) {
      return shoppingItems.length
    } else {
      return items ? items.filter((item) => item.status._id === '6584ab230ca272b8a1c94df9').length : 0
    }
  }, [items, shoppingItems])

  const handleSortItems = (value) => {
    setFilterItemsValue(value)
  }

  return (
    <div className='progress'>
      <div className='progress__top'>
        <div className='progress__top__item' onClick={() => handleSortItems(['6584a9da0ca272b8a1c94df4'])}>
          <p>Listo:</p>
          <p style={{ color: '#176318' }}>
            {listo}/{items.length ? items.length : 0}
          </p>
        </div>
        <div
          className='progress__top__item'
          onClick={() => handleSortItems(['658efe96c582a56609d72de8', '6584aad40ca272b8a1c94df7', '6584aa210ca272b8a1c94df5'])}
        >
          <p>Progress:</p>
          <p style={{ color: '#4DACE6' }}>
            {progress}/{items.length ? items.length : 0}
          </p>
        </div>
        <div className='progress__top__item' onClick={() => handleSortItems(['6584ab060ca272b8a1c94df8'])}>
          <p>Production:</p>
          <p style={{ color: '#3D40D3' }}>
            {production}/{items.length ? items.length : 0}
          </p>
        </div>
        <div className='progress__top__item' onClick={() => handleSortItems(['6584ab230ca272b8a1c94df9'])}>
          <p>Shopping list:</p>
          <p style={{ color: '#F58809' }}>
            {wait}/{items.length ? items.length : 0}
          </p>
        </div>
      </div>
      <div className='progress__bottom'>
        <LinearProgress
          sx={{
            width: '100%',
            height: '15px',
            '& .MuiLinearProgress-bar': { backgroundColor: '#2E732F' },
            '& .MuiLinearProgress-determinate': { backgroundColor: '#176318' },
          }}
          variant='determinate'
          color='success'
          value={items.length ? (listo / items.length) * 100 : 0}
        />
      </div>
    </div>
  )
}
