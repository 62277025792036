import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  allContacts: []
}

export const allContactsSlice = createSlice({
  name: 'allContacts',
  initialState,
  reducers: {
    updateContact: (state, action) => {
      state.allContacts = state.allContacts.map(el => {
        if (el._id == action.payload.id) {
          return { ...action.payload, _id: action.payload.id }
        } else {
          return el
        }
      })
    },

    createContact: (state, action) => {
      state.allContacts = [...state.allContacts, action.payload]
    },

    setAllContacts: (state, action) => {
      state.allContacts = action.payload
    },

  }

})


export const { setAllContacts, updateContact, createContact } = allContactsSlice.actions
export default allContactsSlice.reducer