import { Autocomplete, Box, Button, MenuItem, Modal, Select, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { logAction } from '../../../../utils/logsAction/logService'

export default function Devolucion({ order }) {
  const [openDevol, setOpenDevol] = useState(false)

  const [search, setSearch] = useState('')

  const { data: products, isLoading } = mainApi.useGetProductsLimitQuery({
    limit: 20,
    search: search,
  })

  useEffect(() => {
    console.log(products)
  }, [products])

  const [currentProduct, setCurrentProduct] = useState(null)
  const [currentQuantity, setCurrentQuantity] = useState(1)

  const [creareOrder] = mainApi.useCreateNewOrderMutation()
  const [changeItems] = mainApi.useChangeItemsInOrderMutation()
  const { data: tpoUser } = mainApi.useGetContactsByIdQuery('663a0484a20a70b531a72bb3')

  const usr = JSON.parse(localStorage.getItem('acc'))

  const dispatch = useDispatch()

  useEffect(() => {
    if (tpoUser) console.log(tpoUser)
  }, [tpoUser])

  const createDevol = async () => {
    const { _id, ...orderWithoutId } = order
    const body = {
      data: {
        ...orderWithoutId, // Используете копию объекта order без свойства _id
        date: Date.now(),
        tradeName: localStorage.getItem('name') || 'TPO',
        userAcc: tpoUser,
        description: `${order?.tradeName || order?.userAcc?.tradeName || 'No TradeName'} Devolucion ${_id.slice(-6)}`,
        status: {},
        items: [
          {
            ...currentProduct,
            currientUnit: 'kg',
            _id: currentProduct?._id + 'devol', // Создаёте новый _id для элемента в items
            quantity: +currentQuantity,
            quantityOrder: +currentQuantity,
            marketPriceCP: currentProduct?.ourPriceCP || currentProduct?.marketPriceCP,
          },
        ],
      },
    }
    await creareOrder(body)
      .unwrap()
      .then(() => {
        alert('Order created in shipment')
        changeItems({
          elem: {
            ...order,
            items: [
              ...order.items,
              {
                ...currentProduct,
                currientUnit: 'kg',
                _id: currentProduct?._id + 'devol',
                quantity: +currentQuantity,
                quantityOrder: +currentQuantity,
                marketPriceCP: 0,
                sku: 'DVLCN' + currentProduct?.sku,
                status: {
                  _id: '65959de07503ef32164ba38c',
                  name: 'DEVOLUCION',
                  desc: '',
                  color: '#0b93b5',
                  openColorPicker: false,
                  type: 'itemsInOrder',
                },
              },
            ],
          },
        })
          .unwrap()
          .then(() => {
            dispatch(mainApi.util.invalidateTags(['Order']))
            alert('items added to order')
            setOpenDevol(false)
          })
          .catch((err) => console.log(err))
      })
      .catch((err) => alert(err))
    setOpenDevol(false)

    logAction('Devolucion', {
      orderId: order._id,
      items: [
        {
          ...currentProduct,
          currientUnit: 'kg',
          _id: currentProduct?._id + 'devol', // Создаёте новый _id для элемента в items
          quantity: +currentQuantity,
          quantityOrder: +currentQuantity,
          marketPriceCP: currentProduct?.ourPriceCP || currentProduct?.marketPriceCP,
        },
      ],
      user: {
        ...usr,
        actions: {},
      },
    })
  }

  return (
    <div>
      <Button
        sx={{
          // backgroundColor: '#2A2D2F',
          width: '300px',
          backgroundColor: 'white',
          color: '#176318',
          fontFamily: 'Montserrat',
          fontSize: '0.9em',
          fontWeight: 600,
          border: '1px solid #176318',
          borderRadius: '10px',
          marginTop: '10px',
          '&:hover': {
            backgroundColor: '#176318',
            color: 'white',
          },
        }}
        variant='contained'
        onClick={() => setOpenDevol(true)}
      >
        - Return Product
      </Button>
      <Modal open={openDevol} onClose={() => setOpenDevol(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            width: 300,
            padding: '20px',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2>Add Devolucion</h2>
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            // <Autocomplete
            //   disabled={isLoading}
            //   disablePortal
            //   id='combo-box-demo'
            //   options={products}
            //   sx={{ m: 1, width: 300 }}
            //   getOptionLabel={(option) => option.name} // Возвращает строку для метки
            //   onChange={(event, value) => setCurrentProduct(value)}
            //   renderOption={(
            //     props,
            //     option, // Кастомизация отображения элементов списка
            //   ) => (
            //     <Box key={option._id} component='li' {...props} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            //       <img src={option.img} alt={option.name} width={30} height={30} />
            //       <p>{option.name}</p>
            //     </Box>
            //   )}
            //   renderInput={(params) => <TextField {...params} label='Product' value={search} onChange={(e) => setSearch(e.target.value)} />}
            // />
            <Autocomplete
              disabled={isLoading}
              disablePortal
              id='combo-box-demo'
              options={products}
              sx={{ m: 1, width: 300 }}
              getOptionLabel={(option) => option.name}
              onChange={(event, value) => setCurrentProduct(value)}
              renderOption={(props, option) => (
                <Box key={option._id} component='li' {...props} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img src={option.img} alt={option.name} width={30} height={30} />
                  <p>{option.name}</p>
                </Box>
              )}
              renderInput={(params) => <TextField {...params} label='Product' value={search} onChange={(e) => setSearch(e.target.value)} />}
            />
          )}
          <TextField
            value={currentQuantity}
            onChange={(e) => setCurrentQuantity(e.target.value)}
            disabled={!currentProduct}
            sx={{ m: 1, width: 300 }}
            label='Quantity'
          />
          <Button
            sx={{
              width: '100%',
            }}
            variant='contained'
            onClick={createDevol}
            disabled={!currentProduct || !currentQuantity}
          >
            Add Devolucion product
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
