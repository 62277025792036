import React, { useEffect, useState } from 'react'
import { TextField, Button, Box, useMediaQuery } from '@mui/material'
import { mainApi } from '../../../store/services/mainApi'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'

import AuthTextField from '../../Rcomp/AuthTextField/AuthTextField'
import { MuiTelInput } from 'mui-tel-input'
const Login = () => {
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [siteId, setSiteId] = useState('')
  const dispatch = useDispatch()

  const [login, { isSuccess }] = mainApi.useLoginAdminMutation()

  const { data: siteParams, isSuccess: isSiteParamsSuccess } = mainApi.useGetSiteParamsByIdQuery(
    { _id: siteId },
    {
      skip: !siteId,
    },
  )
  const navigate = useNavigate()

  useEffect(() => {
    if (siteParams) {
      localStorage.setItem('site', JSON.stringify(siteParams))
      localStorage.setItem('name', siteParams.name)
    }
  }, [siteParams])

  const [formData, setFormData] = useState({
    phone: '',
    password: '',
  })

  const [phone, setPhone] = useState('')

  const handleChangePhone = (value) => {
    setPhone(value)
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })
  }

  useEffect(() => {
    if (localStorage.getItem('acc') && localStorage.getItem('site')) {
      navigate('/admin/orders')
    }
  }, [navigate])

  useEffect(() => {
    if (isSuccess) {
      // После успешного логина устанавливаем siteId
      const accountData = JSON.parse(localStorage.getItem('acc'))
      if (accountData?.site) {
        setSiteId(accountData.site)
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSiteParamsSuccess && siteParams) {
      // После успешного получения siteParams перенаправляем пользователя
      navigate('/admin/orders')
    }
  }, [isSiteParamsSuccess, siteParams, navigate])

  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      const res = await login({
        data: {
          phone: phone,
          password: formData.password,
        },
      }).unwrap()

      alert('Login success')
      localStorage.setItem('acc', JSON.stringify(res))
      setSiteId(res.site) // Предполагаем, что res.site содержит siteId
      dispatch(mainApi.util.invalidateTags(['ShipmentOrders']))
      dispatch(mainApi.util.invalidateTags(['Products']))
      dispatch(mainApi.util.invalidateTags(['History']))
      dispatch(mainApi.util.invalidateTags(['Contacts']))
      // Перенаправление будет выполнено в useEffect, когда siteId изменится
    } catch (e) {
      alert('Something went wrong. Try again later')
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyContent: 'center',
        minHeight: 'max-content',
        backgroundColor: '#f5f5f5',
        padding: mobileView ? '20px' : '50px 80px',
        overflow: 'auto',

        borderRadius: '20px',
      }}
    >
      <h2
        style={{
          textAlign: 'center',
          color: '#5FC56E',
          fontSize: '40px',
          fontWeight: '700',
          fontFamily: 'Montserrat',
          margin: '0',
          lineHeight: '0.8',
        }}
      >
        WELCOME <br />
        <span style={{ fontSize: '25px', fontWeight: '700', margin: '0' }}>
          to TPO<span style={{ color: '#186419' }}>Admin</span>
        </span>
      </h2>

      <h3 style={{ textAlign: 'center', color: '#186419', fontFamily: 'Montserrat' }}>Sign in</h3>
      <Box sx={{ width: '85%' }}>
        <p style={{ margin: '15px 0', fontFamily: 'Montserrat', fontWeight: '700', fontSize: '12px' }}>Phone</p>
        <MuiTelInput
          required
          MenuProps={{ PaperProps: { style: { maxHeight: '200px', width: '300px' } } }}
          InputProps={{
            disableUnderline: true,
            sx: {
              border: 'none',
            },
          }}
          variant='standard'
          sx={{
            width: '100%',
            // margin: '15px',

            '& .MuiTelInput-TextField': {
              border: 'none',
            },
            '& .MuiTelInput-IconButton': {
              border: '1px solid #186419',
              width: 50,
              height: 40,
              borderRadius: '20px 0px 0px 20px',

              '&:hover': {
                backgroundColor: '#186419',
                color: 'white',
              },
            },
            '& .MuiInputBase-input': {
              paddingTop: 1,
              paddingBottom: 1,
              paddingLeft: 1,
              // paddingRight: 10,
              borderTop: '1px solid #186419',
              borderBottom: '1px solid #186419',
              borderRight: '1px solid #186419',
              borderLeft: '1px solid #186419',

              borderTopRightRadius: '20px',
              borderBottomRightRadius: '20px',
              // borderLeft: '1px solid #186419',
            },
            '& .MuiInputBase-root': {
              border: 'none',
            },
          }}
          name='phone'
          value={phone}
          onChange={handleChangePhone}
        />
      </Box>
      {/* <AuthTextField label='Phone' name='phone' value={formData.phone} onChange={handleChange} /> */}
      <AuthTextField label='Password' name='password' value={formData.password} onChange={handleChange} />
      {/* <TextField
        type='email'
        required
        label='Email'
        variant='outlined'
        name='email'
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin='normal'
      /> */}
      {/* <TextField
        label='Password'
        type='password'
        variant='outlined'
        name='password'
        value={formData.password}
        onChange={handleChange}
        fullWidth
        margin='normal'
        required
      /> */}
      <Button
        sx={{ width: 'max-content', backgroundColor: '#5FC56E', borderRadius: '30px' }}
        type='submit'
        variant='contained'
        color='primary'
        fullWidth
      >
        Login
      </Button>
    </form>
  )
}

export default Login
