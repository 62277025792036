import React, { useState, useEffect, useMemo } from 'react'
import Stats from '../../Stats/Stats'
import ContactsTable from '../../Contacts/ContactsTable/ContactsTable'
import { CircularProgress, useMediaQuery } from '@mui/material'

import { mainApi } from '../../../store/services/mainApi'

import { renderComponentContacts } from '../../../utils/contactsFunctions'
import { renderModal } from '../../../utils/contsctsModal'
import collectStatistics from './counter'

export const ContactStats = () => {
  useEffect(() => {
    document.title = 'Contacts'
  }, [])

  const [limitPerPage, setLimitPerPage] = useState(20)
  const [search, setSearch] = useState('')

  const [adminType, setAdminType] = useState('All')

  const mobileView = useMediaQuery('(max-width: 768px)')
  const { data: contactsData, isLoading } = mainApi.useGetClientsAndOrdersQuery({ limit: limitPerPage, search: search, adminType: adminType })
  const [contacts, setContacts] = useState([])
  const modalKeys = ['Copy', 'Export', 'Status', 'New contact', 'Add Route', 'Delete']

  const { data: contactsAll, isLoading: isLoadingAll } = mainApi.useGetClientsAndOrdersAllQuery()

  useEffect(() => {
    if (contactsData) {
      setContacts(contactsData)
    }
  }, [contactsData])

  useEffect(() => {
    console.log(contacts)
  }, [contacts])

  const allClientsTotal = (type) => {
    if (contacts) {
      return contacts.reduce((count, contact) => {
        if (contact.type === type) {
          return count + 1
        } else {
          return count
        }
      }, 0)
    }
  }

  const [mock, setMock] = useState([
    {
      image: 'Buisness',
      title: 'Business Clients',
      value: 1,
      color: 'green',
    },
    {
      image: 'Buisness',
      title: 'Core Business',
      value: 1,
      color: 'green',
    },
    {
      image: 'Man',
      title: 'Active Business ',
      value: 1,
      color: 'green',
    },
    {
      image: 'ThreeMan',
      title: 'Total Users',
      value: 1,
      color: 'green',
    },
    {
      image: 'VerifyMan',
      title: 'Total ambassadors',
      value: 1,
      color: 'green',
    },
  ])

  useEffect(() => {
    if (contactsAll) {
      console.log(contactsAll)
      setMock(collectStatistics(contactsAll))
      console.log(mock)
    }
  }, [contactsAll])

  const [keys] = useState([
    'Number',
    // 'Platform',
    'Codigo',
    'Name',
    'BType',
    // 'Type',
    // 'Status',
    'Phone',
    // 'Address',
    // 'Email',
    // 'Tags',
    // 'NIF',
    // 'IBAN',
    // 'Last Visit',
    // 'Last Update',
    'Total',
    'Orders',
  ])

  const [options, setOptions] = useState({
    number: true,
    // platform: true,
    codigo: true,
    name: true,
    btype: true,
    // type: true,
    // status: true,
    phone: true,
    // address: true,
    // email: true,
    // tags: true,
    // nif: true,
    // iban: true,
    // lastvisit: true,
    // lastupdate: true,
    total: true,
    orders: true,
  })

  const handleChoose = (name) => {
    setOptions({
      ...options,
      [name]: !options[name],
    })
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div>
      <Stats mock={mock} />
      <ContactsTable
        headerType={'contacts'}
        renderModal={renderModal}
        modalKeys={modalKeys}
        data={contacts}
        renderComponent={renderComponentContacts}
        keys={keys}
        options={options}
        handleChoose={handleChoose}
        limitPerPage={limitPerPage}
        setLimitPerPage={setLimitPerPage}
        search={search}
        setSearch={setSearch}
        isLoading={isLoading}
        setAdminType={setAdminType}
        adminType={adminType}
      />
    </div>
  )
}
