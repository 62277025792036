import React, { useState, useEffect } from 'react'
import './PersonalSales.scss'
import { Button, Modal, Box, useMediaQuery, TextField, CircularProgress, Checkbox } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'

export default function PersonalSales({ contact }) {
  const mobileView = useMediaQuery('(max-width: 798px)')
  const [open, setOpen] = useState(false)
  const dispatch = useDispatch()

  const [addProductModal, setAddProductModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [selectedAddedProducts, setSelectedAddedProducts] = useState([])

  const { data: products, isLoading: isLoadingProducts } = mainApi.useGetProductsLimitQuery(
    {
      limit: 10,
      search: searchValue,
    },
    {
      skip: !addProductModal,
    },
  )

  const [addPersonalSales] = mainApi.useAddPersonalSaleMutation()

  const { tradeName } = contact

  const { data: personalSales, isLoading: isLoadingPersonal } = mainApi.useGetPersonalSalesQuery(tradeName, { skip: !open })

  useEffect(() => {
    if (personalSales) {
      setSelectedAddedProducts(personalSales)
    }
  }, [personalSales])

  const handleAddProducts = () => {
    addPersonalSales({
      tradeName: tradeName,
      personalSales: selectedAddedProducts,
    })
      .unwrap()
      .then(() => {
        setAddProductModal(false)
        alert('Products added')
        dispatch(mainApi.util.invalidateTags(['PersonalSales']))
      })
      .catch((err) => alert(err))
  }

  const handleDeleteAddedProduct = (sku) => {
    setSelectedAddedProducts((prev) => prev.filter((product) => product.sku !== sku))
  }

  const handleAddProduct = (product) => {
    setSelectedAddedProducts((prev) => [...prev, product])
  }

  const handlePriceChange = (event, sku) => {
    const newPrice = event.target.value
    setSelectedAddedProducts((prev) => prev.map((product) => (product.sku === sku ? { ...product, spoPrice: parseFloat(newPrice) } : product)))
  }

  const handleChangeSelected = (event, sku) => {
    setSelectedAddedProducts((prev) => prev.map((product) => (product.sku === sku ? { ...product, selected: event.target.checked } : product)))
  }

  return (
    <div className='personalSales'>
      <h2>Personal Rates</h2>
      <Button onClick={() => setOpen(true)} variant='contained'>
        Open
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: mobileView ? '300px' : '600px',
            height: '300px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#f0f0f0',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
          }}
        >
          <h2>Personal Rates</h2>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', overflow: 'auto', height: '200px' }}>
            {isLoadingPersonal ? (
              <CircularProgress />
            ) : (
              selectedAddedProducts.map((product) => (
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }} key={product._id}>
                  <img style={{ flex: 1 }} width={50} height={50} src={product.img} alt={product.name} />
                  <p style={{ flex: 2 }}>{product.name}</p>
                  {mobileView ? null : <p style={{ flex: 1 }}>{product.sku}</p>}
                  <div style={{ flex: 1, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                    <p style={{ margin: 0 }}>Selected</p>
                    <Checkbox checked={product.selected} onChange={(e) => handleChangeSelected(e, product.sku)} />
                  </div>
                  <p style={{ flex: 1 }}>{product.marketPriceCP}€</p>
                  <TextField
                    type='number'
                    sx={{ flex: 1, minWidth: '50px' }}
                    value={product.spoPrice || ''}
                    onChange={(e) => handlePriceChange(e, product.sku)}
                    inputProps={{ step: '0.01' }}
                  />
                </Box>
              ))
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Button onClick={() => setAddProductModal(true)} variant='contained' color='success'>
              Add new
            </Button>
            {/* <Button variant='contained' color='info' onClick={handleSavePrices}>
              Save
            </Button> */}
            <Button variant='contained' color='info' size='small' onClick={() => handleAddProducts()}>
              Update
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal open={addProductModal} onClose={() => setAddProductModal(false)}>
        {isLoadingProducts ? (
          <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} />
        ) : (
          <Box
            sx={{
              width: mobileView ? '300px' : '600px',
              height: '300px',
              overflow: 'auto',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#f0f0f0',
              padding: '10px',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                position: 'sticky',
                top: '0',
                backgroundColor: 'white',
                zIndex: '1000',
                padding: '10px',
              }}
            >
              <p>Search</p>
              <TextField variant='outlined' size='small' fullWidth onChange={(e) => setSearchValue(e.target.value)} />
            </Box>
            {products ? (
              products.map((product) => (
                <div
                  key={product._id}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '10px',
                  }}
                >
                  <img style={{ flex: '1' }} src={product.img} alt={product.name} width='50px' height='50px' />
                  <p style={{ flex: '3' }}>{product.name}</p>
                  {mobileView ? null : <p style={{ flex: 1 }}>{product.sku}</p>}
                  {selectedAddedProducts.some((p) => p.sku === product.sku) ? (
                    <Button sx={{ flex: '1' }} onClick={() => handleDeleteAddedProduct(product.sku)}>
                      Remove
                    </Button>
                  ) : (
                    <Button sx={{ flex: '1' }} onClick={() => handleAddProduct(product)}>
                      Add
                    </Button>
                  )}
                </div>
              ))
            ) : (
              <p>No products</p>
            )}
            <Box
              sx={{
                display: 'flex',
                gap: '10px',
                position: 'sticky',
                bottom: '0',
                backgroundColor: 'white',
                zIndex: '1000',
                padding: '10px',
              }}
            >
              <Button fullWidth variant='contained' onClick={() => handleAddProducts()}>
                Save
              </Button>
            </Box>
          </Box>
        )}
      </Modal>
    </div>
  )
}
