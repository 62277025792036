import React, { useEffect, useMemo, useState } from 'react'
import './TotalInfo.scss'
import { mainApi } from '../../../../store/services/mainApi'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'

import { ReactComponent as ArrowDown } from '../../../../assets/svg/ArrowDown.svg'
import ChangeStatus from '../../../ChangeStatus/ChangeStatus'
import Sample from '../Sample/Sample'

export default function TotalInfo({ statuses, order, status }) {
  const [deleteOrder, { isSuccess }] = mainApi.useDeleteOrderMutation()
  const [changeStatus] = mainApi.useUpdateStatusinOrderMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  //status Modal
  const [statusModal, setStatusModal] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      navigate(-1)
      dispatch(mainApi.util.invalidateTags(['Order']))
    }
  }, [isSuccess])

  const total = useMemo(() => {
    if (order.items) {
      if (order?.deliveryPrice) {
        return (
          order.deliveryPrice +
          order.items
            .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
            .reduce((acc, curr) => acc + curr, 0)
        )
      } else {
        return order.items
          .map((item) => (item.marketPriceCP !== '' ? parseFloat(item.marketPriceCP * (item.quantity || 1)) : 0))
          .reduce((acc, curr) => acc + curr, 0)
      }
    }
    return 0 // return a default value if items is falsy
  }, [order])

  const sale = useMemo(() => {
    if (order.items) {
      return order.items
        .map((item) => (item.ourPrice !== '' ? parseFloat(item.ourPrice * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0)
        .toFixed(2)
    }
    return 0 // return a default value if items is falsy
  }, [order])

  const IVA = useMemo(() => {
    let totalIva = 0
    if (order.items) {
      totalIva = order.items
        .map((item) => (item.IVA ? (item.IVA / 100) * (item.marketPriceCP * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0) // Provide 0 as the initial value
    }
    console.log(totalIva, 'IVA')
    // if (order.items && order.items.length > 0) {
    //   totalIva = totalIva / order.items.length
    // }
    return totalIva ? totalIva : 0 // return a default value if items is falsy
  }, [order])

  const profit = useMemo(() => {
    if (order.items) {
      return order.items
        .map((item) =>
          order.items.marketPriceCP != ''
            ? parseFloat(item.marketPriceCP * (item.quantity || 0)) - parseFloat(item.ourPrice * (item.quantity || 0))
            : 0,
        )
        .reduce((acc, curr) => acc + curr, 0)
    }
    return 0
  })

  return (
    <div className='totalInfo'>
      <div className='totalInfo__statuses' style={{ display: 'flex', justifyContent: 'space-between', width: '60%' }}>
        <div
          onClick={() => setStatusModal(true)}
          style={{
            backgroundColor: status.color ? status.color : '#fff',
          }}
          className='totalInfo__status'
        >
          <ChangeStatus
            type='order'
            statusModal={statusModal}
            setStatusModal={setStatusModal}
            data={order}
            statusName1={status.name}
            statuses={statuses}
          />
          <ArrowDown style={{ cursor: 'pointer', marginLeft: '10px' }} width={20} height={20} fill='#CBDCCB' stroke='#CBDCCB' />
        </div>
        <Sample order={order} contact={order.userAcc} type='update' />
        <div className='totalInfo__status'>History</div>
      </div>
      <div className='totalInfo__container'>
        <div className='totalInfo__container__item'>
          <h5>GOODS W/O TVA</h5>
          <span>{(total - IVA - (order.deliveryPrice ? +order.deliveryPrice : 0)).toFixed(2)}&euro;</span>
        </div>
        {localStorage.getItem('name') != 'VPF' && (
          <div className='totalInfo__container__item'>
            <h5>PROFIT</h5>
            <span>
              {profit.toFixed(2)}&euro;({((profit / total) * 100).toFixed(2)}%)
            </span>
          </div>
        )}
        <div className='totalInfo__container__item'>
          <h5>TVA</h5>
          <span>{IVA.toFixed(2)}&euro;</span>
        </div>
        <div className='totalInfo__container__item'>
          <h5>DELIVERY</h5>
          <span>{order?.deliveryPrice ? order?.deliveryPrice : '0.00'}&euro;</span>
        </div>
        <div className='totalInfo__container__item'>
          <h5
            style={{
              color: '#176318',
            }}
            className='total'
          >
            TOTAL
          </h5>
          <span
            style={{
              color: '#000',
            }}
            className='total'
          >
            {total.toFixed(2)}&euro;
          </span>
        </div>
      </div>
      <div className='totalInfo__btns'>
        {/* <button>Add Route</button> */}

        {/* <button onClick={() => alert('Not implemented yet. Try to use Edit on right bottom side')}>Save</button>
        <button>Copy</button> */}
        {/* <button
          onClick={() => {
            deleteOrder(order._id)
          }}
        >
          Delete
        </button> */}
      </div>
    </div>
  )
}
