import { createSlice } from '@reduxjs/toolkit'
import logoTPO from '../../assets/svg/NewLogo.svg'
import logoVPF from '../../assets/svg/LogoVPF.svg'
const initialState = {
  settings: {
    type: 'admin', //user or admin
    adminName: localStorage.getItem('name'),
    headerImage: localStorage.getItem('name') === 'TPO' ? logoTPO : localStorage.getItem('name') === 'VPF' ? logoVPF : null,
    actions: {
      canDelete: true,
      canAdd: true,
      canEdit: true,
      canView: true,
      canChange: true,
      canSearch: true,
      canFilter: true,
      canPrint: true,
      canExport: true,
      canCopy: true,
      canStatus: true,
    },
    elements: {
      orders: true,
      products: true,
      contacts: true,
      operations: true,
      messages: true,
      projects: true,
    },
  },
}

export const userSettingsSlice = createSlice({
  name: 'userSettingsSlice',
  initialState,
  reducers: {
    updateNameAndImage: (state, action) => {
      state.settings.adminName = localStorage.getItem('name')
      state.settings.headerImage = localStorage.getItem('name') === 'TPO' ? logoTPO : localStorage.getItem('name') === 'VPF' ? logoVPF : null
    },
  },
})

export const { updateNameAndImage } = userSettingsSlice.actions
export default userSettingsSlice.reducer
