import React from 'react'
import './MainInfo.scss'
import { Avatar, Box, Typography, useMediaQuery } from '@mui/material'

import { ReactComponent as Instagram } from '../../../../assets/svg/Instagram.svg'
import { ReactComponent as Watsup } from '../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Telegram } from '../../../../assets/svg/Telegram.svg'
import StyledBox from '../../../Rcomp/StyledBox/StyledBox'
import { useTranslation } from 'react-i18next'

export const MainInfo = ({ contact }) => {
  const mobileView = useMediaQuery('(max-width: 798px)')

  const { t } = useTranslation()

  const checkIntagramLinkOrNot = (link) => {
    if (link.includes('https://www.instagram.com')) {
      return true
    } else {
      return false
    }
  }

  const getNameInstagram = (link) => {
    if (link.includes('https://www.instagram.com')) {
      return link.split('/')[3].split('?')[0]
    } else {
      return link
    }
  }
  return (
    <StyledBox defaultVisible={mobileView ? true : true} className='mainInfo'>
      {/* <Box className='mainInfo__title'></Box> */}
      <Box className='mainInfo__content'>
        <Box className='mainInfo__content__left'>
          <Box className='mainInfo__content__left__avatar'>
            <Avatar src={contact?.img} sx={{ width: 125, height: 125, borderRadius: '50%', border: '6px solid #407BFF' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
              <Box className='mainInfo__content__left__avatar__text'>Business</Box>
            </Box>
          </Box>

          <Box className='mainInfo__content__left__undertext'>
            <Instagram width={20} height={20} />
            <a
              href={
                contact?.instagram
                  ? checkIntagramLinkOrNot(contact?.instagram)
                    ? contact?.instagram
                    : `https://www.instagram.com/${getNameInstagram(contact?.instagram)}`
                  : 'https://www.instagram.com'
              }
              target='_blank'
              rel='noreferrer'
              style={{ textDecoration: 'none' }}
            >
              {contact?.instagram ? getNameInstagram(contact?.instagram) : 'N/A'}
            </a>
          </Box>
        </Box>
        {mobileView ? null : (
          <Box className='mainInfo__content__right'>
            <Box className='mainInfo__content__right__text'>
              <Box className='mainInfo__content__right__text__item'>
                <Typography className='mainInfo__content__right__text__item__title'>ID:</Typography>
                <Typography className='mainInfo__content__right__text__item__text'>{contact?._id ? contact?._id : 'N/A'}</Typography>
              </Box>
              <Box className='mainInfo__content__right__text__item'>
                <Typography className='mainInfo__content__right__text__item__title'>{t('StaticPage.mainInfo.code')}:</Typography>
                <Typography className='mainInfo__content__right__text__item__text'>{contact?.tradeName ? contact?.tradeName : 'N/A'}</Typography>
              </Box>
              <Box className='mainInfo__content__right__text__item'>
                <Typography className='mainInfo__content__right__text__item__title'>{t('StaticPage.mainInfo.name')}:</Typography>
                <Typography className='mainInfo__content__right__text__item__text'>{contact?.name ? contact?.name : 'N/A'}</Typography>
              </Box>
              <Box className='mainInfo__content__right__text__item'>
                <Typography className='mainInfo__content__right__text__item__title'>{t('StaticPage.mainInfo.employee')}:</Typography>
                <Typography className='mainInfo__content__right__text__item__text'>{contact?.employee ? contact?.employee : 'N/A'}</Typography>
              </Box>
              <Box className='mainInfo__content__right__text__item'>
                <p className='mainInfo__content__right__text__item__title'>{t('StaticPage.mainInfo.contactUs')}</p>
                <Watsup
                  width={50}
                  height={50}
                  className='mainInfo__content__right__text__item__image'
                  onClick={() => window.open('https://wa.me/34642868541', '_blank')}
                />
                <Telegram
                  width={50}
                  height={50}
                  className='mainInfo__content__right__text__item__image'
                  onClick={() => window.open('https://t.me/TPOBCN', '_blank')}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </StyledBox>
  )
}
