import { Box, FormControl, TextField } from '@mui/material'
import React, { useState, useEffect } from 'react'
import './AdditionalInfo.scss'
import { useSelector, useDispatch } from 'react-redux'
import { changeContact } from '../../../../store/slices/changeContact'

export default function AdditionalInfo({ contact, change }) {
  const save = useSelector((state) => state.changeContact.save)
  const reduxContact = useSelector((state) => state.changeContact.currentContact)
  const dispatch = useDispatch()

  const { phone, iban, swift, nif, cif, billAddress, instagram, email, img, comArdress } = contact
  const [phoneValue, setPhoneValue] = useState(phone || '')
  const [ibanValue, setIbanValue] = useState(typeof iban === 'string' ? iban : iban?.[0] || '')
  const [swiftValue, setSwiftValue] = useState(typeof swift === 'string' ? swift : swift?.[0] || '')
  const [nifValue, setNifValue] = useState(typeof nif === 'string' ? nif : nif?.[0] || '')
  const [cifValue, setCifValue] = useState(typeof cif === 'string' ? cif : cif?.[0] || '')
  const [addressValue, setAddressValue] = useState(billAddress?.address || '')
  const [cityValue, setCityValue] = useState(billAddress?.city || '')
  const [instagramValue, setInstagramValue] = useState(instagram || '')
  const [emailValue, setEmailValue] = useState(email || '')
  const [imageValue, setImageValue] = useState(img || '')
  const [comArdressValue, setComArdressValue] = useState(comArdress || '')

  useEffect(() => {
    if (contact) {
      setPhoneValue(contact.phone || '')
      setIbanValue(typeof iban === 'string' ? iban : contact.iban?.[0] || '')
      setSwiftValue(typeof swift === 'string' ? swift : contact.swift?.[0] || '')
      setNifValue(typeof nif === 'string' ? nif : nif?.[0] || '')
      setCifValue(typeof cif === 'string' ? cif : contact.cif?.[0] || '')
      setAddressValue(contact.billAddress?.address || '')
      setCityValue(contact.billAddress?.city || '')
      setEmailValue(contact.email || '')
      setInstagramValue(contact.instagram || '')
      setImageValue(contact.img || '')
      setComArdressValue(contact.comArdress || '')
    }
  }, [contact])

  useEffect(() => {
    if (save) {
      dispatch(
        changeContact({
          phone: phoneValue,
          iban: ibanValue,
          swift: swiftValue,
          nif: nifValue,
          cif: cifValue,
          billAddress: {
            address: addressValue,
            city: cityValue,
          },
          instagram: instagramValue,
          email: emailValue,
          img: imageValue,
          comArdress: comArdressValue,
        }),
      )
    }
  }, [save])

  return (
    <Box className='additionalInfo__cc'>
      <TextField
        disabled={!change}
        id='outlined-basic'
        label='phone'
        variant='outlined'
        size='small'
        value={phoneValue}
        onChange={(e) => setPhoneValue(e.target.value)}
      />
      <TextField
        disabled={!change}
        id='outlined-basic'
        label='IBAN'
        variant='outlined'
        size='small'
        value={ibanValue}
        onChange={(e) => setIbanValue(e.target.value)}
      />
      <TextField
        disabled={!change}
        id='outlined-basic'
        label='SWIFT'
        variant='outlined'
        size='small'
        value={swiftValue}
        onChange={(e) => setSwiftValue(e.target.value)}
      />
      <FormControl sx={{ minWidth: 120, display: 'flex', flexDirection: 'row' }}>
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='NIF/CIF'
          variant='outlined'
          size='small'
          sx={{ width: '50%' }}
          value={nifValue}
          onChange={(e) => setNifValue(e.target.value)}
        />
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='Comercial address'
          variant='outlined'
          sx={{ width: '50%' }}
          size='small'
          value={comArdressValue}
          onChange={(e) => setComArdressValue(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ minWidth: 120, display: 'flex', flexDirection: 'row' }}>
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='Legal address'
          variant='outlined'
          size='small'
          sx={{ width: '70%' }}
          value={addressValue}
          onChange={(e) => setAddressValue(e.target.value)}
        />
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='City'
          variant='outlined'
          size='small'
          sx={{ width: '30%' }}
          value={cityValue}
          onChange={(e) => setCityValue(e.target.value)}
        />
      </FormControl>
      <FormControl sx={{ minWidth: 120, display: 'flex', flexDirection: 'row' }}>
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='Instagram'
          variant='outlined'
          size='small'
          sx={{ width: '50%' }}
          value={instagramValue}
          onChange={(e) => setInstagramValue(e.target.value)}
        />
        <TextField
          disabled={!change}
          id='outlined-basic'
          label='Email'
          variant='outlined'
          size='small'
          sx={{ width: '50%' }}
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
        />
      </FormControl>
      <TextField
        disabled={!change}
        id='outlined-basic'
        label='Image source'
        variant='outlined'
        size='small'
        // sx={{ width: '70%' }}
        value={imageValue}
        onChange={(e) => setImageValue(e.target.value)}
      />
    </Box>
  )
}
