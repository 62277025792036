export const isColorLight = (color) => {
  // Remove any leading '#' from the color string
  const hex = color.replace('#', '')

  // Convert the hex color to RGB values
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Calculate the relative luminance of the color
  const relativeLuminance = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 255

  // Calculate the contrast ratio between the color and white (1) or black (0)
  const contrastRatioWithWhite = (relativeLuminance + 0.05) / 1.05
  const contrastRatioWithBlack = (relativeLuminance + 0.05) / 0.05

  // Return true if the contrast ratio with white is greater than 3, indicating a light color
  // Return true if the contrast ratio with black is less than 3.5, indicating a light color
  return contrastRatioWithBlack < 3.5 || contrastRatioWithWhite > 3
}
