export const logAction = async (action, details) => {
  try {
    await fetch('https://tpomobi.shop/addLog', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ action, details, timestamp: new Date().toISOString() }),
    })
  } catch (error) {
    console.error('Error logging action:', error)
  }
}
