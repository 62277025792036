import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentContact: {},
  save: false
}

export const changeContactSlice = createSlice({
  name: 'CurrentContact',
  initialState,
  reducers: {
    changeCurrentContact: (state, action) => {
      state.currentContact = action.payload
    },
    changeSave: (state, action) => {
      state.save = action.payload
    },
    changeContact: (state, action) => {
      state.currentContact = {
        ...state.currentContact,
        ...action.payload
      }
    }
  }
})

export const { changeCurrentContact, changeSave, changeContact } = changeContactSlice.actions
export default changeContactSlice.reducer