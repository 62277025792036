import React, { useState, useEffect } from 'react'
import './Header.scss'
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg'
import { ReactComponent as User } from '../../assets/svg/Profile.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/NewLogo.svg'

import { ReactComponent as HeaderCart } from '../../assets/svg/Header/HeaderCart.svg'
import { ReactComponent as HeaderCatalog } from '../../assets/svg/Header/HeaderCatalog.svg'
import { ReactComponent as HeaderMessages } from '../../assets/svg/Header/HeaderMessages.svg'
import { ReactComponent as HeaderOperacion } from '../../assets/svg/Header/HeaderOperacion.svg'
import { ReactComponent as HeaderPeople } from '../../assets/svg/Header/HeaderPeople.svg'
import { ReactComponent as HeaderProjects } from '../../assets/svg/Header/HeaderProjects.svg'
import { Box, Button } from '@mui/material'

export default function Header() {
  const [active, setActive] = useState('')
  const navigate = useNavigate()

  const [logoutMenu, setLogoutMenu] = useState(false)

  const [burger, setBurger] = useState(false)

  const site = JSON.parse(localStorage.getItem('site'))
  const acc = JSON.parse(localStorage.getItem('acc'))

  useEffect(() => {
    setActive(window.location.pathname.split('/').pop())
  }, [])

  // To block scrolling
  function blockScroll() {
    document.body.style.overflow = 'hidden'
  }

  // To unblock scrolling
  function unblockScroll() {
    document.body.style.overflow = ''
  }

  useEffect(() => {
    if (burger) {
      blockScroll()
    } else {
      unblockScroll()
    }
  }, [burger])

  //open menu

  const [openOperations, setOpenOperations] = useState(false)

  const handleOpenOperations = () => {
    setOpenOperations(true)
  }

  const handleCloseOperations = () => {
    setOpenOperations(false)
  }

  const handleLogout = () => {
    setLogoutMenu((prev) => !prev)
  }

  return (
    <header>
      <div className='header'>
        <div className='header__logo'>
          <img src={site?.logo || ''} height={45} width={45} />
          <h1>
            {site?.name || ''} <span>Admin</span>
          </h1>
        </div>
        <div className='header__menu'>
          <input type='checkbox' name='' id=''></input>
          <div className='header__menu__lines' onClick={() => setBurger(!burger)}>
            <span className='line line1'></span>
            <span className='line line2'></span>
            <span className='line line3'></span>
          </div>
          {burger && (
            <div className='header__menu__burger' onClick={() => setBurger(!burger)}>
              <div
                className={active === 'orders' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={() => {
                  setActive('orders')
                  navigate('/admin/orders')
                }}
              >
                <HeaderCart height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Orders
              </div>
              <div
                className={active === 'contacts' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={() => {
                  setActive('contacts')
                  navigate('/admin/contacts')
                }}
              >
                <HeaderPeople height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Contacts
              </div>
              <div
                className={active === 'catalog' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={() => {
                  setActive('catalog')
                  navigate('/admin/catalog')
                }}
              >
                <HeaderCatalog height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Catalog
              </div>
              <div
                className={active === 'operacion' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={(e) => {
                  if (openOperations) {
                    handleCloseOperations()
                  } else {
                    handleOpenOperations()
                  }
                  e.preventDefault()
                  e.stopPropagation()
                }}
                style={{ position: 'relative' }}
              >
                <HeaderOperacion height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Operations
                {openOperations && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '100%',
                      left: '0',
                      width: '100%',
                      backgroundColor: 'white',
                      border: '1px solid #06C0B0',
                      borderRadius: '5px',
                      borderTop: 'none',
                      borderTopLeftRadius: '0',
                      borderTopRightRadius: '0',

                      zIndex: '1',
                    }}
                  >
                    <div
                      onClick={() => {
                        handleCloseOperations()
                        setActive('operacion')
                        navigate('/admin/orders')
                        setBurger(false)
                      }}
                      className='header__menu__item__open'
                    >
                      Billing
                    </div>
                    <div
                      onClick={() => {
                        handleCloseOperations()
                        setActive('operacion')
                        navigate('/admin/history')
                        setBurger(false)
                      }}
                      className='header__menu__item__open'
                    >
                      Facturation
                    </div>
                    <div
                      onClick={() => {
                        handleCloseOperations()
                        setActive('operacion')
                        navigate('/admin/shipment')
                        setBurger(false)
                      }}
                      className='header__menu__item__open'
                    >
                      Shipment
                    </div>
                  </Box>
                )}
              </div>
              <div
                className={active === 'messages' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={() => {
                  setActive('orders')
                  navigate('/admin/orders')
                }}
              >
                <HeaderMessages height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Messages
              </div>
              <div
                className={active === 'projects' ? 'header__menu__burger__item active' : 'header__menu__burger__item'}
                onClick={() => {
                  setActive('orders')
                  navigate('/admin/orders')
                }}
              >
                <HeaderProjects height={30} width={30} stroke={active === 'orders' ? '#fff' : 'none'} style={{ marginRight: '10px' }} />
                Projects
                <ArrowDown className='header__menu__item__icon' stroke='#06C0B0' fill='#06C0B0' />
              </div>
            </div>
          )}
          <div
            className={active === 'orders' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              setActive('orders')
              navigate('/admin/orders')
            }}
          >
            Orders
          </div>
          <div
            className={active === 'contacts' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              setActive('contacts')
              navigate('/admin/contacts')
            }}
          >
            Contacts
          </div>
          <div
            className={active === 'catalog' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              setActive('catalog')
              navigate('/admin/catalog')
            }}
          >
            Catalog
          </div>
          <div
            style={{ position: 'relative' }}
            className={active === 'operacion' ? 'header__menu__item active' : 'header__menu__item'}
            onMouseEnter={handleOpenOperations}
            onMouseLeave={handleCloseOperations}
          >
            Operations
            {openOperations && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: '0',
                  width: '100%',
                  backgroundColor: 'white',
                  border: '1px solid #06C0B0',
                  borderRadius: '5px',
                  borderTop: 'none',
                  borderTopLeftRadius: '0',
                  borderTopRightRadius: '0',

                  zIndex: '1',
                }}
              >
                <div
                  onClick={() => {
                    setActive('operacion')
                    navigate('/admin/orders')
                  }}
                  className='header__menu__item__open'
                >
                  Billing
                </div>
                <div
                  onClick={() => {
                    setActive('operacion')
                    navigate('/admin/history')
                  }}
                  className='header__menu__item__open'
                >
                  Facturation
                </div>
                <div
                  onClick={() => {
                    setActive('operacion')
                    navigate('/admin/shipment')
                  }}
                  className='header__menu__item__open'
                >
                  Shipment
                </div>
              </Box>
            )}
          </div>
          <div
            className={active === 'messages' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              setActive('messages')
              navigate('/admin/orders')
            }}
          >
            Messages
          </div>
          <div
            className={active === 'projects' ? 'header__menu__item active' : 'header__menu__item'}
            onClick={() => {
              setActive('projects')
              navigate('/admin/orders')
            }}
          >
            Projects
            <ArrowDown className='header__menu__item__icon' stroke='#06C0B0' fill='#06C0B0' />
          </div>
        </div>
        <div className='header__user'>
          <div className='header__user__lang'>
            <p style={{ color: '#176318' }}>CAT</p>
            <ArrowDown className='header__user__lang__icon' stroke='#176318' fill='#176318' />
          </div>
          {acc ? (
            <div
              style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                backgroundColor: '#f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: '#176318',
                fontFamily: 'Inter',
                fontSize: '18px',
                fontWeight: '400',
                lineHeight: 'normal',
                margin: '0 20px',
              }}
              onClick={() => navigate('/admin/profile')}
            >
              {acc?.name.split(' ')[0] ? acc?.name.split(' ')[0][0] : null}
            </div>
          ) : (
            <User className='header__user__image' onClick={() => navigate('/admin/profile')} />
          )}
          <Button
            onClick={() => {
              localStorage.removeItem('password')
              localStorage.removeItem('acc')
              localStorage.removeItem('profileID')
              navigate('/admin/auth')
            }}
            color='error'
            className='header__user__logoutButton'
          >
            Logout
          </Button>
        </div>
      </div>
    </header>
  )
}

//setActive('operacion')
//              navigate('/admin/history')
