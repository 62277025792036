import React, { useEffect, useState } from 'react'

import './Profile.scss'
import { Box, Button, Container } from '@mui/material'
import { SiteSettings } from './SitesSettings/SiteSettings'
import ProfileSettings from './ProfileSettings/ProfileSettings'

export default function Profile() {
  return (
    <Box width={'95%'} sx={{ margin: '0 auto' }}>
      <ProfileSettings />
      {localStorage.getItem('password') && localStorage.getItem('name') === 'TPO' && <SiteSettings />}
    </Box>
  )
}
