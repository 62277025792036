import React, { useEffect, useMemo, useState, useRef } from 'react'
import '../../../Table/TableBody/TableBody.scss'
import OrderTableBodyItem from './OrderTableBodyItem/OrderTableBodyItem'
import { ReactComponent as Columns } from '../../../../assets/svg/Columns.svg'
import { CircularProgress } from '@mui/material'
import OrderTableModal from './OrderTableBodyModal/OrderTableModal'
import { mainApi } from '../../../../store/services/mainApi'

export default function OrderTableBody({ btntype, contacts, perPage, data, selectedItems, setSelectedItems, setPerPage, searchValue }) {
  const [showColumns, setShowColumns] = useState(false)

  const [likedItems, setLikedItems] = useState([])
  const [statuses, setStatuses] = useState([])

  const { data: statusesData, isLoading: isLoadingStatus } = mainApi.useGetAllStatusesQuery()

  useEffect(() => {
    if (statusesData) {
      if (localStorage.getItem('name') === 'TPO') {
        setStatuses(statusesData.filter((status) => status.type === 'orders'))
      } else {
        setStatuses(statusesData.filter((status) => status.type === 'orders' && status.name !== 'Checked'))
      }
    }
  }, [statusesData])

  // const lastElement = useRef()
  // const observer = useRef()

  // useEffect(() => {
  //   if (observer.current) {
  //     observer.current.disconnect()
  //   }

  //   var callback = function (entries, observer) {
  //     if (entries[0].isIntersecting) {
  //       console.log('WOWO')
  //       console.log(entries)
  //       setPerPage((e) => e + 25)
  //     }
  //   }
  //   observer.current = new IntersectionObserver(callback)
  //   observer.current.observe(lastElement.current)

  //   if (searchValue.length > 2) {
  //     setPerPage(data.length)
  //   }
  // }, [data])

  useEffect(() => {
    setSelectedItems([])
  }, [perPage])

  const selectedCogs = useMemo(() => {
    let total = 0
    if (selectedItems.length > 0) {
      selectedItems.forEach((order) => {
        if (order?.items?.length) {
          order.items.length > 0 ? order.items.forEach((item) => (total += item.ourPrice * (item.quantity || 1))) : (total += 0)
        }
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [selectedItems])

  const selectedTotal = useMemo(() => {
    let total = 0
    if (selectedItems.length > 0) {
      selectedItems.forEach((order) => {
        if (order?.items) {
          total += Number(
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 0)) : 0))
              .reduce((acc, curr) => acc + curr, 0),
          )
        }
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [selectedItems])

  const selectedProfit = useMemo(() => {
    let total = 0
    if (selectedItems.length > 0) {
      selectedItems.forEach((order) => {
        if (order?.items) {
          total += Number(
            order.items
              .map((items) =>
                items.marketPriceCP != ''
                  ? (parseFloat(items.marketPriceCP * (items.quantity || 1)) - parseFloat(items.ourPrice * (items.quantity || 1))) *
                    (1 - parseFloat(items.IVA || 0) / 100)
                  : 0,
              )
              .reduce((acc, curr) => acc + curr, 0),
          )
        }
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [selectedItems])

  const selectedPaid = useMemo(() => {
    let total = 0
    if (selectedItems.length > 0) {
      selectedItems.forEach((order) => {
        if (order?.paidValue) {
          total += Number(order?.paidValue ? order.paidValue : 0)
        }
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [selectedItems])

  const selectedBalance = useMemo(() => {
    let total = 0
    if (selectedItems.length > 0) {
      selectedItems.forEach((order) => {
        if (order?.items || order?.paidValue) {
          return parseFloat(order.paidValue)
            ? (total +=
                order.items
                  .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
                  .reduce((acc, curr) => acc + curr, 0) - (parseFloat(order.paidValue) || 0))
            : (total += order.items
                .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
                .reduce((acc, curr) => acc + curr, 0))
        }
      })
      return total.toFixed(2) + '€'
    } else {
      return 0
    }
  }, [selectedItems])
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedItems(data)
    } else {
      setSelectedItems([])
    }
  }

  const [keys] = useState([
    'Order',
    'Business',
    'User',
    'Status',
    'Delivery Date',
    'Total',
    'Profit(%)',
    'Profit',
    'Paid',
    'Balance',
    'Creation Date',
    'Country',
    'Phone',
    'Comments',
  ])

  const [options, setOptions] = useState({
    order: true,
    business: true,
    user: true,
    status: true,
    deliverydate: true,
    total: true,
    'profit(%)': true,
    profit: true,
    paid: true,
    balance: true,
    creationdate: false,
    country: false,
    phone: false,
    comments: true,
  })
  const handleChoose = (name) => {
    setOptions({
      ...options,
      [name]: !options[name],
    })
  }

  const visible = useMemo(() => {
    const count = Object.values(options).reduce((acc, item) => {
      if (item) {
        return acc + 1
      }
      return acc
    }, 0)
    return count
  }, [options])

  return (
    <div className='tableBody'>
      <div className='tableBody__title'>
        <input
          type='checkbox'
          checked={selectedItems.length === data.length}
          className='tableBody__title__checkbox'
          onChange={(e) => handleSelectAll(e)}
        />
        {keys.map((elem) => {
          return (
            <div
              key={elem}
              style={{ display: options[elem.toLowerCase().replace(/\s/g, '')] ? 'block' : 'none', width: `${80 / visible}%` }}
              className='tableBody__title__item'
            >
              <span>{elem}</span>
            </div>
          )
        })}

        <div className='tableBody__title__filter'>
          <div className='chooseVisibleColumnsBlock'>
            <div
              onClick={() => {
                setShowColumns(!showColumns)
              }}
              className='chooseVisibleColumns'
            >
              <Columns />
            </div>
            <span style={{ display: showColumns ? 'block' : 'none' }}>
              <div>Columns</div>
              {keys
                // .filter((el) => {
                //   if (localStorage.getItem('name') == 'VPF' && (el == 'Profit' || el == 'Profit(%)')) {
                //     return false
                //   } else {
                //     return true
                //   }
                // })
                .map((elem) => {
                  return (
                    <div key={elem} style={{ display: 'flex', alignItems: 'center', color: 'black', marginLeft: '10px', marginTop: '5px' }}>
                      <div className='checkbox' onClick={() => handleChoose(elem.toLowerCase().replace(/\s/g, ''))}>
                        {options[elem.toLowerCase().replaceAll(' ', '')] ? '✓' : ''}
                      </div>
                      <p style={{ marginLeft: '5px', marginTop: '20px' }}>{elem}</p>
                    </div>
                  )
                })}
            </span>
          </div>
        </div>
      </div>
      <div className='tableBody__body'>
        {data ? (
          data.map((elem, index) => {
            // if (index < perPage) {
            return (
              <OrderTableBodyItem
                statuses={statuses}
                btntype={btntype}
                contacts={contacts}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                keys={keys}
                options={options}
                key={elem.id}
                data={elem}
                isLoadingStatus={isLoadingStatus}
                visible={visible}
              />
            )
            // }
          })
        ) : (
          <CircularProgress />
        )}
      </div>

      {/* <div ref={lastElement}></div> */}
      <OrderTableModal
        selectedPaid={selectedPaid}
        selectedProfit={selectedProfit}
        selectedTotal={selectedTotal}
        selectedCogs={selectedCogs}
        selectedItems={selectedItems}
        selectedBalance={selectedBalance}
        setSelectedItems={setSelectedItems}
      />
    </div>
  )
}
