import React, { useMemo, useState } from 'react'
import '../../../Table/TableHeader/TableHeader.scss'
import SearchIcon from '../../../../assets/svg/Search.svg'
import { FormControl, MenuItem, Select, InputLabel, TextField, Button } from '@mui/material'
import { ReactComponent as Telegram } from '../../../../assets/svg/Telegram.svg'
import { ReactComponent as Whatsapp } from '../../../../assets/svg/Whatsapp.svg'
import { ReactComponent as Browser } from '../../../../assets/svg/Browser.svg'
import { ReactComponent as Instagram } from '../../../../assets/svg/Instagram.svg'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'

import { Modal, Box } from '@mui/material'

import chocko from '../../../../assets/png/choco.png'
import prezo from '../../../../assets/png/prezo.png'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'

export default function ContactsTableHeader({
  setSearchPlatform,
  setSearchType,
  headerType,
  perPage,
  data,
  setSearchValue,
  searchValue,
  setPerPage,
  setAdminType,
  adminType,
  cartFilter,
  setCartFilter,
}) {
  const [createContact] = mainApi.useCreateContactMutation()
  const [importContacts] = mainApi.useImportContactsMutation()
  // const [importCategories] = mainApi.useImportCategoriesMutation()
  // const [importProducts] = mainApi.useImportProductsMutation()
  const dispatch = useDispatch()

  const mobileView = useMediaQuery('(max-width: 768px)')

  const importContactsHandler = async (file) => {
    const body = new FormData()
    body.append('sampleFile', file[0])
    await importContacts(body)
  }

  // const createEmptyNewContact = async () => {
  //   const body = {
  //     data: {
  //       billAddress: { address: '', city: '', postalCode: '' },
  //       cif: '',
  //       code: '',
  //       dificilidad: false,
  //       email: '',
  //       employee: '',
  //       enabled: 1,
  //       extraPedidos: false,
  //       group: '',
  //       iban: '',
  //       img: '',
  //       name: '',
  //       nif: '',
  //       phone: '',
  //       rate: 0,
  //       responsable: '',
  //       solvencia: false,
  //       swift: '',
  //       tags: '',
  //       time: 0,
  //       tipo: '',
  //       tradeName: '',
  //       type: 'client',
  //       username: '',
  //     },
  //   }
  //   await createContact(body)
  //   dispatch(mainApi.util.invalidateTags(['Contacts']))
  // }

  //Create Contact Modal
  const [createContactModal, setCreateContactModal] = useState(false)

  const [nameValue, setNameValue] = useState('New Name')
  const [tradeNameValue, setTradeNameValue] = useState('1TMR')
  const [typeValue, setTypeValue] = useState('userUnion')
  const [phoneValue, setPhoneValue] = useState('')
  const [platformValue, setPlatformValue] = useState('browser')

  const submitHandler = async () => {
    console.log(nameValue, phoneValue, typeValue, platformValue, tradeNameValue)
    if (nameValue && phoneValue && typeValue && platformValue && tradeNameValue) {
      const body = {
        data: {
          billAddress: { address: '', city: '', postalCode: '' },
          cif: '',
          code: '',
          dificilidad: false,
          email: '',
          employee: '',
          enabled: 1,
          extraPedidos: false,
          group: '',
          iban: '',
          img: '',
          name: nameValue,
          nif: '',
          phone: phoneValue,
          rate: 0,
          responsable: '',
          solvencia: false,
          swift: '',
          tags: '',
          time: 0,
          tipo: '',
          tradeName: tradeNameValue,
          type: typeValue,
          username: '',
          platform: typeValue === 'user' ? platformValue : null, //telegram, whatsapp, browser
        },
      }
      await createContact(body)
      dispatch(mainApi.util.invalidateTags(['Contacts']))
      setCreateContactModal(false)
    } else {
      alert('Please fill all fields')
    }
  }

  ////

  const searchHandler = (inputText) => {
    setSearchValue(inputText)
  }

  const platformsContact = useMemo(() => {
    const uniquePlatforms = new Set(data.map((elem) => (elem.platform ? elem.platform : null)))
    uniquePlatforms.delete(null)
    return Array.from(uniquePlatforms)
  })

  const types = useMemo(() => {
    const uniqueTypes = new Set(data.map((elem) => elem.type))
    const filtredTypes = Array.from(uniqueTypes).filter((elem) => {
      return elem ? elem : false
    })
    return filtredTypes
  })

  const tradeNames = useMemo(() => {
    const uniqueTradeNames = new Set(data.map((elem) => elem.tradeName))
    const filtredTradeNames = Array.from(uniqueTradeNames).filter((elem) => {
      return elem ? elem : false
    })
    return filtredTradeNames
  }, [data])

  const [contactPlatform, setContactPlatform] = React.useState('')
  const [type, setType] = React.useState('')

  const [openMobileFilters, setOpenMobileFilters] = React.useState(false)

  return (
    <div className='tableHeader'>
      <div className='tableHeader__title'>
        Showing 1-{perPage} of {data.length} items.
      </div>

      {!mobileView && (
        <ProductionQuantityLimitsIcon
          onClick={() => setCartFilter(!cartFilter)}
          width={20}
          height={20}
          sx={{ marginLeft: '10px', cursor: 'pointer', color: 'red', padding: '5px', borderRadius: '5px', border: '1px solid red' }}
        />
      )}

      <div className='tableHeader__body'>
        {mobileView && (
          <div
            style={{ width: mobileView ? '100px' : 'auto' }}
            className='tableHeader__body__btn active'
            onClick={() => setOpenMobileFilters(!openMobileFilters)}
          >
            Filters
          </div>
        )}

        <Modal open={openMobileFilters} onClose={() => setOpenMobileFilters(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 250,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxHeight: '60vh',
              overflowX: 'hidden',
              overflowY: 'auto',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

              gap: '10px',
            }}
          >
            {adminType ? (
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Admin type</InputLabel>
                <Select
                  onChange={(e) => setAdminType(e.target.value)}
                  label='Admin type'
                  sx={{
                    fontFamily: 'Inter',
                    backgroundColor: '#fff',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#176318',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#176318',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#176318',
                    },
                  }}
                  renderValue={() => adminType}
                  value={adminType}
                  size='small'
                >
                  <MenuItem value={'MDL'}>MDL</MenuItem>
                  <MenuItem value={'TPO'}>TPO</MenuItem>
                  <MenuItem value={'VPF'}>VPF</MenuItem>
                  <MenuItem value={'All'}>All</MenuItem>
                </Select>
              </FormControl>
            ) : null}

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Rows Quantity</InputLabel>
              <Select
                onChange={(e) => setPerPage(e.target.value)}
                label='Rows Quantity'
                sx={{
                  fontFamily: 'Inter',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                }}
                renderValue={() => perPage}
                value={perPage}
                size='small'
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={data.length}>All</MenuItem>
              </Select>
            </FormControl>
            <div className='tableHeader__body__btn' style={{ width: '120px' }}>
              <input
                type='file'
                style={{ display: 'none' }}
                name='importContacts'
                id=' importContacts'
                onChange={(e) => importContactsHandler(e.target.files)}
              />
              <label htmlFor=' importContacts'>Import</label>
            </div>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Rows Quantity</InputLabel>
              <Select
                onChange={(e) => setPerPage(e.target.value)}
                label='Rows Quantity'
                sx={{
                  fontFamily: 'Inter',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                }}
                renderValue={() => perPage}
                value={perPage}
                size='small'
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={data.length}>All</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel
                sx={{
                  left: '30%',
                  position: 'absolute',
                  top: '-7px',
                  fontFamily: 'Inter',
                  color: '#176318',
                  '&.Mui-focused': { color: '#176318' },
                }}
              >
                Type
              </InputLabel>
              <Select
                onChange={(e) => {
                  setType(e.target.value)
                  setSearchType(e.target.value)
                }}
                value={type}
                size='small'
              >
                <MenuItem value=''>All</MenuItem>
                <MenuItem value='ambassador'>👑Ambassadors</MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>
                        {(type == 'userUnion' && '🧑‍User') ||
                          (type == 'user' && '📲Account') ||
                          (type == 'supplier' && '🚚Supplier') ||
                          (type == 'client' && '💰Business') ||
                          (type == 'ambassador' && '👑Ambassador')}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Modal>

        {!mobileView && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Rows Quantity</InputLabel>
            <Select
              onChange={(e) => setPerPage(e.target.value)}
              label='Rows Quantity'
              sx={{
                fontFamily: 'Inter',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
              }}
              renderValue={() => perPage}
              value={perPage}
              size='small'
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={data.length}>All</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* inputs for import */}
        {!mobileView && adminType && (
          <FormControl sx={{ minWidth: 120, position: 'relative' }}>
            <InputLabel
              sx={{
                fontFamily: 'Inter',
                color: '#176318',
                '&.Mui-focused': { color: '#176318' },
                // width: '100%',
                textAlign: 'center',
                transformOrigin: 'center',
                // position: 'absolute',
                // left: '50%',
                // transform: 'translateX(-70%) translateY(50%)',
              }}
            >
              Admin
            </InputLabel>
            <Select
              onChange={(e) => setAdminType(e.target.value)}
              label='Admin'
              sx={{
                marginRight: '10px',
                fontFamily: 'Inter',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
              }}
              renderValue={() => adminType}
              value={adminType}
              size='small'
            >
              <MenuItem value={'MDL'}>MDL</MenuItem>
              <MenuItem value={'TPO'}>TPO</MenuItem>
              <MenuItem value={'VPF'}>VPF</MenuItem>
              <MenuItem value={'All'}>All</MenuItem>
            </Select>
          </FormControl>
        )}
        {!mobileView && (
          <div className='tableHeader__body__btn'>
            <input
              type='file'
              style={{ display: 'none' }}
              name='importContacts'
              id=' importContacts'
              onChange={(e) => importContactsHandler(e.target.files)}
            />
            <label htmlFor=' importContacts'>Import</label>
          </div>
        )}
        {!mobileView && (
          <FormControl className='' sx={{ m: 1, minWidth: 155, position: 'relative' }}>
            <InputLabel
              sx={{
                marginLeft: '32px',
                position: 'absolute',
                top: '-7px',
                fontFamily: 'Inter',
                color: '#176318',
                '&.Mui-focused': { color: '#176318' },
              }}
            >
              Platform
            </InputLabel>
            <Select
              onChange={(e) => {
                setContactPlatform(e.target.value)
                setSearchPlatform(e.target.value)
              }}
              value={contactPlatform}
              size='small'
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='choco'>
                <img src={chocko} alt='choko' width={100} height={20} />
              </MenuItem>
              <MenuItem value='prezo'>
                <img src={prezo} alt='prezo' width={100} height={20} />
              </MenuItem>
              <MenuItem value='instagram'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Instagram width={20} height={20} />
                  <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Instagram</span>
                </div>
              </MenuItem>
              {platformsContact.map((platform) => (
                <MenuItem key={platform} value={platform}>
                  {platform === 'telegram' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Telegram width={20} height={20} />
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Telegram</span>
                    </div>
                  ) : null}
                  {platform === 'whatsapp' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Whatsapp width={20} height={20} />
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Whatsapp</span>
                    </div>
                  ) : null}
                  {platform === 'browser' ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Browser width={20} height={20} />
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>Browser</span>
                    </div>
                  ) : null}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!mobileView && (
          <FormControl sx={{ minWidth: 155, position: 'relative' }}>
            <InputLabel
              sx={{
                left: '30%',
                position: 'absolute',
                top: '-7px',
                fontFamily: 'Inter',
                color: '#176318',
                '&.Mui-focused': { color: '#176318' },
              }}
            >
              Type
            </InputLabel>
            <Select
              onChange={(e) => {
                setType(e.target.value)
                setSearchType(e.target.value)
              }}
              value={type}
              size='small'
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='ambassador'>👑Ambassadors</MenuItem>
              {types.map((type) => (
                <MenuItem key={type} value={type}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>
                      {(type == 'userUnion' && '🧑‍User') ||
                        (type == 'user' && '📲Account') ||
                        (type == 'supplier' && '🚚Supplier') ||
                        (type == 'client' && '💰Business') ||
                        (type == 'ambassador' && '👑Ambassador')}
                    </span>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <div className='tableHeader__body__search '>
          <input type='text' placeholder='Search' onChange={(e) => searchHandler(e.target.value)} value={searchValue} />
          <span>
            <img src={SearchIcon} alt='' />
          </span>
        </div>

        <div
          style={{ width: mobileView ? '100px' : 'auto' }}
          className='tableHeader__body__btn active rightSide'
          onClick={() => setCreateContactModal(true)}
        >
          New contact
        </div>

        <Modal open={createContactModal} onClose={() => setCreateContactModal(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
            }}
          >
            <FormControl fullWidth sx={{ position: 'relative' }}>
              <p>Type</p>
              <Select
                MenuProps={{ PaperProps: { style: { maxHeight: '200px' } } }}
                required
                onChange={(e) => setTypeValue(e.target.value)}
                value={typeValue}
                fullWidth
              >
                {/* <MenuItem value=''></MenuItem> */}
                <MenuItem value='ambassador'>👑Ambassadors</MenuItem>
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>
                        {(type == 'userUnion' && '🧑‍User') ||
                          (type == 'user' && '📲Account') ||
                          (type == 'supplier' && '🚚Supplier') ||
                          (type == 'client' && '💰Business') ||
                          (type == 'ambassador' && '👑Ambassador')}
                      </span>
                    </div>
                  </MenuItem>
                ))}
              </Select>
              <p>Name</p>
              <TextField label='Name' variant='outlined' type='text' required onChange={(e) => setNameValue(e.target.value)} value={nameValue} />
              <p>Phone</p>
              <TextField label='Phone' variant='outlined' type='tel' required onChange={(e) => setPhoneValue(e.target.value)} value={phoneValue} />
              {typeValue === 'user' && <p>Platform</p>}
              {typeValue === 'user' && (
                <Select
                  MenuProps={{ PaperProps: { style: { maxHeight: '200px' } } }}
                  required
                  error={!platformValue}
                  onChange={(e) => setPlatformValue(e.target.value)}
                  value={platformValue}
                  fullWidth
                >
                  {platformsContact.map((platform) => (
                    <MenuItem key={platform} value={platform}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>{platform}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              )}
              <p>Trade name</p>
              {typeValue === 'client' ? (
                <TextField
                  label='Trade name'
                  variant='outlined'
                  type='text'
                  required
                  onChange={(e) => setTradeNameValue(e.target.value)}
                  value={tradeNameValue}
                />
              ) : (
                <Select
                  MenuProps={{ PaperProps: { style: { maxHeight: '200px' } } }}
                  required
                  onChange={(e) => setTradeNameValue(e.target.value)}
                  value={tradeNameValue}
                  fullWidth
                >
                  {tradeNames.map((tradeName) => (
                    <MenuItem key={tradeName} value={tradeName}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginLeft: '5px', fontFamily: 'Inter' }}>{tradeName}</span>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              )}
              <Button variant='contained' color='success' sx={{ marginTop: '10px' }} type='submit' onClick={() => submitHandler()}>
                Create
              </Button>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </div>
  )
}
