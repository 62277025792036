import React, { useMemo } from 'react'
import './ProductsInfo.scss'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const ProductsInfo = ({ orders }) => {
  const { t } = useTranslation()

  const items = useMemo(() => {
    if (orders) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      orders.forEach((order) => {
        order.items.forEach((item) => {
          const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

          if (!uniqueItemsMap[key]) {
            // Если элемент с таким ключом еще не существует, добавляем его в объект
            uniqueItemsMap[key] = {
              img: item.img,
              conceptio: item.name,
              // sku: item.sku,
              quantity: item.quantity,
              price: item.marketPriceCP,
              // IVA: item.IVA ? item.IVA : 0,
              // tipo: item.currentUnit ? item.currentUnit : '',
              total: (item.marketPriceCP * item.quantity).toFixed(2),
            }
          } else {
            // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
            uniqueItemsMap[key].quantity += item.quantity
            uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
          }
        })
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      uniqueItems.sort((a, b) => b.quantity - a.quantity)

      return uniqueItems
    } else {
      return []
    }
  }, [orders])

  return (
    <Box className='productsInfo'>
      <Box
        sx={{
          minHeight: '26px',
          fontWeight: 'bold',
          fontSize: '20px',
          color: '#286F29',
          textTransform: 'uppercase',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        className='mainInfo__title'
      >
        {t('StaticPage.topOrders.title')}
      </Box>
      <Box className='productsInfo__content'>
        <Box className='productsInfo__content__title'>
          <Typography className='productsInfo__content__title__text' sx={{ width: '60%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.name')}
          </Typography>
          <Typography className='productsInfo__content__title__text' sx={{ width: '20%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.quantity')}
          </Typography>
          <Typography className='productsInfo__content__title__text' sx={{ width: '20%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.price')}
          </Typography>
        </Box>
        <Box className='productsInfo__content__items'>
          {items.map((item, index) => (
            <Box key={index} className='productsInfo__content__items__item'>
              <Box className='productsInfo__content__items__item__text ' sx={{ width: '60%', textAlign: 'center' }}>
                <Box sx={{ backgroundImage: `url(${item.img})` }} className='productsInfo__content__items__item__text__img' />
                <Typography className='productsInfo__content__items__item__text__conceptio'>{item.conceptio}</Typography>
              </Box>
              <Typography
                className='productsInfo__content__items__item__text'
                sx={{
                  width: '20%',
                  textAlign: 'center',
                  borderLeft: '1px solid #CCCCCC',
                  borderRight: '1px solid #CCCCCC',
                  height: '100% !important',
                }}
              >
                {item?.quantity ? item?.quantity.toFixed(2) : 0}
              </Typography>
              <Typography className='productsInfo__content__items__item__text' sx={{ width: '20%', textAlign: 'center' }}>
                {typeof item.price === 'number' ? item.price.toFixed(2) : typeof item.price === 'string' ? item.price : 0}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
