import React, { useState } from 'react'
import '../../../Table/TableHeader/TableHeader.scss'
import SearchIcon from '../../../../assets/svg/Search.svg'
import { FormControl, MenuItem, Select, InputLabel, TextField, Button, Modal, Box, CircularProgress } from '@mui/material'
import { mainApi } from '../../../../store/services/mainApi'
import { useDispatch } from 'react-redux'
import { useMediaQuery } from '@mui/material'
import CreateProductEmpt from '../CreateProductEmpt/CreateProductEmpt'

export default function CatalogTableHeader({ setSpo, spo, perPage, data, setSearchValue, searchValue, setPerPage }) {
  const dispatch = useDispatch()

  const [openMobileFilters, setOpenMobileFilters] = React.useState(false)

  const mobileView = useMediaQuery('(max-width: 768px)')

  const [showModal, setShowModal] = React.useState(false)

  const [loadingState, setLoadingState] = useState({
    products: false,
    categories: false,
    brands: false,
    businessTypes: false,
  })

  const importProductsHandler = async (file) => {
    setLoadingState((prevState) => ({ ...prevState, products: true }))
    const formData = new FormData()
    formData.append('sampleFile', file[0])

    try {
      await fetch('https://tpomobi.shop/importProductsAdmin', { method: 'POST', body: formData })
      dispatch(mainApi.util.invalidateTags(['Products']))
      alert('Products updated')
    } catch (error) {
      console.error('Error importing products:', error)
    } finally {
      setLoadingState((prevState) => ({ ...prevState, products: false }))
    }
  }

  const importCategoriesHandler = async (file) => {
    setLoadingState((prevState) => ({ ...prevState, categories: true }))
    const body = new FormData()
    body.append('sampleFile', file[0])

    try {
      await fetch('https://tpomobi.shop/importProductsLanding', { method: 'POST', body: body })
      dispatch(mainApi.util.invalidateTags(['Products']))
      alert('Categories updated')
    } catch (error) {
      alert('Something went wrong. Try import again')
      console.error('Error importing categories:', error)
    } finally {
      setLoadingState((prevState) => ({ ...prevState, categories: false }))
    }
  }

  const importBrandsHandler = async (file) => {
    setLoadingState((prevState) => ({ ...prevState, brands: true }))
    const body = new FormData()
    body.append('sampleFile', file[0])

    try {
      await fetch('https://tpomobi.shop/importBrandsLanding', { method: 'POST', body: body })
      alert('Brands updated')
    } catch (error) {
      alert('Something went wrong. Try import again')
      console.error('Error importing brands:', error)
    } finally {
      setLoadingState((prevState) => ({ ...prevState, brands: false }))
    }
  }

  const importBusinessTypeHandler = async (file) => {
    setLoadingState((prevState) => ({ ...prevState, businessTypes: true }))
    const body = new FormData()
    body.append('sampleFile', file[0])

    try {
      const response = await fetch('https://tpomobi.shop/importBusinessTypeLanding', { method: 'POST', body: body })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      alert('Business types updated successfully: ' + JSON.stringify(data))
    } catch (error) {
      alert('Something went wrong. Try import again. Error: ' + error.message)
      console.error('Error importing business types:', error)
    } finally {
      setLoadingState((prevState) => ({ ...prevState, businessTypes: false }))
    }
  }

  const searchHandler = (inputText) => {
    setSearchValue(inputText)
  }

  return (
    <div className='tableHeader'>
      <div className='tableHeader__title'>
        Showing 1-{perPage} of {data.length} items.
      </div>

      <div className='tableHeader__body'>
        {!mobileView && (
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Rows Quantity</InputLabel>
            <Select
              onChange={(e) => setPerPage(e.target.value)}
              label='Rows Quantity'
              sx={{
                fontFamily: 'Inter',
                backgroundColor: '#fff',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#176318',
                },
              }}
              renderValue={() => perPage}
              value={perPage}
              size='small'
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={150}>150</MenuItem>
              <MenuItem value={200}>200</MenuItem>
              <MenuItem value={500}>500</MenuItem>
              <MenuItem value={data.length}>All</MenuItem>
            </Select>
          </FormControl>
        )}

        {!mobileView && (
          <div className='tableHeader__body__btn'>
            <input
              type='file'
              name='importProducts'
              id='importProducts'
              onChange={(e) => importProductsHandler(e.target.files)}
              style={{ display: 'none' }}
            />
            {loadingState.products ? <CircularProgress sx={{ color: '#176318' }} /> : <label htmlFor='importProducts'>Import</label>}
          </div>
        )}

        {!mobileView && (
          <div className='tableHeader__body__btn' style={{ marginLeft: '8px' }}>
            <input
              type='file'
              name='importCategories'
              id='importCategories'
              onChange={(e) => importCategoriesHandler(e.target.files)}
              style={{ display: 'none' }}
            />
            {loadingState.categories ? (
              <CircularProgress sx={{ color: '#176318' }} />
            ) : (
              <label
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                htmlFor='importCategories'
              >
                Import Categories
              </label>
            )}
          </div>
        )}

        {!mobileView && (
          <div className='tableHeader__body__btn' style={{ marginLeft: '8px' }}>
            <input
              type='file'
              name='importBrands'
              id='importBrands'
              onChange={(e) => importBrandsHandler(e.target.files)}
              style={{ display: 'none' }}
            />
            {loadingState.brands ? (
              <CircularProgress sx={{ color: '#176318' }} />
            ) : (
              <label
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                htmlFor='importBrands'
              >
                Import Brands
              </label>
            )}
          </div>
        )}
        {!mobileView && (
          <div className='tableHeader__body__btn' style={{ marginLeft: '8px' }}>
            <input
              type='file'
              name='importBuisnessType'
              id='importBuisnessType'
              onChange={(e) => importBusinessTypeHandler(e.target.files)}
              style={{ display: 'none' }}
            />
            {loadingState.businessTypes ? (
              <CircularProgress sx={{ color: '#176318' }} />
            ) : (
              <label
                style={{
                  cursor: 'pointer',
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                htmlFor='importBuisnessType'
              >
                Import Business Types
              </label>
            )}
          </div>
        )}

        {!mobileView && (
          <div style={{ margin: '8px' }} onClick={() => setSpo(!spo)} className={`tableHeader__body__btn ${spo ? 'active' : ''}`}>
            SPO
          </div>
        )}

        {mobileView && (
          <div
            style={{ width: mobileView ? '100px' : 'auto' }}
            className='tableHeader__body__btn active'
            onClick={() => setOpenMobileFilters(!openMobileFilters)}
          >
            Filters
          </div>
        )}

        <Modal open={openMobileFilters} onClose={() => setOpenMobileFilters(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 200,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              maxHeight: '60vh',
              overflowX: 'hidden',
              overflowY: 'auto',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',

              gap: '10px',
            }}
          >
            <FormControl sx={{ m: 1, minWidth: 170 }}>
              <InputLabel sx={{ fontFamily: 'Inter', color: '#176318', '&.Mui-focused': { color: '#176318' } }}>Rows Quantity</InputLabel>
              <Select
                onChange={(e) => setPerPage(e.target.value)}
                label='Rows Quantity'
                sx={{
                  fontFamily: 'Inter',
                  backgroundColor: '#fff',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#176318',
                  },
                }}
                renderValue={() => perPage}
                value={perPage}
                size='small'
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={150}>150</MenuItem>
                <MenuItem value={200}>200</MenuItem>
                <MenuItem value={500}>500</MenuItem>
                <MenuItem value={data.length}>All</MenuItem>
              </Select>
            </FormControl>
            <div style={{ margin: '0', width: '170px' }} className='tableHeader__body__btn'>
              <input
                type='file'
                name='importProducts'
                id='importProducts'
                onChange={(e) => importProductsHandler(e.target.files)}
                style={{ display: 'none' }}
              />
              {loadingState.products ? <CircularProgress sx={{ color: '#176318' }} /> : <label htmlFor='importProducts'>Import</label>}
            </div>
            <div className='tableHeader__body__btn' style={{ margin: '0', width: '170px' }}>
              <input
                type='file'
                name='importCategories'
                id='importCategories'
                onChange={(e) => importCategoriesHandler(e.target.files)}
                style={{ display: 'none' }}
              />
              {loadingState.categories ? (
                <CircularProgress sx={{ color: '#176318' }} />
              ) : (
                <label
                  style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  htmlFor='importCategories'
                >
                  Import Categories
                </label>
              )}
            </div>
            <div className='tableHeader__body__btn' style={{ margin: '0', width: '170px' }}>
              <input
                type='file'
                name='importBrands'
                id='importBrands'
                onChange={(e) => importBrandsHandler(e.target.files)}
                style={{ display: 'none' }}
              />
              {loadingState.brands ? (
                <CircularProgress sx={{ color: '#176318' }} />
              ) : (
                <label
                  style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  htmlFor='importBrands'
                >
                  Import Brands
                </label>
              )}
            </div>
            <div className='tableHeader__body__btn' style={{ margin: '0', width: '170px' }}>
              <input
                type='file'
                name='importBuisnessType'
                id='importBuisnessType'
                onChange={(e) => importBusinessTypeHandler(e.target.files)}
                style={{ display: 'none' }}
              />
              {loadingState.businessTypes ? (
                <CircularProgress sx={{ color: '#176318' }} />
              ) : (
                <label
                  style={{
                    cursor: 'pointer',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  htmlFor='importBuisnessType'
                >
                  Import Business Types
                </label>
              )}
            </div>
            <div style={{ margin: '0', width: '170px' }} onClick={() => setSpo(!spo)} className={`tableHeader__body__btn ${spo ? 'active' : ''}`}>
              SPO
            </div>
          </Box>
        </Modal>

        <Modal open={showModal} onClose={() => setShowModal(false)}>
          <CreateProductEmpt setShowModal={setShowModal} />
        </Modal>

        <div className='tableHeader__body__search '>
          <input type='text' placeholder='Search' onChange={(e) => searchHandler(e.target.value)} value={searchValue} />
          <span>
            <img src={SearchIcon} alt='' />
          </span>
        </div>

        <div style={{ width: mobileView ? '100px' : 'auto' }} className='tableHeader__body__btn active' onClick={() => setShowModal(true)}>
          New Product
        </div>
      </div>
    </div>
  )
}
