import React, { useEffect, useState } from 'react'
import './CatalogChange.scss'
import { ReactComponent as ArrowLeft } from '../../../assets/svg/ArrowLeft.svg'
import { useNavigate } from 'react-router-dom'
import CatalogInfo from './CatalogInfo/CatalogInfo'
import CatalogChangeBox from './CatalogChangeBox/NewCatalogChangeBox'

import { mainApi } from '../../../store/services/mainApi'
import { useLocation } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import CatalogPrice from './CatalogPrice/NewCatalogPrice'
import { useDispatch } from 'react-redux'
import { setCurrentProduct } from '../../../store/slices/currentProduct'

export default function CatalogChange() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { data: productsData, isLoading } = mainApi.useFindAllWordsQuery()
  const { data: productData, isLoading: isLoadingProduct } = mainApi.useGetProductByIdQuery(location.pathname.split('/').pop())

  const [products, setProducts] = useState([])
  const [product, setProduct] = useState({})

  //set redux value
  useEffect(() => {
    if (productData) {
      dispatch(setCurrentProduct(productData))
      setProduct(productData)
      console.log(productData, 'productData')
      document.title = productData.name
    }
  }, [productData])

  //for reset values
  const [reset, setReset] = useState(false)

  //idk what is this
  const [status, setStatus] = useState({})

  //CatalogInfo

  useEffect(() => {
    if (productsData) {
      setProducts(productsData)
    }
  }, [productsData])

  return isLoading || isLoadingProduct ? (
    <CircularProgress size={200} color='success' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
  ) : (
    <div className='catalogChange'>
      <div className='catalogChange__title'>
        <ArrowLeft className='contactChange__title__back' onClick={() => navigate(-1)} />
        <h2>{product?.name}</h2>
      </div>
      <div className='catalogChange__container'>
        <div className='catalogChange__container__left'>
          <CatalogInfo product={product} reset={reset} />
          <CatalogChangeBox products={products} product={product} setStatus={setStatus} status={status} reset={reset} />
        </div>
        <div className='catalogChange__container__right'>
          <CatalogPrice setReset={setReset} product={product} reset={reset} />
        </div>
      </div>
    </div>
  )
}
