import React, { useEffect, useState } from 'react'
import './Stats.scss'
import StatItem from './StatItem/StatItem'
import { ReactComponent as ArrowDown } from '../../assets/svg/ArrowDown.svg'
import { Checkbox, CircularProgress, Switch } from '@mui/material'
import { ReactComponent as HistoryDash } from '../../assets/svg/Dashboard/HistoryDash.svg'

import { useNavigate } from 'react-router-dom'
export default function Stats({ mock, historyLink, isLoading, isFetching }) {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()

  const handleChange = (event) => {
    setOpen(event.target.checked)
  }

  useEffect(() => {
    console.log('Component mounted or updated, mock:', mock)
  }, [mock])

  return (
    <div className='stats'>
      <div className='stats__close'>
        <Switch color='success' checked={open} onChange={handleChange} />
        <div className='stats__close__title'>Dashboard</div>
        {/* {historyLink ? <HistoryDash width={25} height={25} onClick={() => navigate('admin' + historyLink)} /> : null} */}
        {historyLink ? <HistoryDash width={25} height={25} onClick={() => navigate('/admin' + historyLink)} /> : null}
        {/* <ArrowDown className={`stats__close__arrow ${open ? 'open' : 'close'}`} width={25} height={25} fill='#fafafa' stroke='#000000' /> */}
      </div>
      <div className='stats__container' style={{ display: open ? 'grid' : 'none' }}>
        {isLoading || isFetching ? (
          <CircularProgress color='success' style={{ margin: 'auto' }} />
        ) : (
          mock.map((item, index) => <StatItem key={index} image={item.image} title={item.title} value={item.value} color={item.color} />)
        )}
      </div>
    </div>
  )
}
