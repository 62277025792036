import React from 'react'
import { Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material'
import styled from '@emotion/styled'

const StyledFormControl = styled(FormControl)({
  minWidth: 120,
  width: '80%',
  margin: '15px auto',
})

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-input': {
    borderRadius: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'grey',
    borderRadius: '25px',
    opacity: 0.5,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray',
    opacity: 1,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'green',
  },
  '& .MuiSelect-select': {
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
})

function CustomSelect({ name, value, onChange }) {
  return (
    <StyledFormControl variant='outlined'>
      <Typography sx={{ marginBottom: '15px', fontSize: '12px', fontWeight: '600', fontFamily: 'Montserrat' }} variant='body1'>
        User type
      </Typography>
      <StyledSelect
        size='small'
        labelId='demo-simple-select-outlined-label'
        id='demo-simple-select-outlined'
        value={value}
        onChange={onChange}
        name={name}
      >
        <MenuItem value='user'>User</MenuItem>
        <MenuItem value='admin'>Admin</MenuItem>
        <MenuItem value='guest'>Guest</MenuItem>
      </StyledSelect>
    </StyledFormControl>
  )
}

export default CustomSelect
