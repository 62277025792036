import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentProduct: {},
  save: false
}

export const currentProductSlice = createSlice({
  name: 'CurrentProduct',
  initialState,
  reducers: {
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload
    },
    changeSave: (state, action) => {
      state.save = action.payload
    },
    changeCurrentProduct: (state, action) => {
      state.currentProduct = {
        ...state.currentProduct,
        ...action.payload
      }
    }

  }
})

export const { setCurrentProduct, changeSave, changeCurrentProduct } = currentProductSlice.actions
export default currentProductSlice.reducer