import React, { useEffect, useState } from 'react'
import './StatItem.scss'
import { ReactComponent as Unenabled } from '../../../assets/svg/Unenabled.svg'
import { ReactComponent as Cart } from '../../../assets/svg/Cart.svg'
import { ReactComponent as Paid } from '../../../assets/svg/Paid.svg'
import { ReactComponent as Sold } from '../../../assets/svg/Sold.svg'
import { ReactComponent as Money } from '../../../assets/svg/Money.svg'
import { ReactComponent as Debts } from '../../../assets/svg/Debt.svg'
import { ReactComponent as Man } from '../../../assets/svg/Man.svg'
import { ReactComponent as Products } from '../../../assets/svg/Products.svg'
import { ReactComponent as Dip } from '../../../assets/svg/Diplomat.svg'
import { ReactComponent as RedCart } from '../../../assets/svg/RedCart.svg'
import { ReactComponent as GreenCart } from '../../../assets/svg/GreenCart.svg'
import { ReactComponent as Catalog } from '../../../assets/svg/Catalog.svg'
import { ReactComponent as Spo } from '../../../assets/svg/Spo.svg'
import { ReactComponent as Cogs } from '../../../assets/svg/BankCard.svg'
import { ReactComponent as Cog } from '../../../assets/svg/Cog.svg'
import { ReactComponent as Hand } from '../../../assets/svg/HandWMoney.svg'
import { ReactComponent as ThreeMan } from '../../../assets/svg/ThreeMan.svg'
import { ReactComponent as VerifyMan } from '../../../assets/svg/VerifyMan.svg'
import { ReactComponent as Sup } from '../../../assets/svg/Supliers.svg'

const imageComponents = {
  Cart: <Cart />,
  Paid: <Paid />,
  Sold: <Sold />,
  Money: <Money />,
  HandWithMoney: <Hand />,
  Debts: <Debts />,
  UnenabledItems: <Unenabled />,
  Man: <Man />,
  VerifyMan: <VerifyMan />,
  Supliers: <Sup />,
  ThreeMan: <ThreeMan />,
  Products: <Products />,
  Buisness: <Dip />,
  RedCart: <RedCart />,
  GreenCart: <GreenCart />,
  Cog: <Cog />,
  Cogs: <Cogs />,
  Catalog: <Catalog />,
  Spo: <Spo />,
}
export default function StatItem({ image, title, value, color }) {
  const [imageElement, setImageElement] = useState(null)

  useEffect(() => {
    setImageElement(imageComponents[image] || null)
  }, [image])
  // useEffect(() => {
  //   switch (image) {
  //     case 'Cart':
  //       setImageElement(<Cart />)
  //       break
  //     case 'Paid':
  //       setImageElement(<Paid />)
  //       break
  //     case 'Sold':
  //       setImageElement(<Sold />)
  //       break
  //     case 'Money':
  //       setImageElement(<Money />)
  //       break
  //     case 'Debts':
  //       setImageElement(<Debts />)
  //       break
  //     case 'UnenabledItems':
  //       setImageElement(<Unenabled />)
  //       break
  //     case 'Man':
  //       setImageElement(<Man />)
  //       break
  //     case 'Products':
  //       setImageElement(<Products />)
  //       break
  //     case 'Diplomat':
  //       setImageElement(<Diplomat />)
  //       break
  //     case 'RedCart':
  //       setImageElement(<RedCart />)
  //       break
  //     case 'GreenCart':
  //       setImageElement(<GreenCart />)
  //       break
  //     case 'Cog':
  //       setImageElement(<Cog />)
  //       break
  //     case 'Catalog':
  //       setImageElement(<Catalog />)
  //       break
  //     case 'Spo':
  //       setImageElement(<Spo />)
  //       break
  //     default:
  //       setImageElement(null)
  //       break
  //   }
  // }, [])

  return (
    <div className='statItem'>
      <div className='statItem__titleItem__image'>{imageElement}</div>
      <div
        style={{
          width: '80%',
          display: 'flex',
          justifyContent: 'left',
          // alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div className='statItem__titleItem'>
          <div className='statItem__titleItem__title'>{title}</div>
        </div>
        <div className='statItem__value' style={{ color: color }}>
          {value}
        </div>
      </div>
    </div>
  )
}
